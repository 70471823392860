define('webapp/components/widgets/widget-related-skills', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    detailService: Ember.inject.service(),
    detailId: null,

    skills: Ember.computed(function () {
      return [];
    }),

    items: Ember.computed(function () {
      return [];
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var currentDetailId = this.get('detailService.detail._id');
      // prevents component from loading the same data multiple times
      if (currentDetailId !== this.get('detailId')) {
        this.set('detailId', currentDetailId);
        if (currentDetailId) {
          this.loadData();
        }
      }
    },
    loadData: function loadData() {
      var _this = this;

      var skills = this.get('detailService.detail.skills');
      var detailTags = this.get('detailService.detail.tags');

      var myStorage = this.get('myStorage');
      var tags = detailTags ? detailTags.slice() : [];

      if (skills && skills.length) {
        skills.forEach(function (skill) {
          return skill.tags.forEach(function (tag) {
            tags.push(tag);
          });
        });
      }

      if (tags.length) {
        myStorage.skill.ajaxPost({
          param: 'search',
          data: {
            skillType: 'offer',
            tags: tags
          }
        }).then(function (searchRes) {
          if (!_this.isDestroyed) {
            _this.set('skills', searchRes.hits);
          }
        });
      }
    }
  });
});