define('webapp/components/widgets/widget-search', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function findNearestComponent(element) {
    while (element && element.classList) {
      if (element.classList.contains('ember-view')) {
        return element;
      }
      element = element.parentNode;
    }
  }

  exports.default = Ember.Component.extend({
    searchService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    viewService: Ember.inject.service(),
    router: Ember.inject.service(),
    isSearchRoute: Ember.computed.alias('searchService.isSearchRoute'),
    searchTerm: Ember.computed.alias('searchService.searchTerm'),
    categories: [],
    selectedCategory: Ember.computed.alias('searchService.selectedCategory'),
    selectedSite: Ember.computed.alias('searchService.selectedSite'),

    searchShown: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('cachedData').getSite().then(function (site) {
        if (site && site.regionPages) {
          _this.set('regionPages', site.regionPages);
        }
      });
    },


    currentRegionPage: Ember.computed.alias('viewService.regionPage'),

    search: function search() {
      var searchService = this.get('searchService');
      var searchTerm = this.get('searchTerm');
      var currentRegionPage = this.get('currentRegionPage');
      searchService.setSearchTerm(searchTerm);

      this.set('viewService.showSearchbar', !this.get('media.isMobile'));

      if (!this.get('isSearchRoute') && currentRegionPage) {
        searchService.transitionSearchToRegion(currentRegionPage);
      } else {
        searchService.transitionSearch();
      }
    },


    // uikit toggle hacks
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      if (this.element) {
        var elementToToggle = this.element.querySelector('.nav-overlay[hidden]');
        var toggleElementWrapper = findNearestComponent(elementToToggle);

        if (elementToToggle && toggleElementWrapper) {
          var showHandler = function showHandler() {
            toggleElementWrapper.classList.add('uk-width-expand');
            _this2.searchShown = true;
          };
          var hideHandler = function hideHandler() {
            toggleElementWrapper.classList.remove('uk-width-expand');
            _this2.searchShown = false;
          };

          UIkit.util.on(elementToToggle, 'show', showHandler);
          UIkit.util.on(elementToToggle, 'hide', hideHandler);
        }
      }
    },


    actions: {
      search: function search() {
        this.search();
      },


      // accepts null for region, to unset the region
      changeRegion: function changeRegion(region) {
        var searchService = this.get('searchService');
        var viewService = this.get('viewService');
        var regionCatIds = region && region.categories || [];
        viewService.set('regionPage', region);
        this.get('cachedData').getCategories().then(function (categories) {
          var selectedCategories = searchService.get('selectedCategories');

          selectedCategories = selectedCategories.filter(function (cat) {
            return cat.type !== 'region';
          });
          var regionCats = regionCatIds.map(function (id) {
            return (0, _treeUtils.findInTree)(categories, function (cat) {
              return cat._id === id;
            });
          });

          var newSelectedCategories = regionCats.concat(selectedCategories);
          searchService.set('selectedCategories', newSelectedCategories);
          searchService.transitionSearch();
        });
      },
      searchKeyup: function searchKeyup(e) {
        var searchService = this.get('searchService');
        var searchTerm = e.target.value;
        searchService.set('searchTerm', searchTerm);
        if (e.key === 'Enter') {
          this.search();
        } else {
          Ember.run.debounce(searchService, searchService.searchAsYouType, searchTerm, 200);
        }
      },
      selectSuggestion: function selectSuggestion(suggestion) {
        var searchService = this.get('searchService');
        searchService.set('searchTerm', suggestion.replace(/<.*?>/g, ''));
        searchService.set('suggestions', null);
        searchService.transitionSearch();
      }
    }
  });
});