define('webapp/components/page-link-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PageLinkTo = Ember.LinkComponent.extend({
    languageService: Ember.inject.service(),

    getPageRouteParams: function getPageRouteParams(page) {
      var languageService = this.get('languageService');
      var selectedLanguage = this.get('languageService.selectedLanguage');
      var langSlug = this.get('languageService.languageSlug');

      if (page.type === 'account') {
        return ['account'];
      } else if (page.fullSlug === '') {
        // index route;
        if (langSlug) {
          return ['page', langSlug];
        } else {
          return ['index'];
        }
      } else {
        if (langSlug) {
          return ['page', languageService.translateSlug(page, selectedLanguage)];
        } else {
          return ['page', page.fullSlug];
        }
      }
    },


    params: Ember.computed('page', 'languageService.selectedLanguage', function () {
      var page = this.get('page');
      return this.getPageRouteParams(page);
    })
  });

  exports.default = PageLinkTo;
});