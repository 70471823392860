define('webapp/components/account/documents-chooser', ['exports', 'webapp/settings/media-extensions', 'webapp/mixins/account/media-edit'], function (exports, _mediaExtensions, _mediaEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function calcPath(item) {
    var path = item.name + '.' + (item.generatedExtension || item.extension);
    if (item.directory) {
      path = item.directory + '/' + path;
    }
    return path;
  }

  exports.default = Ember.Component.extend(_mediaEdit.default, {
    documentExtensions: Ember.computed(function () {
      return _mediaExtensions.default.DOCUMENT_EXTENSIONS.slice();
    }),

    showAddItemModal: false,

    actions: {
      removeItem: function removeItem(item) {
        var items = this.get('items');
        items = items.filter(function (x) {
          return x !== item;
        });
        this.set('items', items);
      },
      addItem: function addItem() {
        this.set('showAddItemModal', true);
      },
      cancelAddItem: function cancelAddItem() {
        this.set('showAddItemModal', false);
      },
      confirmAddItem: function confirmAddItem(item) {
        var items = this.get('items');

        items.pushObject({
          path: calcPath(item),
          mediaId: item._id,
          description: Ember.get(item, 'infos.description') || '',
          copyright: Ember.get(item, 'infos.copyright') || ''
        });

        this.set('showAddItemModal', false);
      }
    }
  });
});