define('webapp/helpers/array-slice', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var array = params[0];
    var firstArg = params[1];
    var secondArg = params[2];
    return array && array.slice(firstArg, secondArg);
  });
});