define('webapp/helpers/format-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var price = params[0];
    var type = params[1];
    var res;

    if (price == undefined) return;

    price = parseFloat(price);

    if (isNaN(price)) {
      return 'invalid price';
    }

    if (type != 'decimals') {
      res = Math.floor(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
      res = Math.round(price % 1 * 100).toString();
      if (res.length == 1) {
        res = '0' + res;
      }
    }
    return res;
  });
});