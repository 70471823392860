define('webapp/components/map/search-map', ['exports', 'webapp/components/map/base-map'], function (exports, _baseMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function convertGeopoint(geoPoint) {
    return [geoPoint.lon, geoPoint.lat];
  } /* global mapboxgl */

  exports.default = _baseMap.default.extend({
    userProxy: Ember.inject.service(),
    searchService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    mapPopup: null,

    locationMarker: null,

    getLocationIcon: function getLocationIcon() {
      var markerClass = 'user-location-marker';
      if (!this.get('fasboot.isFastBoot')) {
        var div = document.createElement('div');
        div.classList.add(markerClass);
        return div;
      }
    },
    renderUserLocation: function renderUserLocation() {
      var location = this.get('userProxy.location');

      if (this.locationMarker) {
        this.locationMarker.remove();
      }

      if (location) {
        var marker = this.locationMarker = new mapboxgl.Marker(this.getLocationIcon()).setLngLat(convertGeopoint(location));
        marker.addTo(this.map);
      } else {
        this.locationMarker = null;
      }
    },
    mapLoaded: function mapLoaded() {
      var searchService = this.get('searchService');
      searchService.on('searchReceived', this, this._searchReceived);
      searchService.on('showPopup', this, this.createPopup);
      if (searchService.get('searchResult.hits')) {
        this._searchReceived();
      }
      this.renderUserLocation();
    },


    _obsUserLocation: Ember.observer('userProxy.location', function () {
      this.renderUserLocation();
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('searchService').off('searchReceived', this, this._searchReceived);
      this.get('searchService').off('showPopup', this, this.createPopup);
    },
    _searchReceived: function _searchReceived() {
      var _this = this;

      var map = this.get('map');
      var searchService = this.get('searchService');
      var hits = searchService.get('searchResult.markers');

      this.clearPopups();
      if (this._markerLayer) {
        this._markerLayer.forEach(function (marker) {
          return marker.remove();
        });
      }
      var markerLayer = this._markerLayer = [];
      var bounds = new mapboxgl.LngLatBounds();
      var hasBounds = false;
      hits.forEach(function (hit, idx) {
        if (hit.geoPoint && hit.geoPoint.lat && hit.geoPoint.lon) {
          var iconElement = _this.getIcon({ idx: idx + 1 });
          var lnglat = convertGeopoint(hit.geoPoint);
          iconElement.addEventListener('click', function () {
            _this.createPopup(hit);
          });
          var marker = new mapboxgl.Marker(iconElement).setLngLat(lnglat);
          bounds.extend(lnglat);
          marker.addTo(map);
          markerLayer.push(marker);
          hasBounds = true;
        }
      });

      if (hasBounds) {
        map.fitBounds(bounds, { padding: 40, maxZoom: 17, easing: function easing(t) {
            return t;
          }, duration: 500 });
      }
      this.renderUserLocation();
    }
  });
});