define('webapp/components/html-wrapper', ['exports', 'webapp/utils/image-path', 'webapp/utils/file-path'], function (exports, _imagePath, _filePath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    attributeBindings: ['fastboot.isFastBoot:uk-no-boot'],

    _setupClickHandlers: function _setupClickHandlers() {
      var router = this.get('router');

      function buildOnClickHandler(url) {
        return function (evt) {
          router.transitionTo(url);
          evt.stopPropagation();
          return false;
        };
      }

      this.$('a[link-to]').each(function (idx, elem) {
        var linkTo = elem.attributes['link-to'];

        elem.onclick = buildOnClickHandler(linkTo.value);
      });

      this.$('a[intern-link]').each(function (idx, elem) {
        elem.onclick = buildOnClickHandler(elem.attributes['href'].value);
      });
    },
    didInsertElement: function didInsertElement() {
      this._setupClickHandlers();
    },
    didUpdate: function didUpdate() {
      Ember.run.schedule('afterRender', this, this._setupClickHandlers);
    },


    shownHTML: Ember.computed('html', function () {
      var html = this.get('html');
      if (!html) {
        return;
      }

      // for backgrouund image, doesnt need quotes
      html = html.replace(/\({{image-path\s+(.*?)}}\)/g, function (match, path) {
        var split = path.split(/\s+/).map(function (x) {
          return x.replace(/['"]/g, '');
        });
        var size = split[1];
        return '(' + (0, _imagePath.default)(split[0], size) + ')';
      });

      html = html.replace(/["']?{{image-path\s+(.*?)}}["']?/g, function (match, path) {
        var split = path.split(/\s+/).map(function (x) {
          return x.replace(/['"]/g, '');
        });
        var size = split[1];
        return '"' + (0, _imagePath.default)(split[0], size) + '"';
      });

      html = html.replace(/{{file-path\s+(.*?)}}/g, function (match, path) {
        path = path.replace(/['"]/g, '');
        return '"' + (0, _filePath.default)(path) + '"';
      });

      return html;
    })
  });
});