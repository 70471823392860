define('webapp/components/uk-modal', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    // attributeBindings: ['uk-modal'],
    // 'uk-modal': true,
    //classNames: ['uk-modal'],
    viewService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    keyboardActivated: true,
    modalOpen: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._hiddenListener = function () {
        if (!_this.isDestroyed) {
          if (_this.get('showModal')) {
            _this.sendAction('cancelModal');
          }
          _this.set('modalOpen', false);
        }
      };

      this.element.addEventListener('hidden', this._hiddenListener);
    },
    willDestroyElement: function willDestroyElement() {
      if (this._modal) {
        this._modal.hide(true);
      }
      this.element.removeEventListener('hidden', this._hiddenListener);
    },
    _checkModal: function _checkModal() {
      var isFastBoot = this.get('fastboot.isFastBoot');
      if (!isFastBoot) {
        if (this.get('showModal')) {
          this._modal = UIkit.modal(this.element, {
            bgClose: false,
            escClose: false,
            keyboard: false,
            modal: false
          });
          this._modal.show();
          this.set('modalOpen', true);
        } else if (this._modal) {
          this._modal.hide(true);
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.schedule('afterRender', this, this._checkModal);
    },


    keyMapper: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function () {
      if (!this.get('showModal')) {
        return;
      }
      if (!this.get('viewService.uploadInProgress')) {
        this.sendAction('cancelModal');
      }
    })
  });
});