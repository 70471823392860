define('webapp/components/widgets/widget-entry-categories', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function sortTree(tree, sortFn) {
    if (tree) {
      return tree.map(function (elem) {
        return Ember.assign({}, { elem: elem }, { children: sortTree(elem.children) });
      }).sort(sortFn);
    }
  }

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var selectedCategories = this.get('data.options.categories') || [];
      var maxDepth = this.get('data.options.depth');
      var showEmpty = this.get('data.options.showEmpty');
      var sortOrder = this.get('data.options.sortOrder'); // title, titleTree, count, initial

      Ember.RSVP.all([this.get('cachedData').getCategories(), myStorage.search.ajaxPost({ data: { category: selectedCategories, size: 0 } })]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            categoryTree = _ref2[0],
            searchResult = _ref2[1];

        var categoryCounts = searchResult.aggs.categoryIds;
        var shownSubCategories = selectedCategories.map(function (catId) {
          return (0, _treeUtils.findInTree)(categoryTree, function (cat) {
            return cat._id === catId;
          });
        });

        shownSubCategories = (0, _treeUtils.mapTree)(shownSubCategories, function (cat, parent, level) {
          var count = categoryCounts[cat._id] || 0;
          if ((level === 0 || level <= maxDepth) && (showEmpty || count > 0)) {
            return Ember.assign({ count: count }, cat);
          }
        });

        if (sortOrder === 'titleTree') {
          shownSubCategories = sortTree(shownSubCategories, function (a, b) {
            return a.title.localeCompare(b.title);
          });
        }

        var shownCategories = [];

        (0, _treeUtils.walkTree)(shownSubCategories, function (cat) {
          shownCategories.push(cat);
        });

        if (sortOrder === 'title') {
          shownCategories = shownCategories.sort(function (a, b) {
            return a.title.localeCompare(b.title);
          });
        } else if (sortOrder === 'count') {
          shownCategories = shownCategories.sort(function (a, b) {
            return b.count - a.count;
          });
        }

        if (!_this.isDestroyed) {
          _this.set('shownCategories', shownCategories);
        }
      });
    }
  });
});