define('webapp/routes/account/messages/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    countService: Ember.inject.service(),

    model: function model() {
      var myStorage = this.get('myStorage');
      var countService = this.get('countService');
      return myStorage.message.ajaxPost({ param: 'list', data: {} }).then(function (res) {
        countService.resetNotifications();
        return res.items;
      });
    },
    afterModel: function afterModel() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var user = this.get('userProxy._id');
      // TODO JB mergecheck
      return;
      myStorage.notification.ajaxGet().then(function (res) {
        if (!res.message) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = res[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var notification = _step.value;

              if (notification.entry) {
                notification.isMyDream = notification.entry.owner === user;
              } else {
                notification.isMyDream = false;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          _this.controllerFor(_this.routeName).set('notifications', res);
        }
      });
    }
  });
});