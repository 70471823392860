define('webapp/utils/transform-widgets', ['exports', 'webapp/utils/find-by'], function (exports, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.createWidgetBuckets = undefined;


  function createBucket(widgets, positionOptions) {
    // let len = widgets && widgets.length;
    var allSmall = true;
    var allMedium = true;
    var allLarge = true;

    var isStacked = positionOptions.alignment === 'stacked';

    var pWidgets = widgets.map(function (w, idx) {
      var classes = '';
      // Prinzipiell: nur 1 Widget: uk-width-expand
      //   none (0)
      //   uk-flex-1
      //   uk-flex-auto
      //   uk-width-expand
      //   ------- bisheriges Feld
      //   + Grid widths z.B. uk-width-1-2 + @s @m @l @xl = options.classes

      if (w.options && w.options.classes) {
        classes += w.options.classes + ' ';
      }

      if (w.options && w.options.flexClass) {
        classes += w.options.flexClass + ' ';
      }

      if (isStacked) {
        if (idx === 0) {
          classes += 'uk-margin-top ';
        }
        // 1st: uk-margin-large-top
        // classes += 'uk-width-expand'; //weg
      }
      if (w.options && w.options.hiddenSmall) {
        classes += ' uk-hidden-small';
      } else {
        allSmall = false;
      }

      if (w.options && w.options.hiddenMedium) {
        classes += ' uk-hidden-medium';
      } else {
        allMedium = false;
      }

      if (w.options && w.options.hiddenLarge) {
        classes += ' uk-hidden-large';
      } else {
        allLarge = false;
      }

      w.classes = classes.trim();
      return w;
    });

    var wrapperClasses = [];
    if (allSmall) {
      wrapperClasses.push('uk-hidden-small');
    }
    if (allMedium) {
      wrapperClasses.push('uk-hidden-medium');
    }
    if (allLarge) {
      wrapperClasses.push('uk-hidden-large');
    }

    return {
      wrapperClass: wrapperClasses.join(' '),
      widgets: pWidgets,
      blockClass: positionOptions.blockClass,
      containerClass: positionOptions.containerClass,
      alignment: positionOptions.alignment,
      vertAlignment: positionOptions.vertAlignment,
      horizontalAlignment: positionOptions.horizontalAlignment,
      wrap: positionOptions.wrap,
      margin: positionOptions.margin
    };
  }

  function createWidgetBuckets(widgets, sitePositions) {
    sitePositions = sitePositions || [];
    if (widgets) {
      var widgetsMapRaw = {};
      widgets.forEach(function (w) {
        var position = w.widget && w.widget.position;

        var bucket = widgetsMapRaw[position];
        if (!bucket) {
          bucket = [];
          widgetsMapRaw[position] = bucket;
        }

        bucket.push(w);
      });

      var widgetsMap = {};
      for (var key in widgetsMapRaw) {
        if (widgetsMapRaw.hasOwnProperty(key)) {
          var positionOptions = (0, _findBy.default)(sitePositions, 'position', key);
          var options = positionOptions && positionOptions.options || {};
          widgetsMap[key] = createBucket(widgetsMapRaw[key], options);
        }
      }

      return widgetsMap;
    }
  }

  exports.createWidgetBuckets = createWidgetBuckets;
});