define("webapp/locales/de/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "application": {
      "searchAllRegions": "in allen Orten",
      "searchAllGroups": "in allen ",
      "breadcrumbsHome": "Home",
      "noCosts": "Kostenlos",
      "month": "pro Monat",
      "beta": {
        "infotext": "Diese Funktion wird nur visualisiert und steht in der Beta-Version nicht zur Verfügung."
      },
      "ui": {
        "action": "Aktion",
        "alphabetical": "Alphabetisch",
        "at": "am",
        "isActive": "Aktiv",
        "isNotActive": "Deaktiviert",
        "editDescription": "Beschreibung bearbeiten",
        "dreampoints": "Dreampoints",
        "dreampointsAllDreams": "Dreampoints deiner Einträge",
        "dreampointsDonate": "zu spendende Dreampoints",
        "dreampointsTooltip": "Du kannst mit Deinem Eintrag Dreampoints sammeln",
        "totalDreampoints": "Dreampoints aller deiner Einträge",
        "edit": "ändern",
        "editProfile": "Mein Profil bearbeiten",
        "editEvent": "Veranstaltung bearbeiten",
        "editPoi": "Ort bearbeiten",
        "editProduct": "Produkt bearbeiten",
        "editEntry": "bearbeiten",
        "deleteEntry": "löschen",
        "for": "für",
        "gavePoints": "hat dir {{points}} Punkte gegeben.",
        "goToEntry": "Eintrag aufrufen",
        "goToEvent": "Veranstaltung aufrufen",
        "goToPoi": "Ort aufrufen",
        "goToProduct": "Produkt aufrufen",
        "noEntry": "Sie haben noch keinen Eintrag angelegt. Jetzt gleich den ersten Eintrag anlegen",
        "noEntryProfile": "hat noch keinen Eintrag angelegt.",
        "noEvent": "Sie haben noch keine Veranstaltung angelegt. Jetzt gleich die erste Veranstaltung anlegen",
        "noPoi": "Sie haben noch keinen Ort angelegt. Jetzt gleich den ersten Ort anlegen",
        "noProduct": "Sie haben noch kein Produkt angelegt. Jetzt gleich das erste Produkt anlegen",
        "nothingFound": "Für diese Suche konnten wir keine Ergebnisse finden.",
        "trySearch": "Willst du es mit anderen Suchbegriffen probieren, wie zum Beispiel",
        "mandatory": "Pflichtfeld",
        "newEntry": "Neuen Eintrag anlegen",
        "newEvent": "Neue Veranstaltung anlegen",
        "newPoi": "Neuen Ort anlegen",
        "newProduct": "Neues Produkt anlegen",
        "addImage": "Bild hinzufügen",
        "addVideo": "Video hinzufügen",
        "addDocument": "Dokument hinzufügen",
        "gallery": "Bilder",
        "memberSince": "Mitglied seit ",
        "notEditable": "kann nicht verändert werden",
        "routingDescription": "Anfahrtsbeschreibung",
        "editShownProfile": "Meine Profilanzeige ändern",
        "advancedEdit": "Erweitert",
        "saveShownProfile": "Profiländerungen speichern",
        "stockPhotoLinks": "Links zu Bilddatenbanken",
        "total": "Gesamtsumme",
        "visibleForAll": "Für alle Benutzer sichtbar",
        "visibleForJoiner": "Für Joiner sichtbar",
        "visibleForFollower": "Für Follower sichtbar",
        "visibleForSupporter": "Für Supporter sichtbar",
        "visibleForNone": "Für Niemanden sichtbar",
        "readMore": "Weiterlesen",
        "userName": "Benutzername ",
        "years": "Jahre",
        "yearsFew": "Babyalter",
        "addImages": "Bilder hinzufügen",
        "addMedia": "Bilder/Videos hinzufügen",
        "editImage": "Bild bearbeiten",
        "fullView": "Gesamtansicht",
        "sort": "Sortierung",
        "relevance": "Relevanz",
        "distance": "Distanz",
        "distant": "entfernt",
        "here": "In der Nähe",
        "offer": "Angebote",
        "region": "Region",
        "certificate": "Zertifikate",
        "type": "Typ",
        "only": "Nur",
        "all": "Alle",
        "hasBeen": "wurde",
        "updated": "aktualisiert",
        "uploadedFileFor": "Hochgeladene Datei für: ",
        "confirmDeleteEntry": "Den Eintrag {{title}} unwiderruflich löschen?",
        "openDocument": "Datei öffnen",
        "access": "aufrufen",
        "galleryImages": "zur Galerie...",
        "mobileRotate": "Drehen für Vollbildansicht",
        "more": "mehr",
        "mailSubject": "Ich habe einen Interessanten Link gefunden",
        "mailBodyPre": "Hallo, ich habe etwas interessantes gefunden: ",
        "mailBodyPost": "",
        "showAllEntries": "Alle Einträge anzeigen",
        "entriesFocus": "Ausgewählte Einträge im Focus:",
        "entriesLatest": "Neueste Einträge im Focus:",
        "invalidEmbed": "Diese URL kann nicht eingebetted werden.",
        "unsavedChanges": "Ungespeicherte Änderungen",
        "discardUnsavedChanges": "Änderungen verwerfen und fortfahren",
        "discardUnsavedChangesMobile": "Verwerfen",
        "saveUnsavedChanges": "Speichern"
      },
      "widgets": {
        "inFocus": "Im Focus",
        "newestEntries": "Die neuesten Einträge",
        "highestRatedEntries": "Die meisten Dreampoints"
      },
      "newsletter": {
        "alreadySubscribed": "Sie sind bereits Abonnement des {{list}} Newsletters. Sie können die Newsletter-Abonnements in Ihrem Account aufrufen.",
        "alreadySubscribedNoList": "Sie haben sich bereits für den Newsletter angemeldet",
        "goToList": "Mailingliste aufrufen",
        "noAccess": "Der angebotene Entry-Newsletter kann mit deinen Berechtigungen nicht abonniert werden.",
        "subscribe": "Abonnieren",
        "subscribeWithAccount": "Mit deinem derzeitigen angemeldeten Benutzer abonnieren.",
        "subscribeList": "Du abonnierst diesen Newsletter aus: ",
        "subscribeThanks": "Danke für Ihr Newslettter-Abonnement. Sie bekommen zur Aktivierung noch eine Bestätigungsmail für das Opt-In."
      }
    },
    "opt-in": {
      "success-member-verified": "Die {{email}} wurde zur {{listname}} Liste hinzugefügt.",
      "error-can-not-update-member": "Die Änderungen an den Mitglieds Daten für {{email}} konnten nicht durchgeführt werden.",
      "error-list-does-not-need-validation": "Die angegebene liste hat keinen opt-int.",
      "error-list-does-not-exist": "Es wurde keine Liste für die angegebenen Daten gefunden.",
      "error-member-not-in-list": "Es wurde kein Mitglied einer Liste für die angegebenen Daten gefunden."
    },
    "opt-out": {
      "success-membership-terminated": "Die {{email}} wurde von der {{listname}} entfernt.",
      "error-can-not-update-member": "Die Änderungen an den Mitglieds Daten für {{email}} konnten nicht durchgeführt werden.",
      "error-list-does-not-have-opt-out": "Die angegebene Liste hat keinen opt-out.",
      "error-list-does-not-exist": "Es wurde keine Liste für die angegebenen Daten gefunden.",
      "error-member-not-in-list": "Es wurde kein Mitglied einer Liste für die angegebenen Daten gefunden."
    },
    "comments": {
      "reply": "AN: ",
      "heading": "Anregungen, Anmerkungen, Kommentare",
      "title": "Kommentare",
      "show": "Kommentare einblenden",
      "noComments": "Es wurden noch keine Kommentare abgegeben",
      "blockComments": "Die Kommentierung für diesen Post wurde beendet.",
      "message": {
        "afterSaveSuccess": "Ihr Kommentar wurde gespeichert.",
        "afterSaveUnlock": "Ihr Kommentar wurde gespeichert, muss allerdings noch von einem Administrator überprüft werden.",
        "afterSaveError": "Ihr Kommentar kann zur Zeit nicht gespeichert werden!"
      },
      "comment": {
        "title": "Kommentartitel",
        "userName": "Ihr Benutzername",
        "content": "Ihr Kommentar",
        "cancel": "Abbrechen",
        "submit": "Absenden",
        "button": "Einen neuen Kommentar verfassen",
        "buttonReply": "Antworten",
        "login": "Melden Sie sich an, um einen Kommentar abzugeben",
        "past": "hat",
        "replyFrom": "Antwort auf",
        "hasCommented": "kommentiert",
        "showReply": "Antwort anzeigen",
        "showReplies": "Antworten anzeigen",
        "anonymous": "Anonym"
      },
      "hierarchy": {
        "title": "Kommentaransicht:",
        "flat": "Flach",
        "nested": "Genested"
      }
    },
    "blog": {
      "ui": {
        "category": "Kategorie",
        "categoryHeading": "Aus der Kategorie",
        "writtenBy": "Geschrieben von",
        "written": "Geschrieben",
        "publishedIn": "Veröffentlicht in",
        "lastUpdated": "Zuletzt aktualisiert am",
        "subCategories": "Unterkategorien",
        "openSinglePost": "Zur Artikelansicht"
      }
    },
    "register": {
      "ui": {
        "companyData": "Ihre Firmenangaben",
        "companyDataMandatory": "Sie müssen Ihren Firmennamen angeben",
        "typeMinimum": "Sie müssen mindestens einen Typ auswählen",
        "personalData": "Ihre persönlichen Daten",
        "loginData": "Anmeldedaten",
        "addressData": "Adressdaten",
        "addressInfo": "Sie müssen ihre persönliche Adresse nicht eingeben. Diese führt aber zu korrekten Routen- und Entfernungskalkulationen.",
        "usernameInfo": "Wichtig: Das ist Ihr Benutzername für die Anmeldung",
        "emptyFirstName": "Sie müssen Ihren Vornamen angeben",
        "emptyLastName": "Sie müssen Ihren Nachnamen angeben",
        "emptyMail": "Sie müssen eine gültige E-Mail-Adresse angeben",
        "emptyUserName": "Sie müssen einen Benutzernamen angeben",
        "takenUserName": "Dieser Benutzername ist bereits vergeben",
        "takenEmail": "Diese Email wurde bereits von einem Benutzer angegeben.",
        "password": "Passwort",
        "passwordRepeat": "Passwort wiederholen",
        "language": "Sprache",
        "timezone": "Zeitzone"
      }
    },
    "social": {
      "dreamline": {
        "anonymous": "Anonym",
        "locked": "Dieser Inhalt ist nur für Mitträumer sichtbar",
        "lockedCTA": "Jetzt bei deisem Eintrag mitmachen",
        "allActivities": "Alle Dreamline-Aktivitäten",
        "sendComment": "Abschicken",
        "addMedia": "Bild / Video hinzufügen",
        "nowJoiner": "ist nun ein Unterstützer",
        "nowFollower": "folgt diesem Eintrag!",
        "comments": "schreibt:",
        "writeComment": "Kommentar schreiben...",
        "commentButton": "Kommentar",
        "nowLikes": "liked diesen Eintrag nun!",
        "nowSharing": "hat diesen Eintrag geteilt!",
        "noMessages": "Dieser Eintrag hat noch keine Aktivität. Schreibe jetzt den ersten Kommentar.",
        "noMessagesFiltered": "Keine Aktivität mit diesen Filtereinstellungen bei diesem Eintrag.",
        "fewDreampoints": " hat nur wenige Dreampoints. Kommentiere, like oder share den Eintrag.",
        "dreampointsInfo": "Das sind die Dreampoints des Eintrags. Mehr zu Dreampoints findest Du in Deinem Profil.",
        "isJoiner": "",
        "isComment": " kommentiert",
        "isLike": "",
        "isShare": " geteilt",
        "isFavorite": "",
        "isUpdate": " aktualisiert",
        "addImageToDream": "Dieses Bild zu deinem Eintrag hinzufügen",
        "isStep": " einen neuen Schritt gemacht",
        "lastEntry": "Letzte Eintragaktualisierung",
        "onlyJoinerVisible": "Nur für Mitträumer/Unterstützer sichtbar",
        "prefixLike": "mag diesen Eintrag seit ",
        "prefixShare": "hat diesen Eintrag vor ",
        "prefixJoiner": "unterstützt diesen Eintrag seit ",
        "prefixComment": "hat diesen Eintrag vor ",
        "prefixFavorite": "folgt diesem Eintrag seit ",
        "prefixUpdate": "hat diesen Eintrag vor ",
        "prefixStep": "hat vor ",
        "provideImage": "Ich stelle dieses Bild dem Eintragbesitzer zur Verfügung",
        "profile": "Profil",
        "depublished": "Dieser Kommentar wurde wegen nicht angebrachtem Verhalten entfernt.",
        "edited": {},
        "sharePart": "Teilen",
        "toggleStream": "Kommentare",
        "toggleStreamSingle": "Kommentar",
        "resetFilter": "Filter zurücksetzen",
        "filterBySteps": "Nach Steps filtern",
        "filteredBySteps": "Nach Steps gefiltert",
        "filteredBy": "Aktivitäten gefiltert nach ",
        "filterComment": "Kommentaren",
        "filterShare": "Shares",
        "filterFavorite": "Follows",
        "filterLike": "Likes",
        "filterStep": "Steps",
        "flagged": "Dieser Beitrag wurde gemeldet",
        "isCopycatJoiner": "auch träumen",
        "isFirstUpdate": " erstellt",
        "prefixCopycatJoiner": "möchte diesen Eintrag seit"
      },
      "report": {
        "title": "Melden",
        "send": "Meldung absenden",
        "headlinePrefix": "Sie sind dabei",
        "headlineSuffix": "zu melden.",
        "personSuffix": "s",
        "login": "Sie müssen angemeldet sein, um den Beitrag zu melden.",
        "reportSex": "Sexueller Inhalt",
        "reportSpam": "Dies ist Spam",
        "reportScam": "Falsche Darstellung, Mobbing.",
        "reportViolence": "Gewaltdarstellung",
        "reportOther": "Anderer anstößiger Inhalt"
      },
      "dream": {
        "edit": "Eintrag bearbeiten",
        "settings": "Eintrageinstellungen",
        "similar": "Ähnliche Einträge",
        "joinerApproved": "Du machst bei diesem Eintrag mit",
        "joinerPending": "Der Eintrageigentümer muss dein Mitmachen noch bestätigen",
        "joinerButton": "Bei diesem Eintrag mitmachen",
        "joinerButtonMobile": "Mitmachen",
        "joinerSupport": "Du unterstützt diesen Eintrag",
        "joinerSupportPending": "Der Eintrageigentümer muss deine Unterstützung noch erlauben",
        "joinerSupportButton": "Diesen Eintrag unterstützen",
        "joinerSupportButtonMobile": "Unterstützen",
        "joinerDream": "Gruppentraum",
        "joinerInfo": "Wenn der Eintrageigentümer dein Mitmachen bestätigt, wird dieser Eintrag zum Gruppentraum, bei dem du weitergehende Rechte hast.",
        "donateAmount": "Mit Geldbetrag unterstützen",
        "donateDreampoints": "Mit Dreampoints unterstützen",
        "donateTip": "Mit Rat und Tat unterstützen",
        "donateInfo": "Die Nachricht ist privat und kann nur vom Eintrageigentümer gelesen werden.",
        "giveEmail": "Meine E-Mail-Adresse weitergeben",
        "givePhone": "Meine Telefonnummer weitergeben",
        "member": "Mitglied",
        "members": "Mitglieder",
        "shareTitle": "Share the dream ",
        "noFollow": "Du folgst keinem Eintrag!",
        "noLikes": "Dir gefällt noch kein Eintrag!",
        "noSupport": "Du unterstützt keinen Eintrag",
        "followedDreams": "Einträgen denen du folgst",
        "noJoiner": "Du bist noch kein Mitglied eines Gruppentraumes",
        "meToo": "Will ich auch machen",
        "joinerMessage": "Gib hier deine Nachricht für den Eintrageigentümer ein:",
        "joinerSupportInfo": "Wir arbeiten laufend an Verbesserungen, wie z.B. mit Geld oder Entrypoints zu unterstützen. Stay tuned.",
        "meTooInfo": "Zeige dem Eintrageigentümer, dass du den Eintrag ganz toll findest und diesen Eintrag auch machen willst.",
        "done": "DONE",
        "registerFirstTitle": "Mitträumen, unterstützen, auch machen?",
        "registerFirstText": "Um bei einem Eintrag miträumen zu können, um den Eintrag zu unterstützen oder anzuzeigen, dass Du diesen Eintrag auch machen möchtest, musst Du angemeldet sein. Die Anmeldung bei share your entry geht schnell und ist kostenlos. Klicke einfach auf den \"Anmelden\" Button.",
        "findDreams": "Finde hier kleine und große Einträge und mache Deinen Eintrag wahr und/oder hilf anderen ihre Einträge zu realisieren."
      },
      "like": "Like",
      "share": "Share",
      "join": "Join",
      "comment": "Kommentar",
      "follow": "Follow",
      "Like": "Like",
      "Share": "Share",
      "Join": "Join",
      "Comment": "Kommentar",
      "Follow": "Follow",
      "Favorite": "Follow",
      "hasLiked": "Du magst diesen Eintrag bereits.",
      "Joiner": "Mitträumen",
      "CopycatJoiner": "Träumt deinen Eintrag"
    },
    "password": {
      "errors": {
        "password-and-conf-do-not-match": "Die beiden Passwörter müssen identisch sein.",
        "not-secure-enough": "Wir empfehlen ein Passwort mit einer Länge von mindestens 8 Zeichen, Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen ($@$!%*#?&)."
      },
      "quality": {
        "0": "Sehr Schlecht",
        "1": "Schlecht",
        "2": "Ausreichend",
        "3": "Gut",
        "4": "Sehr Gut"
      }
    },
    "account": {
      "ui": {
        "userNameNotEditableTooltip": "Ihr Benutzername. Sie können den angegebenen Benutzernamen in den Einstellungen ändern. Wichtig: Nach der Änderung müssen Sie sich erneut mit dem neuen Benutzernamen anmelden.",
        "registeredAs": "Sie sind momentan registriert als",
        "register": "Registrieren",
        "entryBannerImageNew": "Lege ein Hauptbild für Deinen Eintrag fest.",
        "profileBannerImageNew": "Lege ein Hauptbild für Dein Profil fest."
      }
    },
    "user": {
      "anonymous-user-name": "",
      "ui": {
        "accountToolbarLoginTooltip": "Kostenlos anmelden und eigenen Eintrag anlegen",
        "accountToolbarLogin": "Anmelden",
        "accountToolbarLogout": "Abmelden",
        "accountProgressbar": "Dein Profil ist zu {{value}}% komplett",
        "accountProgressbarEntry": "Dein Eintrag ist zu {{value}}% komplett",
        "accountMenuProfile": "Profil",
        "accountMenuSubscriptions": "Abonnements",
        "accountMenuSettings": "Einstellungen",
        "accountMenuCompanySettings": "Firmenangaben",
        "accountSettings": "Weitere Einstellungen",
        "accountMenuSkillsOffer": "Ich biete...",
        "accountMenuSkillsRequest": "Ich brauche...",
        "accountMenuSkillsSearch": "Gesuche und Angebote",
        "accountMenuFavorites": "Meine Favoriten",
        "accountMenuSupports": "Einträge die ich Unterstütze",
        "accountMenuFollows": "Einträge, denen ich folge",
        "accountMenuImages": "Bilder/Videos/Dokumente",
        "accountMenuSubscritions": "Mein Abonnement",
        "accountMenuReviews": "Rezensionen",
        "accountMenuRatings": "Bewertungen",
        "accountMenuActivities": "Dreampoints",
        "accountMenuSupport": "Unterstützte Einträge",
        "accountMenuLikes": "Einträge, die ich mag",
        "accountMenuJoin": "Meine Gruppenträume",
        "accountMenuMessages": "Nachrichten",
        "accountNoMessages": "Sie haben bis jetzt noch keine Nachrichten erhalten.",
        "accountMenuEntries": "Meine Einträge",
        "accountMenuEvents": "Meine Veranstaltungen",
        "accountMenuPoi": "Meine Orte",
        "accountMenuProducts": "Meine Produkte",
        "accountMenuNewsletter": "Meine Newsletter Abos",
        "accountMenuRegistrationData": "Anmeldedaten",
        "accountMenuPress": "Meine Artikel",
        "accountGreeting": "Hallo, ",
        "changeUserGroup": "Anderes Abonnement",
        "backToLogin": "Zurück zur Anmeldenseite",
        "companyName": "Firmenname",
        "companyNameTooltip": "Wichtig: Unter diesen Firmennamen erscheint dann auch Ihr Eintrag",
        "createNewAccount": "Ein neues Benutzerkonto erstellen",
        "deleteImage": "Bild löschen",
        "fillOutForm": "Zur Registrierung müssen alle Pflichtfelder vollständig ausgefüllt sein",
        "firstName": "Vorname",
        "lastName": "Nachname",
        "description": "Geben Sie hier eine allgemeine Beschreibung zur Person oder zum Unternehmen ein",
        "email": "E-Mail-Adresse",
        "userName": "Benutzername",
        "zip": "Postleitzahl",
        "city": "Ort",
        "street": "Straße",
        "housenumber": "Nr.",
        "country": "Land",
        "birthday": "Geburtstag",
        "gender": "M/W",
        "genderMale": "männlich",
        "genderFemale": "weiblich",
        "mobileNumber": "Mobilnummer",
        "iAmCustomer": "Ich möchte einen SHARE YOUR DREAM B2B Account anlegen",
        "loginTitle": "Benutzeranmeldung",
        "username": "Benutzername (E-Mail-Adresse)",
        "password": "Passwort",
        "oldPassword": "Bisheriges Passwort",
        "changePassword": "Ich möchte mein Passwort ändern",
        "selectCustomerType": "Sie sind ... bitte auswählen",
        "stayLoggedin": "Angemeldet bleiben",
        "stayLoggedinInfo": "Sollte dies ein öffentlich zugängliches Gerät sein, deaktivieren Sie bitte diese Option und melden sich zum Abschluss wieder ab.",
        "cannotLogin": "Sie können sich nicht anmelden? Klicken Sie hier, um Ihr Passwort zurückzusetzen.",
        "loginButton": "Anmelden",
        "registerTitle": "Neu bei uns?",
        "registerMessage": "Jetzt anmelden und sofort loslegen. Es geht schnell und einfach.",
        "registerButton": "Jetzt Benutzerkonto einrichten",
        "requestPasswordReset": "Neues Passwort anfordern und altes Passwort löschen",
        "uploadToMyStorage": "Neue Bilder in mein Verzeichnis hochladen",
        "myStorage": "Mein Verzeichnis",
        "uploadUserImage": "Benutzerbild auswählen",
        "uploadSelectFile": "wählen Sie eine Datei aus",
        "uploadDrop": "Zum Hochladen ziehen Sie eine Datei hierher oder ",
        "memberSince": "Mitglied seit ",
        "lastLogin": "Letzte Anmeldung am ",
        "accountMenuEntryPre": "Einträge, ",
        "pleaseSelect": "Geschlecht",
        "genderThird": "keine Angabe",
        "myAddedStorage": "Zur Verfügung gestellte Bilder",
        "dataUsage": "Die Datei wird in ",
        "dataUsageNone": "Die Datei wird noch nicht verwendet. Möchtest Du die Datei endgültig löschen?",
        "dataDelete": " weiteren Einträgen benutzt. Möchtest Du die Datei endgültig löschen? Überall, wo diese Datei verwendet wird, wird diese durch einen Platzhalter ersetzt.",
        "resetPasswordPage": "Setzen Sie Ihr Passwort neu",
        "resetPassword": "Passwort neu setzen",
        "resetPasswordSuccess": "Ihr Passwort wurde zurückgesetzt, sie können sich jetzt einloggen",
        "visibilityInfo": "Tipp: Was aus deinem Profil öffentlich angezeigt wird entscheidest du! Klicke auf das Auge-Symbol, um die Sichtbarkeit deiner Eingaben einzustellen:"
      },
      "company": {
        "noCustomerYet": "Ihrer Registrierung wurde noch kein Business-Konto zugewiesen.",
        "noCustomerValidation": "Ihr Business-Konto wurde noch nicht freigeschaltet.",
        "newCustomerAccount": "Ein Business-Konto anlegen",
        "email": "E-Mail zur Kontaktaufnahme",
        "copyContact": "Kontaktdaten aus Firmenangaben für den Eintrag übernehmen",
        "companyName": "Firmenname",
        "phone": "Telefonnummer zur Kontaktaufnahme",
        "fax": "Telefaxnummer für schriftliche Kontaktaufnahme",
        "website": "Webseite des Unternehmens (nur Domain ohne https://)",
        "additionalWebsite": "Weitere Webseite des Unternehmens (nur Domain ohne https://)",
        "facebook": "Facebook-Account des Unternehmens (nur Accountname)",
        "twitter": "Twitter-Account des Unternehmens (nur Accountname ohne @)",
        "google": "Google+-Account des Unternehmens (nur Accountname)",
        "instagram": "Instagram-Account des Unternehmens (nur Accountname)",
        "pinterest": "Pinterest-Account des Unternehmens (nur Accountname)",
        "youtube": "Youtube-Account des Unternehmens (nur Accountname)",
        "xing": "Xing-Account des Unternehmens (nur Accountname)",
        "linkedin": "LinkedIn-Account des Unternehmens (nur Accountname)",
        "remarks": "Anmerkungen"
      },
      "message": {
        "after-save-success": "Ihre Änderungen wurden gespeichert.",
        "after-save-error": "Ihre Änderungen konnten nicht gespeichert werden.",
        "can-not-logout": "Wir konnten Sie nicht abmelden!",
        "can-not-login": "Mit diesen Benutzer- und Passworteingaben können wir dich nicht anmelden! Ist der Benutzername richtig geschrieben? Das Passwort richtig eingegeben? Eventuell ist bei der Anmeldung auch etwas schief gegangen.",
        "registration-success": "Sie sollten bald ein Bestätigung E-Mail von uns erhalten.",
        "registration-error": "Bei ihrer Registrierung ist ein Fehler aufgetreten!",
        "conflict": "Dieser Benutzername existiert bereits, wählen Sie einen anderen!",
        "verify-success": "Ihre E-Mail-Adresse wurde bestätigt. Sie können sich jetzt anmelden: ",
        "verify-error": "Ihre E-Mail-Adresse konnte nicht überprüft werden. Bitte kontaktieren sie den Administrator.",
        "fileExistsError": "Upload fehlgeschlagen. Datei existiert bereits",
        "unknownError": "Unbekannter Fehler beim Hochladen der Datei",
        "fileSizeError": "Upload fehlgeschlagen. Datei ist zu groß",
        "upload-success": "Upload erfolgreich",
        "loginFailureTop": "Ist dein Benutzername richtig geschrieben? Hast du dein Passwort richtig eingegeben?",
        "loginFailureBottom": "Vielleicht hast du noch gar keinen Entryer-Account?"
      },
      "user": "Einträger",
      "users": "Einträgern"
    },
    "profile": {
      "press": {
        "filterAuthor": "Nach Autor filtern",
        "articleDetail": "Artikeldetail",
        "show": "einblenden",
        "hide": "ausblenden",
        "downloadImage": "Bild herunterladen",
        "downloadImages": "Bilder herunterladen",
        "downloadText": "Text exportieren"
      },
      "progress": {
        "title": "So verbesserst du dein Profil"
      },
      "marketplace": {
        "new": "Neuer Angebot oder Bedarf",
        "offer": {
          "title": "Ich biete an",
          "new": "Neues Angebot anlegen",
          "delete": "Löschen",
          "tags": "Tags für das Angbebot/Gesuch (wird damit besser gefunden)",
          "description": "Beschreibung",
          "descriptionPlaceholder": "Aussagekräftige Beschreibung, damit ein anderer Benutzer etwas damit anfangen kann.",
          "relatedEntry": "Zugeordneter Eintrag"
        },
        "request": {
          "title": "Ich benötige",
          "new": "Neues Gesuch anlegen",
          "delete": "Gesuch löschen"
        },
        "fields": {
          "maxRange": "Für mein Angebot bin ich bereits soviele km zu fahren",
          "spaceRequired": "Platzbedarf in m²",
          "shows": "Programmangebot",
          "livingAnimals": "Mitnahme von lebenden Tieren",
          "waterRequired": "Bedarf an Wasser in m³",
          "energyRequired": "Bedarf an Strom",
          "securityRequired": "Bedarf an Security",
          "loadingUnloading": "Bei An-/Abfahrt notwendig",
          "placeRequired": "Anforderungen an den Stellplatz"
        }
      },
      "messages": {
        "is": "ist",
        "youAre": "Sie sind",
        "want": "möchte",
        "youWant": "Sie möchten",
        "as": "als",
        "dreamer": "Einträger",
        "supporter": "Unterstützer",
        "approve": "Bestätigen",
        "enter": "beigetreten",
        "join": "joinen",
        "sendText": "Ihre Nachricht wurde versendet.",
        "shareDream": "Du hast diesen Eintrag erfolgreich geteilt.",
        "likeDream": "Du magst diesen Eintrag jetzt.",
        "favoriteDream": "Du folgst diesen Eintrag jetzt.",
        "joinDream": "Du willst diesen Eintrag mitträumen.",
        "donateDream": "Vielen Dank, dass du diesen Entryer mit Rat und Tat unterstützt hast, seinen Eintrag zu realisieren. Er erhält umgehend deine Nachricht und wird sich hoffentlich bei dir melden und deine Hilfe akzeptieren.",
        "has": "hat sich",
        "decided": "dazu entschieden",
        "dreamToo": "auch zu träumen",
        "commentPre": "hat",
        "commentSuff": "kommentiert",
        "follow": "folgt",
        "deletedUser": "Gelöschter Benutzer"
      },
      "newsletter": {
        "subscribed": "abonniert",
        "notSubscribed": "abonnieren",
        "privacyInformation": "Durch einen Klick auf «abonnieren» erklärst du dich damit einverstanden, dass wir dir die künftigen Newsletter dieses Abonnements zusenden (Opt-In). Du kannst den Newsletter jederzeit wieder abbestellen, indem du erneut auf «abonnieren» klickst (Opt-Out)."
      },
      "entries": {
        "entries": "Einträge von {{userName}}",
        "follows": "Einträge, denen {{userName}} folgt",
        "joins": "Einträge, bei denen {{userName}} mitmacht",
        "donates": "Einträge, die {{userName}} unterstützt",
        "copycats": "Einträge, die {{userName}} auch machen will"
      }
    },
    "entries": {
      "edit": "Eintrag <strong>{{title}}</strong> bearbeiten",
      "entry": {
        "tooltip": {
          "like": "Hier klicken, um diesen Eintrag ein Like zu geben",
          "noLike": "Like geht nur bei fremden Einträgen",
          "follow": "Hier klicken, um diesen Eintrag zu folgen",
          "noFollow": "Follow geht nur bei fremden Einträgen",
          "alreadyLiked": "Du magst diesen Eintrag bereits",
          "alreadyFollow": "Du folgst diesem Eintrag bereits",
          "noFollowNotLoggedIn": "Du musst angemeldet sein, um diesen Eintrag zu folgen",
          "copied": "Schön, dass du diesen Eintrag auch träumen willst. Dein Profilbild wurde diesem Eintrag hinzugefügt.",
          "alreadyCopied": "Du träumst diesen Eintrag bereits.",
          "filterShares": "Hier Klicken, um in der Entryline nur Shares anzuzeigen",
          "filterLikes": "Hier Klicken, um in der Entryline nur Likes anzuzeigen",
          "filterFollows": "Hier Klicken, um in der Entryline nur Follows anzuzeigen",
          "filterComments": "Hier Klicken, um in der Entryline nur Comments anzuzeigen"
        },
        "profile": {
          "edit": "Profil bearbeiten",
          "publicProfile": "zu meiner öffentlichen Profilseite"
        },
        "ui": {
          "address": "Meine Adressdaten",
          "access": "Sehen können diesen Eintrag nur",
          "allAccess": "Alle können diesen Eintrag sehen",
          "addEntry": "Einträge hinzufügen",
          "addStep": "Einen Schritt hinzufügen",
          "step": "Schritt",
          "editEntry": "Eintrag bearbeiten",
          "titlePlaceholder": "Titel eingeben (maximal 50 Zeichen)",
          "subtitlePlaceholder": "Untertitel eingeben (maximal 60 Zeichen)",
          "metaDescriptionPlaceholder": "Kurzbeschreibung mit maximal 200 Zeichen",
          "descriptionPlaceholder": "Beschreibung mit HTML. Doppelklick auf Text für HTML-Funktionsleiste (maximal 600 Zeichen).",
          "companyTitle": "Meine Unternehmensangaben",
          "commercialTitle": "Unternehmensinformationen zum Eintrag",
          "contactPerson": "Meine Kontaktdaten",
          "businessContact": "Unternehmens-Kontaktdaten",
          "addOpening": "Öffnungszeit hinzufügen",
          "agreementHours": "Geöffnet nach Absprache",
          "assignedCategories": "zugewiesene Kategorien",
          "assignedProducts": "zugewiesene Produkte",
          "assignedCertificates": "Meine Zertifizierungen",
          "categoryFields": "Weitere Angaben",
          "cssClass": "CSS-Klasse für den Inhalt",
          "deliveryInfo": "Informationen zum Lieferdienst",
          "exclusionHours": "Ausschlusszeiten",
          "galleryImages": "Bildergalerie (2 oder 3 Bilder je nach Anordnung)",
          "globalTitle": "Name",
          "hasKiosk": "Ich habe einen Verkaufsstand",
          "rentKiosk": "Ich möchte einen Verkaufsstand mieten",
          "imagesHorizontal": "Horizontal",
          "imagesOrientation": "Bildausrichtung",
          "imagesSquared": "Quadratisch",
          "imagesVertical": "Vertikal",
          "kioskDescription": "Beschreibung Ihres Verkaufsstandes",
          "logo": "Logo",
          "longDescription": "Langer Beschreibungstext",
          "mainImage": "Hauptbild",
          "isMainImage": "Diese Bild ist das Hauptbild",
          "isMainImageAndListImage": "Diese Bild erscheint als Hauptbild des Eintrags",
          "makeMainImage": "Diese Bild als Hauptbild festlegen",
          "makeMainImageTooltip": "Das Hauptbild wird oben im Profil angezeigt. Bilder, die nicht als Haupt- oder Listenbild ausgewählt sind, werden in der Bildergalerie angezeigt.",
          "makeListImageTooltip": "Suchergebnisse werden als Liste mit dem Listenbild angezeigt. Ist kein Listenbild ausgewählt, wird das Hauptbild verwendet.",
          "listImage": "Listenbild",
          "isListImage": "Diese Bild ist das Listenbild",
          "makeListImage": "Diese Bild als Listenbild festlegen",
          "metaDescription": "Kurzbeschreibung",
          "noEntries": "Keine Einträge gefunden.",
          "noDescriptions": "Der Eintrag hat noch keine Beschreibungstexte.",
          "openAt": "Geöffnet",
          "openingHours": "Öffnungszeiten",
          "openingInfo": "Anmerkungen zu den Öffnungszeiten",
          "openingReligious": "Adresse in überwiegend katholischer Gemeinde",
          "parking": "Parkmöglichkeiten",
          "parkingLots": "Anzahl",
          "parkingType": "Art",
          "personImage": "Bild des Ansprechpartners",
          "shortDescriptionInfo": "Ist gleichzeitig die Kurzbeschreibung",
          "sortword": "Alternativtitel für die Sortierung",
          "subtitle": "Beschreibender Untertitel",
          "skillsNeeded": "Für meinen Eintrag könnte ich gut gebrauchen...",
          "tags": "Tags",
          "title": "Der Eintrag erscheint unter folgenden Namen",
          "titleError": "Der Eintrag muss vor dem Speichern benannt werden",
          "visible": "Dieser Eintrag ist sichtbar",
          "routingPlaceholder": "Geben Sie hier Ihre Anfahrtsbeschreibung mit HTML ein.",
          "categories": "Kategorien, unter denen ich zu finden bin",
          "resolveAddress": "Koordinaten ermitteln",
          "resolveAddressInfo": "Bitte ermitteln Sie den Geopoint (Position auf der Karte) aus Ihrer Adresse. Ohne korrekten Geopoint können sie unter Umständen nicht richtig gefunden werden.",
          "resolveGeolocation": "Adresse ermitteln",
          "multiTitle": "Titel für diesen Schritt",
          "multiTitlePlaceholder": "Titel für diesen Schritt (maximal 50 Zeichen)",
          "multiDescription": "Beschreibung dieses Schrittes",
          "multiProgress": "Fortschritt ",
          "overallProgress": "Dieser Eintrag ist zu {{value}}% realisiert",
          "multiImages": "Bilder für diesen Schritt",
          "multiCode": "Zusätzlicher Code für diesen Schritt",
          "stepInDream": "In welcher Phase des Eintrags ist dieser Schritt?",
          "stepInDreamBefore": "vor der Realisation",
          "stepInDreamDuring": "während der Realisation",
          "stepInDreamAfter": "nach der Realisation",
          "added": "…wurde hinzugefügt.",
          "tabDescription": "Beschreibung",
          "tabDescriptionMobile": "Beschr.",
          "tabImages": "Bilder/Videos/Dokumente",
          "tabImagesMobile": "Bilder/Videos/Dateien",
          "tabCompany": "Unternehmensdaten",
          "tabCategories": "Kategorien",
          "tabCategoriesMobile": "Kat.",
          "tabRelations": "Verbindungen",
          "tabExhibitor": "Ausstellerangaben",
          "editEntryNew": "Eintrag anlegen",
          "descriptionInfo": "Tipp: Wenn Wörter markiert sind, können Stile zugewiesen werden.",
          "imagesInfo": "Bilder können unter \"Bilder/Videos/Dokumente\" bearbeitet werden."
        },
        "fields": {
          "prefixMissing": "Folgende Felder sind nicht ausgefüllt: \n",
          "address": {
            "houseNumber": "Hausnummer \n",
            "zip": "PLZ \n",
            "city": "Stadt \n",
            "street": "Straße \n",
            "country": "Land \n"
          }
        }
      },
      "progress": {
        "title": "So verbesserst du deinen Eintrag"
      },
      "open": "Eintrag im Frontend aufrufen",
      "person": {
        "ui": {
          "company": "Firma",
          "female": "Frau",
          "fullName": "Vorname und Name",
          "fullSize": "Volle Größe",
          "gender": "Geschlecht",
          "greeting": "Grußwort",
          "male": "Herr",
          "open": "Direkt zum Eintrag dieser Person",
          "personAddress": "Adresse der Person",
          "personContact": "Persönliche Kontaktdaten",
          "personImage": "Personenbild",
          "position": "Position/Typ",
          "quote": "Zitat/Bildunterschrift",
          "signature": "Unterschriftenbild"
        }
      },
      "product": {
        "edit": "Produkt <strong>{{title}}</strong> bearbeiten",
        "ui": {
          "addProduct": "Produkt hinzufügen",
          "noProducts": "Keine Produkte gefunden",
          "title": "Produkt"
        }
      },
      "poi": {
        "ui": {
          "title": "Der Ort erscheint unter folgenden Namen"
        }
      },
      "event": {
        "edit": "Veranstaltung <strong>{{title}}</strong> bearbeiten",
        "ui": {
          "addEvent": "Veranstaltung hinzufügen",
          "editEventNew": "Veranstaltung anlegen",
          "editEvent": "Veranstaltung bearbeiten",
          "openEvent": "Veranstaltung aufrufen",
          "noEvents": "Keine Veranstaltungen gefunden",
          "title": "Die Veranstaltung erscheint unter folgenden Namen",
          "startDate": "Veranstaltungsbeginn (Tag)",
          "endDate": "Veranstaltungsende (Tag)",
          "opening": "Öffnungszeiten",
          "cooperation": "Kooperationspartner",
          "directions": "Anfahrtsbeschreibung",
          "tabDescription": "Beschreibung",
          "tabOpening": "Datum und Zeiten",
          "tabCategories": "Kategorien",
          "tabRelations": "Verbindungen"
        }
      },
      "tabbar": {
        "basics": "Basisdaten",
        "entry": "Eintragdaten",
        "gallery": "Bilder",
        "layout": "Layout",
        "openingHours": "Öffnungszeiten",
        "order": "Bestellinformationen",
        "tooltip": {
          "entryNotSavedDetail": "Eintragdaten können erst bearbeitet werden, wenn der Eintrag mit Titel gespeichert wurde.",
          "entryNotSavedLayout": "Layoutdaten können erst bearbeitet werden, wenn der Eintrag mit Titel gespeichert wurde."
        }
      },
      "types": {
        "poi": "Poi",
        "standard": "Standard"
      },
      "ui": {
        "groupDetailName": "Name für Gruppeneinträge",
        "groupName": "Name für die Gruppen (Region)"
      },
      "entries": "Einträge"
    },
    "search": {
      "searchBarPlaceholder": "Suche...",
      "searchBarPlaceholderMobile": "Suche...",
      "removeDatefilter": "Datumfilter entfernen",
      "search": "Suche",
      "for": "nach",
      "resulted": "ergab",
      "hits": "Treffer",
      "andFilter": " und Filtereinstellungen",
      "openTags": "Tags einblenden",
      "closeTags": "Tags ausblenden",
      "allDistances": "Alle Entfernungen",
      "showAll": "Alles anzeigen"
    },
    "buttons": {
      "cancel": "Abbrechen",
      "ok": "Ok",
      "save": "Änderungen speichern",
      "saveMobile": "Speichern",
      "newPassword": "Neues Passwort anfordern",
      "delete": "löschen",
      "deleteFavorite": "Nicht mehr folgen",
      "deleteLike": "Nicht mehr liken",
      "selectThis": "Dieses auswählen",
      "meToo": "Ich möchte das auch machen",
      "alreadyMeToo": "Schön, dass du diesen Eintrag auch träumst.",
      "alreadyMeTooMobile": "Schön, dass du diesen Eintrag auch träumst.",
      "ready": "Fertig",
      "tryAgain": "Bitte probiere es noch einmal",
      "createAccount": "Jetzt EntryerAccount einrichten"
    },
    "accountTypes": {
      "aussteller": "Aussteller",
      "veranstalter": "Veranstalter",
      "gastgeber": "Gastgeber"
    },
    "openingHours": {
      "addOpening": "Öffnungszeit hinzufügen",
      "delete": "Löschen",
      "type": "Art der Öffnungszeit",
      "options": "Bereich",
      "additionalInfo": "Informationen zu den Öffnungszeiten",
      "date": {
        "day": {
          "di": "Di",
          "do": "Do",
          "fr": "Fr",
          "mi": "Mi",
          "mo": "Mo",
          "sa": "Sa",
          "so": "So"
        }
      },
      "dateS": "Datum",
      "time": "Uhrzeit",
      "from": "von",
      "to": "bis"
    },
    "media": {
      "ui": {
        "title": "Titel",
        "description": "Beschreibung",
        "copyright": "Copyright",
        "cropImage": "Zuschneiden",
        "cropImageDuplicate": "Duplizieren und zuschneiden",
        "filename": "Dateiname",
        "rotateImage": "Bild 90° im Uhrzeigersinn drehen",
        "resetImage": "Zurück zum Original",
        "cropFormat": "Formatvorgabe",
        "resetCrop": "Zuschnitt zurücksetzen",
        "processingImage": "Bild wird zugeschnitten, bitte warten",
        "imageSaved": "Bild gespeichert"
      }
    },
    "entryTypes": {
      "entry": "Eintrag",
      "event": "Veranstaltung",
      "poi": "Veranstaltungsort"
    },
    "syd": {
      "ui": {
        "dreamerCommercial": "B2B Standard",
        "userCommercial": "B2B Dreamer"
      }
    },
    "errors": {
      "description": "Dieses Feld",
      "inclusion": "{{description}} is not included in the list",
      "exclusion": "{{description}} is reserved",
      "invalid": "{{description}} is invalid",
      "empty": "{{description}} can't be empty",
      "blank": "{{description}} muss ausgefüllt sein",
      "email": "{{description}} muss eine valide E-Mail Adresse sein",
      "slug": "Slug darf nur Kleinbuchstaben, Zahlen und \"-\" enthalten"
    }
  };
});