define('webapp/components/custom-search', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    items: [],
    myStorage: Ember.inject.service(),
    showFilters: Ember.computed.alias('model.options.showFilters'),

    selectedCategories: [],
    selectedDateRange: null,
    calendarCenter: null, // center date for calendar

    firstSearchResult: null,

    search: function search() {
      var _this = this;

      var options = this.get('options');
      var selectedCategories = this.get('selectedCategories');
      var selectedDateRange = this.get('selectedDateRange');

      var categoryIds = [];

      if (options.categories && options.categories.length) {
        categoryIds = options.categories;
      }

      if (selectedCategories && selectedCategories.length) {
        categoryIds = categoryIds.concat(selectedCategories.map(function (x) {
          return x._id;
        }));
      }

      var query = {
        itemTypes: options.type,
        categoryIds: categoryIds.length ? categoryIds : undefined
      };

      if (selectedDateRange) {
        if (selectedDateRange.start) {
          query.startDate = selectedDateRange.start.toISOString();
        }
        if (selectedDateRange.end) {
          query.stopDate = selectedDateRange.end.toISOString();
        }
      }

      this.get('myStorage').search.ajaxPost({
        data: query
      }).then(function (res) {
        if (!_this.isDestroyed) {
          if (!_this.firstSearchResult) {
            // only for daycounts
            _this.set('firstSearchResult', res.hits);
          }
          _this.setProperties({
            items: res.hits,
            aggs: res.aggs
          });
        }
      });
    },


    dayCounts: Ember.computed('firstSearchResult', function () {
      var firstSearchResult = this.get('firstSearchResult');
      if (!firstSearchResult) {
        return {};
      }

      var items = firstSearchResult.filter(function (item) {
        return item.startDate;
      });

      var daysMap = {};

      items.forEach(function (item) {
        var startDate = new Date(item.startDate);
        var stopDate = new Date(item.stopDate || item.startDate);
        var currentDate = startDate;

        while (currentDate.getTime() <= stopDate.getTime()) {
          var dayKey = (0, _moment.default)(currentDate).format('YYYY-MM-DD');

          if (daysMap[dayKey]) {
            daysMap[dayKey] += 1;
          } else {
            daysMap[dayKey] = 1;
          }

          currentDate = (0, _moment.default)(currentDate).add(1, 'day').toDate();
        }
      });

      return daysMap;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this.search();
    },


    actions: {
      categoriesChanged: function categoriesChanged(cats) {
        this.set('selectedCategories', cats);
        this.search();
      },
      selectDateRange: function selectDateRange(range) {
        this.set('selectedDateRange', range);
        this.search();
      },
      clearDateRange: function clearDateRange() {
        this.set('selectedDateRange', null);
        this.search();
      }
    }
  });
});