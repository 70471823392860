define('webapp/components/search/category-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    isExpanded: false,
    classNameBindings: ['isEmpty'],

    isEmpty: Ember.computed.alias('category.isEmpty'),

    didReceiveAttrs: function didReceiveAttrs() {
      var selectedCategories = this.get('selectedCategories');
      var category = this.get('category');
      var categorySelected = selectedCategories && selectedCategories.indexOf(category._id) >= 0;
      this.set('categorySelected', categorySelected);
    },


    _obsCurrentExpanded: Ember.observer('currentExpanded', function () {
      // ensures only one category is expanded
      var currentExpanded = this.get('currentExpanded');
      if (currentExpanded != this) this.set('isExpanded', false);
    }),

    actions: {
      selectCategory: function selectCategory(cat) {
        this.sendAction('selectCategory', cat);
      },
      toggleExpand: function toggleExpand() {
        var isExpanded = this.get('isExpanded');
        this.set('isExpanded', !isExpanded);
        if (!isExpanded) {
          this.sendAction('expandItem', this);
          var category = this.get('category');
          this.sendAction('selectCategory', category);
        }
      },
      expandItem: function expandItem(comp) {
        this.set('currentExpandedChild', comp);
      }
    }
  });
});