define('webapp/routes/account/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SHOWN_FIELDS = ['userName', 'createdAt', 'lastLogin', 'language'];

  exports.default = Ember.Route.extend({
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),

    model: function model() {
      var userProxy = this.get('userProxy');

      return Ember.assign(userProxy.getProperties(SHOWN_FIELDS), {
        passwordValid: true,
        password: ''
      });
    }
  });
});