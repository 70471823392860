define('webapp/services/detail-service', ['exports', 'webapp/utils/head-tags', 'webapp/utils/image-path', 'webapp/config/environment'], function (exports, _headTags, _imagePath, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    detail: null,
    cmsService: Ember.inject.service(),
    router: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    languageService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    itemType: null,

    getItemType: function getItemType(path) {
      var languageService = this.get('languageService');
      var itemTypeRoutes = this.get('cmsService.itemTypeRoutes');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.keys(itemTypeRoutes)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var itemType = _step.value;

          var typeRoute = languageService.translateSlug(itemTypeRoutes[itemType]);
          if (path.indexOf(typeRoute) === 0) {
            return itemType;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    mapItem: function mapItem(item) {
      item.descriptions = item.descriptions && item.descriptions.map(function (desc) {
        if (desc.galleryRef) {
          desc.gallery = item.galleries && item.galleries.find(function (gal) {
            return gal.internalId === desc.galleryRef;
          });
        }
        return desc;
      });
      return item;
    },
    setupPage: function setupPage(pageRoute, page, params) {
      var _this = this;

      var languageService = this.get('languageService');
      var myStorage = this.get('myStorage');
      var path = params.path;
      var detailSlug = path.slice(languageService.translateSlug(page).length + 1);
      var itemType = this.getItemType(path);
      this.set('itemType', itemType);

      if (detailSlug) {
        var p = void 0;
        if (itemType === 'event') {
          p = myStorage.eventAdmin.ajaxGet({ param: 'slug/' + detailSlug });
        } else if (itemType === 'poi') {
          p = myStorage.poiAdmin.ajaxGet({ param: 'slug/' + detailSlug });
        } else {
          p = myStorage.entry.ajaxGet({ param: 'entryslug/' + detailSlug });
        }

        return p.then(function (detail) {
          _this.set('detail', _this.mapItem(detail));
        });
      } else {
        this.set('detail', null);
        return Ember.RSVP.resolve();
      }
    },
    getBreadcrumbs: function getBreadcrumbs(baseBreadcrumbs) {
      var breadcrumbs = baseBreadcrumbs.slice();

      // make detail route not clickable
      var detailBC = breadcrumbs[breadcrumbs.length - 1];
      breadcrumbs[breadcrumbs.length - 1] = { title: detailBC.title };

      var detail = this.detail;
      breadcrumbs.push({
        route: 'page',
        title: detail.title,
        routeParams: this.getDetailRoute(detail.slug, this.get('itemType'))
      });

      return breadcrumbs;
    },
    getDetailRoute: function getDetailRoute(entrySlug, itemType) {
      // TODO clean this up
      var message = this.get('message');
      var languageService = this.get('languageService');
      var itemTypeRoutes = this.get('cmsService.itemTypeRoutes');
      var route = itemTypeRoutes[itemType] && languageService.translateSlug(itemTypeRoutes[itemType]);

      if (!route) {
        message.infoUnique('no entry container defined for ' + itemType);
      }

      if (entrySlug) {
        route += '/' + entrySlug;
      }
      return route;
    },
    getHeadTags: function getHeadTags() {
      var detail = this.get('detail');
      // const user = this.get('userProxy');
      var headTags = [];
      // headTags.pushObject(generateHeadTag('twitter:card', 'summary'));
      // headTags.pushObject(generateHeadTag('twitter:site', '@shareyourdream'));
      // TODO this is wrong, needs to be owner
      // if (user.twitter) {
      //   headTags.pushObject(generateHeadTag('twitter:creator', user.twitter));
      // }
      headTags.pushObject((0, _headTags.generateHeadTag)('og:url', _environment.default.baseURL + this.get('router.currentURL')));
      headTags.pushObject((0, _headTags.generateHeadTag)('og:title', detail.title));
      headTags.pushObject((0, _headTags.generateHeadTag)('og:description', detail.metaDescription));
      if (detail.mainImage) {
        headTags.pushObject((0, _headTags.generateHeadTag)('og:image', (0, _imagePath.default)(detail.mainImage.path)));
      }

      return headTags;
    }
  });
});