define('webapp/routes/base-route', ['exports', 'webapp/config/environment', 'webapp/utils/head-tags', 'webapp/utils/image-path'], function (exports, _environment, _headTags, _imagePath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    cmsService: Ember.inject.service(),
    viewService: Ember.inject.service(),
    activeBodyClass: null,
    languageService: Ember.inject.service(),

    positions: _environment.default.widgetPositions,

    afterModel: function afterModel(model) {
      this.controllerFor('application').set('widgets', model.widgets);
      this.set('viewService.currentModel', model);
      this.set('titleToken', model.title);
    },
    getBreadcrumbs: function getBreadcrumbs(handlerInfos) {
      return [{
        route: handlerInfos.name,
        routeParams: this.get('currentModel.fullSlug'),
        title: this.get('currentModel.menuTitle')
      }];
    },
    getPageHeadTags: function getPageHeadTags(model) {
      var languageService = this.get('languageService');
      var headTags = [];

      var title = languageService.translateField(model, 'title');
      if (title) {
        headTags.pushObject((0, _headTags.generateHeadTag)('og:title', title));
      }

      var metaDescription = languageService.translateField(model, 'metaDescription');
      if (metaDescription) {
        headTags.pushObject((0, _headTags.generateHeadTag)('og:description', metaDescription));
        headTags.pushObject((0, _headTags.generateHeadTag)('description', metaDescription));
      }

      var metaKeywords = languageService.translateField(model, 'metaKeywords');
      if (metaKeywords) {
        headTags.pushObject((0, _headTags.generateHeadTag)('keywords', metaKeywords));
      }

      if (model.image) {
        headTags.pushObject((0, _headTags.generateHeadTag)('og:image', (0, _imagePath.default)(model.image.path)));
      }

      return headTags;
    }
  });
});