define('webapp/services/my-storage', ['exports', 'webapp/config/environment', 'webapp/utils/request'], function (exports, _environment, _request) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    userProxy: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var userProxy = this.get('userProxy');
      var fastboot = this.get('fastboot');

      var storages = {};

      function buildEndpoint(key) {
        storages[key] = _request.default.create({ fastboot: fastboot, userProxy: userProxy, config: { host: _environment.default.apiEndpoints[key] } });
      }

      // grab apiEndpoints from environment and create a Request Object for each
      Object.keys(_environment.default.apiEndpoints).forEach(buildEndpoint);

      this.setProperties(storages);
    }
  });
});