define('webapp/components/widgets/widget-back', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      back: function back() {
        try {
          history.back();
        } catch (err) {
          history.go(-1);
        }
      }
    }
  });
});