define('webapp/components/search/category-browser', ['exports', 'webapp/utils/tree-utils', 'webapp/utils/filter-categories', 'webapp/utils/find-by'], function (exports, _treeUtils, _filterCategories, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cachedData: Ember.inject.service(),
    searchService: Ember.inject.service(),

    categoryIdsMap: Ember.computed.alias('searchService.searchResult.aggs.categoryIds'),
    hideEmptyCats: true,
    selectedCategories: [],

    rawCategories: [],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('cachedData').getCategories().then(function (res) {
        _this.set('rawCategories', Ember.copy(res));
      });
    },


    filteredCategories: Ember.computed('rawCategories', 'categoryType', function () {
      var _getProperties = this.getProperties(['rawCategories', 'categoryType']),
          rawCategories = _getProperties.rawCategories,
          categoryType = _getProperties.categoryType;

      var filteredCategories = void 0;
      if (categoryType) {
        filteredCategories = (0, _filterCategories.default)(rawCategories, [categoryType]);
        if (filteredCategories && filteredCategories.length) {
          filteredCategories = filteredCategories[0].children;
        }
      } else {
        filteredCategories = rawCategories;
      }

      return filteredCategories;
    }),

    enhancedCategories: Ember.computed('filteredCategories', 'categoryIdsMap', function () {
      var _getProperties2 = this.getProperties(['filteredCategories', 'categoryIdsMap', 'hideEmptyCats']),
          filteredCategories = _getProperties2.filteredCategories,
          categoryIdsMap = _getProperties2.categoryIdsMap,
          hideEmptyCats = _getProperties2.hideEmptyCats;

      var enhancedCategories = filteredCategories;
      if (categoryIdsMap && filteredCategories) {
        enhancedCategories = (0, _treeUtils.mapTree)(filteredCategories, function (cat) {
          cat = Ember.copy(cat);
          var count = categoryIdsMap[cat._id] || 0;
          cat.count = count;
          cat.isEmpty = !count;
          if (!(hideEmptyCats && count === 0)) {
            return cat;
          }
        });
      }
      return enhancedCategories;
    }),

    actions: {
      expandItem: function expandItem(comp) {
        this.set('currentExpandedChild', comp);
      },
      selectCategory: function selectCategory(cat) {
        if (!cat.isEmpty) {
          var searchService = this.get('searchService');
          var selectedCategories = searchService.get('selectedCategories');
          var foundCat = (0, _findBy.default)(selectedCategories, '_id', cat._id);
          if (!foundCat) {
            selectedCategories.pushObject(cat);
          }
          searchService.transitionSearch();
        }
      }
    }
  });
});