define('webapp/routes/request-password-reset', ['exports', 'webapp/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    userProxy: Ember.inject.service(),
    i18n: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        page: this.get('cmsService').getPage('login')
      }).then(function (res) {
        return res.page;
      });
    },
    getBreadcrumbs: function getBreadcrumbs() {
      return [{
        route: 'login',
        title: 'Login'
      }];
    },


    actions: {
      requestPasswordReset: function requestPasswordReset(email) {
        var userStorage = this.get('myStorage.user');
        var message = this.get('message');

        if (!email) {
          return message.info('Bitte geben Sie eine E-Mail Addresse an.');
        }

        userStorage.ajaxPost({ param: 'request_password_reset', data: { email: email } }).then(function (res) {
          message.info('In Kürze sollten Sie eine Email mit einem Reset-Link erhalten.');
        }).catch(function (err) {
          console.error(err);
          message.error('In Kürze sollten Sie eine Email mit einem Reset-Link erhalten.');
        });
      }
    }
  });
});