define('webapp/utils/share-link-builder', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = shareLinkBuilder;
  function shareLinkBuilder(type, title, currentURL) {
    var url = '';

    if (type === 'facebook') {
      url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(_environment.default.baseUrl + currentURL);
      window.open(url, '_blank', 'width=600,height=460');
    }

    if (type === 'google') {
      url = 'https://plus.google.com/share?url=' + encodeURI(_environment.default.baseUrl + currentURL);
      window.open(url, '_blank', 'width=600,height=460');
    }

    if (type === 'pinterest') {
      url = 'https://www.pinterest.de/pin/create/button/?url=' + encodeURI(_environment.default.baseUrl + currentURL);
      window.open(url, '_blank', 'width=600,height=460');
    }

    if (type === 'tumblr') {
      url = 'https://www.tumblr.com/widgets/share/tool?shareSource=legacy&canonicalUrl=' + encodeURI(_environment.default.baseUrl + currentURL);
      window.open(url, '_blank', 'width=600,height=460');
    }

    if (type === 'twitter') {
      url = 'https://twitter.com/intent/tweet?text=' + encodeURI(title + ' - ') + 'Share%20Your%20Dream%20&url=' + encodeURI(_environment.default.baseUrl + currentURL);
      window.open(url, '_blank', 'width=600,height=460');
    }

    if (type === 'whatsapp') {
      url = 'whatsapp://send?text=' + encodeURIComponent(title) + ':%20' + encodeURI('https://www.shareyourdream.com/' + currentURL);
      window.location.href = url;
    }

    if (type === 'mail') {
      // mailSubject, mailBodyPre, mailBodyPost bei application.ui.
      // teaserText metaDescription
      url = 'mailto:?subject=' + i18n.t('ui.mailSubject') + encodeURIComponent(title) + '&body=' + i18n.t('ui.mailBodyPre') + encodeURI(_environment.default.baseUrl + currentURL) + i18n.t('ui.mailBodyPost');
      window.location.href = url;
    }

    return url;
  }
});