define('webapp/routes/application', ['exports', 'webapp/utils/find-by', 'webapp/config/environment'], function (exports, _findBy, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ApplicationRoute = Ember.Route.extend({
    userProxy: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    languageService: Ember.inject.service(),
    searchService: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    countService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    moment: Ember.inject.service(),

    setFastbootClass: function setFastbootClass() {
      if (!this.get('fastboot.isFastBoot')) {
        document.body.classList.remove('fastboot');
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.get('moment').changeLocale('de');
      var cmsService = this.get('cmsService');
      var userProxy = this.get('userProxy');
      var countService = this.get('countService');
      this.setFastbootClass();

      return Ember.RSVP.all([cmsService.setupDynamicPages(), userProxy.signIn(), userProxy.fetchUserGroups()]).then(function () {
        countService.refreshNotifications();
      });
    },
    model: function model() {
      return Ember.RSVP.hash({
        menus: this.get('cmsService').getMenus(),
        site: this.get('cachedData').getSite()
      });
    },
    afterModel: function afterModel(model) {
      this.set('siteTitle', model.site.title);
    },
    title: function title(tokens) {
      var siteTitle = this.get('siteTitle');
      var elems = tokens.slice();
      elems.push(siteTitle);
      return elems.join(' - ');
    }
  });

  if (_environment.default.handleErrors) {
    ApplicationRoute.reopen({
      actions: {
        error: function error(err) {
          var _this = this;

          console.error(err.stack);
          this.get('cachedData').getSite().then(function (site) {
            var errorPage = (0, _findBy.default)(site.pages, 'type', 'error');
            if (errorPage) {
              _this.replaceWith('page', errorPage.fullSlug);
            }
          });
        }
      }
    });
  }

  exports.default = ApplicationRoute;
});