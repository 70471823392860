define('webapp/services/language-service', ['exports', 'webapp/utils/find-by'], function (exports, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    i18n: Ember.inject.service(),
    viewService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    router: Ember.inject.service(),
    isSetup: false,
    selectedLanguage: 'de',

    // get from api config
    multiLanguage: true,
    useLanguageSlug: true,
    defaultLanguageOnRoot: true,

    languages: Ember.computed(function () {
      return ['de', 'en'];
    }),

    setLanguageSettings: function setLanguageSettings(languageSettings) {
      var multiLanguage = Ember.get(languageSettings, 'multiLanguage') || false;
      var useLanguageSlug = Ember.get(languageSettings, 'useLanguageSlug') || false;
      var defaultLanguageOnRoot = Ember.get(languageSettings, 'defaultLanguageOnRoot') || false;
      var languages = Ember.get(languageSettings, 'languages') || ['de', 'en'];

      this.setProperties({ multiLanguage: multiLanguage, useLanguageSlug: useLanguageSlug, defaultLanguageOnRoot: defaultLanguageOnRoot, languages: languages });
    },
    languageIsDefault: function languageIsDefault(lang) {
      return this.get('languages').indexOf(lang) === 0;
    },


    languagePrefix: Ember.computed('useLanguageSlug', 'selectedLanguage', 'defaultLanguageOnRoot', function () {
      if (this.get('useLanguageSlug')) {
        var selectedLanguage = this.get('selectedLanguage');
        if (this.languageIsDefault(selectedLanguage) && this.get('defaultLanguageOnRoot')) {
          return '';
        } else {
          return selectedLanguage;
        }
      } else {
        return '';
      }
    }),

    getTranslatedMenuTitle: function getTranslatedMenuTitle(page) {
      var selectedLanguage = this.get('selectedLanguage');
      return Ember.get(page, 'translations.' + selectedLanguage + '.menuTitle') || page.menuTitle;
    },
    translateField: function translateField(model, fieldName) {
      var selectedLanguage = this.get('selectedLanguage');
      return Ember.get(model, 'translations.' + selectedLanguage + '.' + fieldName) || Ember.get(model, fieldName);
    },
    translateSlug: function translateSlug(page) {
      var slug = page.fullSlug;
      var languagePrefix = void 0;
      if (this.get('multiLanguage')) {
        var selectedLanguage = this.get('selectedLanguage');
        var langSlugObj = (0, _findBy.default)(page.fullSlugs, 'language', selectedLanguage);
        slug = langSlugObj && langSlugObj.fullSlug || page.fullSlug;
        languagePrefix = this.get('languagePrefix');
      }
      return [languagePrefix, slug].filter(Boolean).join('/');
    },
    setLanguage: function setLanguage(language) {
      if (this.get('multiLanguage')) {
        this.set('selectedLanguage', language);
        this.set('i18n.locale', language);
        if (this.get('fastboot.isFastBoot')) {
          var fbDocument = Ember.getOwner(this).lookup('service:-document');
          fbDocument.documentElement.setAttribute('lang', language);
        } else {
          document.documentElement.setAttribute('lang', language);
        }
      }
    },
    setInitialLanguage: function setInitialLanguage(detectedLanguage) {
      if (!this.isSetup) {
        this.isSetup = true;
        this.setLanguage(detectedLanguage);
      }
    },


    // set language and switch to language specific route
    switchLanguage: function switchLanguage(lang) {
      var router = this.get('router');
      var currentRouteName = this.get('router.currentRouteName');
      var currentModel = this.get('viewService.currentModel');
      this.setLanguage(lang);

      var transitionSlug = void 0;

      if (currentModel.type !== 'account' && ['register', 'login'].indexOf(currentRouteName) < 0) {
        if (this.languageIsDefault(lang) && this.defaultLanguageOnRoot) {
          transitionSlug = currentModel.fullSlug;
        } else {
          transitionSlug = this.translateSlug(currentModel, lang);
        }

        var restSlug = currentModel.restSlug;

        router.transitionTo('page', [transitionSlug, restSlug].filter(Boolean).join('/'));
      }
    },


    languageSlug: Ember.computed('multiLanguage', 'selectedLanguage', 'defaultLanguageOnRoot', function () {
      var selectedLanguage = this.get('selectedLanguage');
      var defaultLanguageOnRoot = this.get('defaultLanguageOnRoot');
      var multiLanguage = this.get('multiLanguage');
      var isDefaultLanguage = this.languageIsDefault(selectedLanguage);

      var langSlug = '';
      if (multiLanguage) {
        langSlug = defaultLanguageOnRoot && isDefaultLanguage ? '' : selectedLanguage;
      }
      return langSlug;
    }),

    getNavigatorLanguage: function getNavigatorLanguage() {
      if (!window.navigator) {
        return;
      }

      var navLanguageFull = window.navigator.language;
      var navLanguage = navLanguageFull && navLanguageFull.slice(0, 2);
      var languages = this.get('languages');

      if (languages.indexOf(navLanguage) >= 0) {
        this.setLanguage(navLanguage);
      } else {
        console.log('unknown navigator language', navLanguageFull);
      }
    }
  });
});