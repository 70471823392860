define('webapp/mixins/list-route', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function populateCategories(allCats, catIds) {
    return catIds.map(function (catId) {
      return (0, _treeUtils.findInTree)(allCats, function (cat) {
        return cat._id === catId;
      });
    }).filter(Boolean).filter(function (cat) {
      return !cat.isLocked;
    });
  }

  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    i18n: Ember.inject.service(),
    modelName: null,

    beforeModel: function beforeModel() {
      var _this = this;

      var cachedData = this.get('cachedData');
      return cachedData.getCategories().then(function (cats) {
        _this.set('allCategories', cats);
      });
    },
    model: function model() {
      var allCategories = this.get('allCategories');
      var itemStorage = this.get('itemStorage');
      var userId = this.get('userProxy._id');

      if (!userId) {
        throw new Error('no userId');
      }

      return itemStorage.ajaxPost({ param: 'search', data: { filter: { owner: userId } } }).then(function (data) {
        var items = data.model;
        return items.map(function (item) {
          item.categories = populateCategories(allCategories, item.categories);
          return item;
        });
      });
    },


    actions: {
      addItem: function addItem() {
        this.transitionTo(this.get('editRoute') || this.routeName + '.edit', 'new');
      },
      deleteItem: function deleteItem(item) {
        var _this2 = this;

        var itemStorage = this.get('itemStorage');
        var i18n = this.get('i18n');
        var isFastBoot = this.get('fastboot.isFastBoot');
        if (!isFastBoot) {
          UIkit.modal.confirm(i18n.t('application.ui.confirmDeleteEntry', { title: item.title }).toString(), {
            labels: {
              cancel: i18n.t('buttons.cancel').toString(),
              ok: i18n.t('buttons.ok').toString()
            }
          }).then(function () {
            itemStorage.ajaxDelete({ param: item._id }).then(function (res) {
              var message = _this2.get('message');
              if (res.message) {
                message.info(res.message);
              }
              Ember.run.later(function () {
                _this2.refresh();
              }, 500);
            });
          });
        }
      }
    }
  });
});