define('webapp/components/press/article-item', ['exports', 'webapp/utils/image-path', 'webapp/config/environment'], function (exports, _imagePath, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DOWNLOAD_API = _environment.default.downloadApi.host;

  exports.default = Ember.Component.extend({
    showArticleExport: false,
    selectedImages: Ember.computed(function () {
      return [];
    }),

    closeModal: function closeModal() {
      this.set('showArticleExport', false);
    },


    downloadLink: Ember.computed('selectedImages.[]', function () {
      var selectedImages = this.get('selectedImages');

      var paths = selectedImages.map(function (x) {
        return x.path;
      });
      var joinedPaths = paths.join(';');
      var uriComp = encodeURIComponent(joinedPaths);

      return DOWNLOAD_API + '/' + uriComp + '/download.zip';
    }),

    actions: {
      toggleExpandedItem: function toggleExpandedItem(item) {
        this.sendAction('toggleExpandedItem', item);
        if (item === this.get('expandedItem')) {
          this.closeModal();
        }
      },
      showArticleExport: function showArticleExport() {
        this.set('showArticleExport', true);
      },
      closeModal: function closeModal() {
        this.closeModal();
      },
      toggleImageSelection: function toggleImageSelection(image) {
        var selectedImages = this.get('selectedImages');
        if (selectedImages.includes(image)) {
          selectedImages.removeObject(image);
        } else {
          selectedImages.addObject(image);
        }
      },
      downloadImages: function downloadImages(images) {
        console.log('downloadImages', images);
        window.open((0, _imagePath.default)(images[0].path));
      }
    }
  });
});