define('webapp/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    showHero: Ember.computed('widgets.hero', function () {
      return this.get('widgets.hero');
    }),

    viewService: Ember.inject.service(),
    router: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    userProxy: Ember.inject.service(),

    showSearchbar: Ember.computed.alias('viewService.showSearchbar'),

    showEditor: false,

    actions: {
      toggleMobileSearchbar: function toggleMobileSearchbar() {
        this.toggleProperty('showSearchbar');
      },
      toggleEditor: function toggleEditor() {
        this.toggleProperty('showEditor');
        if (!this.get('fastboot.isFastBoot')) {
          var body = document.body;
          if (this.get('showEditor')) {
            body.classList.add('editor-open');
          } else {
            body.classList.remove('editor-open');
          }
        }
      }
    }
  });
});