define('webapp/routes/page', ['exports', 'webapp/routes/base-route', 'webapp/utils/head-tags'], function (exports, _baseRoute, _headTags) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    templateSettings: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    viewService: Ember.inject.service(),
    searchService: Ember.inject.service(),
    detailService: Ember.inject.service(),
    profileContainer: Ember.inject.service(),
    categoryContainer: Ember.inject.service(),
    newsletterContainer: Ember.inject.service(),
    languageService: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),

    // services that handle special pages like detail, search
    pageHandlers: {
      search: 'searchService',
      entryContainer: 'detailService',
      profileContainer: 'profileContainer',
      categoryContainer: 'categoryContainer',
      newsletterContainer: 'newsletterContainer'
    },

    beforeModel: function beforeModel(transition) {
      this.set('breadcrumbs', null);
      var fingerprintService = this.get('fingerprintService');
      return fingerprintService.handleBeforeTransition(transition);
    },
    setupPage: function setupPage(page, params) {
      var pageHandlers = this.get('pageHandlers');

      var handlerService = pageHandlers[page.type];
      this.set('currentHandlerService', handlerService && this.get(handlerService));

      if (handlerService) {
        return this.get(handlerService).setupPage(this, page, params).then(function () {
          return page;
        });
      } else if (page.type === 'region') {
        this.setupRegionPage(page);
      }
      return Ember.RSVP.resolve(page);
    },
    model: function model(params) {
      var _this = this;

      if (!params.path) {
        return this.replaceWith('index');
      }

      var cmsService = this.get('cmsService');
      var languageService = this.get('languageService');
      var targetSlug = params.path;

      return this.get('cmsService').getPage(targetSlug, false).then(function (page) {
        if (page.detectedLanguage) {
          languageService.setInitialLanguage(page.detectedLanguage);
        }
        return _this.setupPage(page, params).then(function (page) {
          cmsService.set('activePage', page);
          return page;
        });
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var currentHandlerService = this.get('currentHandlerService');
      var headTags = this.getPageHeadTags(model);

      if (currentHandlerService) {
        if (currentHandlerService.afterPageModel) {
          currentHandlerService.afterPageModel(this, model);
        }
        if (currentHandlerService.getHeadTags) {
          headTags = headTags.concat(currentHandlerService.getHeadTags(this, model));
        }
      }

      this.set('headTags', (0, _headTags.removeDuplicates)(headTags));
    },
    setupRegionPage: function setupRegionPage(page) {
      var searchService = this.get('searchService');
      searchService.set('regionSlug', page.options && page.options.regionSlug);
      this.set('viewService.showSearchbar', true);
      //TODO do this different, e.g. with region categories
      if (page.subType === 'regionHome') {
        this.get('viewService').set('regionPage', page);
      }
    },
    blogCategoryBreadcrumbs: function blogCategoryBreadcrumbs() {
      var currentModel = this.get('currentModel');
      var breadcrumbs = [];
      var breadcrumbInfos = currentModel.category.breadcrumbs;
      var slug = currentModel.fullSlug + '/category';
      breadcrumbs.push({
        route: 'page',
        routeParams: slug,
        title: 'Kategorie'
      });
      for (var i = 0; i < breadcrumbInfos.length; i++) {
        var cat = breadcrumbInfos[i];
        slug += '/' + cat.slug;
        breadcrumbs.push({
          route: 'page',
          routeParams: slug,
          title: cat.title
        });
      }
      return breadcrumbs;
    },
    getBreadcrumbs: function getBreadcrumbs() {
      var languageService = this.get('languageService');
      var currentHandlerService = this.get('currentHandlerService');
      var currentModel = this.get('currentModel');

      var parentPages = currentModel.parentPages;

      var breadcrumbs = parentPages.map(function (p) {
        return {
          route: 'page',
          title: languageService.getTranslatedMenuTitle(p),
          routeParams: languageService.translateSlug(p)
        };
      });

      breadcrumbs.push({
        route: 'page',
        routeParams: currentModel.fullSlug,
        title: currentModel.menuTitle
      });

      if (currentModel.type === 'blogPost') {
        breadcrumbs.push({
          route: 'page',
          routeParams: currentModel.fullSlug + '/' + currentModel.post.slug,
          title: currentModel.post.title
        });
      } else if (currentModel.type === 'blogCategory') {
        breadcrumbs = breadcrumbs.concat(this.blogCategoryBreadcrumbs());
      }

      if (currentHandlerService && currentHandlerService.getBreadcrumbs) {
        breadcrumbs = currentHandlerService.getBreadcrumbs(breadcrumbs);
      }

      return breadcrumbs;
    }
  });
});