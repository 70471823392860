define('webapp/components/widgets/widget-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    message: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('result', {});
    },


    form: Ember.computed.alias('data.connection.item'),

    currentPath: Ember.computed("", function () {
      var form = this.get('form');
      return 'forms/' + form.slug;
    }),

    actions: {
      submit: function submit() {
        var _this = this;

        var message = this.get('message');
        var result = this.get('result');
        var formId = this.get('form._id');
        var fields = this.get('form.fields');
        var myStorage = this.get('myStorage');

        //check mandatory
        var missingFields = [];
        fields.forEach(function (field) {
          if (field.mandatory && !result[field.key]) {
            Ember.set(field, 'isMissing', true);
            missingFields.push(field);
          } else {
            Ember.set(field, 'isMissing', false);
          }
        });

        if (missingFields.length) {
          message.error('Folgende Felder müssen ausgefüllt werden: \n' + missingFields.map(function (x) {
            return x.name;
          }).join(', '));
        } else {
          // send submission
          myStorage.submission.create({ data: {
              form: formId,
              fields: result
            } }).then(function () {
            message.info('Formular abgesendet');
            _this.set('isSubmitted', true);
          }).catch(function (err) {
            message.error('Fehler beim verschicken des Formulars');
          });
        }
      },
      filesSent: function filesSent(field, files) {
        console.log('filesSent!');
        var key = field.key;

        var result = this.get('result');

        Ember.set(result, key, files);
        console.log('RESULT', this.get('result'));

        console.log('filesSent', field, files);
      }
    }
  });
});