define('webapp/controllers/page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    viewService: Ember.inject.service(),
    // queryParams: ['highlight'],
    // highlight: null,

    detailService: Ember.inject.service(),
    itemType: Ember.computed.alias('detailService.itemType'),
    entry: Ember.computed.oneWay('detailService.detail')
  });
});