define('webapp/components/detail/entry-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    cmsService: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.isDestroyed) {
        this.set('userAvatar', this.get('userProxy.image'));
        this.set('showMessageModal', false);
      }
    },


    actions: {
      sendMessage: function sendMessage() {
        this.set('showMessageModal', true);
      },
      closeMessageModal: function closeMessageModal() {
        this.set('showMessageModal', false);
      }
    }
  });
});