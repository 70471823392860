define('webapp/services/breadcrumb-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    cachedData: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    _updateBreadcrumbs: function _updateBreadcrumbs() {
      var handlerInfos = this.get('routing.router._routerMicrolib.currentHandlerInfos');
      var breadcrumbs = [];

      handlerInfos.slice(1) //get rid of application handlerInfo
      .forEach(function (elem) {
        if (elem.handler.getBreadcrumbs) {
          var elemBreadcrumbs = elem.handler.getBreadcrumbs(elem);
          breadcrumbs = breadcrumbs.concat(elemBreadcrumbs);
        }
      });

      breadcrumbs = breadcrumbs.filter(function (elem) {
        return elem.route !== 'index' && elem.title;
      });

      this.set('breadcrumbs', breadcrumbs);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.get('routing.router').on('didTransition', this, this._updateBreadcrumbs);
      this._updateBreadcrumbs();
    }
  });
});