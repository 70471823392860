define('webapp/services/share-service', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    i18n: Ember.inject.service(),

    shareLinkBuilder: function shareLinkBuilder(type, title, currentURL, metaDescription) {
      var url = '';
      var i18n = this.get('i18n');

      if (type === 'facebook') {
        url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(_environment.default.baseUrl + currentURL);
        window.open(url, '_blank', 'width=600,height=460');
      }

      if (type === 'google') {
        url = 'https://plus.google.com/share?url=' + encodeURI(_environment.default.baseUrl + currentURL);
        window.open(url, '_blank', 'width=600,height=460');
      }

      if (type === 'pinterest') {
        url = 'https://www.pinterest.de/pin/create/button/?url=' + encodeURI(_environment.default.baseUrl + currentURL);
        window.open(url, '_blank', 'width=600,height=460');
      }

      if (type === 'tumblr') {
        url = 'https://www.tumblr.com/widgets/share/tool?shareSource=legacy&canonicalUrl=' + encodeURI(_environment.default.baseUrl + currentURL);
        window.open(url, '_blank', 'width=600,height=460');
      }

      if (type === 'twitter') {
        url = 'https://twitter.com/intent/tweet?text=' + encodeURI(title + ' - ') + 'Share%20Your%20Dream%20&url=' + encodeURI(_environment.default.baseUrl + currentURL);
        window.open(url, '_blank', 'width=600,height=460');
      }

      if (type === 'whatsapp') {
        url = 'whatsapp://send?text=' + encodeURIComponent(title) + '%20' + encodeURIComponent(_environment.default.baseUrl + currentURL);
        window.location.href = url;
      }

      if (type === 'mail') {
        url = 'mailto:?subject=' + encodeURIComponent(i18n.t('application.ui.mailSubject')) + encodeURIComponent(title) + '&body=' + encodeURIComponent(i18n.t('application.ui.mailBodyPre')) + encodeURIComponent(_environment.default.baseUrl + currentURL + '\n');
        if (metaDescription) {
          url = url + encodeURIComponent(metaDescription + '\n\n') + i18n.t('application.ui.mailBodyPost');
        } else {
          url = url + i18n.t('application.ui.mailBodyPost');
        }

        window.location.href = url;
      }
    }
  });
});