define('webapp/components/medium-content-editable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      observer = Ember.observer,
      run = Ember.run;
  exports.default = Ember.Component.extend({
    tagName: 'div',
    attributeBindings: ['contenteditable'],
    editable: true,
    isUserTyping: false,
    plaintext: false,
    classNames: ['editable'],
    mediumEditor: undefined,

    placeholder: '',

    contenteditable: computed('editable', function () {
      return this.get('editable') ? 'true' : undefined;
    }),

    triggerChange: function triggerChange() {
      var $el = Ember.$(this.get('element'));
      Ember.run.next(this, function () {
        $el.trigger('change');
      });
    },
    didInsertElement: function didInsertElement() {
      var placeholder = this.get('placeholder');

      var options = {
        targetBlank: true
      };

      if (placeholder) {
        options.placeholder = {
          text: placeholder,
          hideOnClick: true
        };
      }

      var _editor = new MediumEditor(this.$(), Ember.assign(options, this.get('options')));
      this.set('mediumEditor', _editor);

      // Setup listeners this way to support IE11 / IE10
      // (browsers that don't natively support the input event)
      Ember.run.scheduleOnce('afterRender', this, function () {
        var $el = Ember.$(this.get('element'));
        $el.bind('blur keyup paste copy cut mouseup input', this.triggerChange.bind(this));
        Ember.$('.medium-editor-toolbar').bind('mouseup', this.triggerChange.bind(this));
      });
      return this.setContent();
    },


    setUserFinishedTyping: function setUserFinishedTyping() {
      if (this.get('isUserTyping')) {
        var action = this.attrs.onFinishedTyping;

        if (typeof action === 'string') {
          this.sendAction('onFinishedTyping');
        } else if (typeof action === 'function') {
          action();
        }
      }

      if (!this.isDestroyed) {
        return this.set('isUserTyping', false);
      }
    },

    willDestroyElement: function willDestroyElement() {
      var $el = Ember.$(this.get('element'));
      $el.unbind('blur keyup paste copy cut mouseup input', this.triggerChange.bind(this));
      Ember.$('.medium-editor-toolbar').unbind('mouseup', this.triggerChange.bind(this));
      this.get('mediumEditor').destroy();
    },
    change: function change() {
      if (this.get('plaintext')) {
        return this.set('value', this.$().text());
      } else {
        return this.set('value', this.$().html());
      }
    },
    focusOut: function focusOut() {
      return this.setUserFinishedTyping();
    },
    keyDown: function keyDown(event) {
      if (!event.metaKey) {
        run.debounce(this, 'setUserFinishedTyping', 2000);
        if (!this.isDestroyed) {
          return this.set('isUserTyping', true);
        }
      }
    },


    valueDidChange: observer('value', function () {
      if (this.$() && this.get('value') !== this.$().html()) {
        this.setContent();
      }
    }),

    setContent: function setContent() {
      if (this.$() && this.get('mediumEditor')) {
        var value = this.get('value');
        this.get('mediumEditor').setContent(value === undefined ? '' : value);
      }
    }
  });
});