define('webapp/components/map/map-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        _this.set('assignTo', _this);
        if (!_this.element) _this.createElement();
        var elem = $(_this.element);
        elem.remove();
      });
    }
  });
});