define('webapp/components/content-builder-view/containers/panel', ['exports', 'webapp/components/content-builder-view/containers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    nestedElementsGrid: Ember.computed('options.nestedElementsInGrid', function () {
      console.log(this.get('options.nestedElementsInGrid'));
      if (this.get('options.nestedElementsInGrid')) {
        var nestedElementsGrid = 'uk-grid';
        return nestedElementsGrid;
      }
    })

  });
});