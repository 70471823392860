define('webapp/services/user-proxy', ['exports', 'ember-ajax/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var signedInFields = ['_id', 'email', 'token'];
  var nameFields = ['firstName', 'lastName'];

  // TODO move user fields to seperate user object of userProxy
  var USER_FIELDS = ['_id', 'userName', 'firstName', 'lastName', 'gender', 'email', 'description', 'token', 'image', 'banner', 'userGroups', 'adminGroup', 'accessPermissions', 'address', 'contact', 'geoPoint', 'additionalData', 'shownFields', 'createdAt', 'options', 'lastLogin', 'language'];

  var WRITEABLE_USER_FIELDS = ['userName', 'firstName', 'lastName', 'gender', 'description', 'image', 'banner', 'address', 'contact', 'geoPoint', 'options', 'additionalData', 'shownFields', 'language'];

  var LS_USER_KEY = 'userfe';

  function parseGeoPoint(data) {
    if (data.lat && data.lon) {
      return {
        lat: data.lat,
        lon: data.lon
      };
    }
  }

  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    i18n: Ember.inject.service(),
    message: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    _id: null,
    firstName: null,
    lastName: null,
    email: null,
    street: null,
    city: null,
    zip: null,
    token: null,
    image: null,
    options: null,
    geoPoint: null,

    location: Ember.computed('geoPoint.lat', 'geoPoint.lon', function () {
      var geoPoint = this.get('geoPoint');
      return geoPoint && parseGeoPoint(geoPoint);
    }),

    __setUser: function __setUser(user) {
      var data = Ember.getProperties(user, USER_FIELDS);
      data.address = data.address || {};
      data.contact = data.contact || {};
      data.additionalData = data.additionalData || {};
      data.options = data.options || {};

      this.setProperties(data);
    },
    __clearUser: function __clearUser() {
      var props = {};
      USER_FIELDS.forEach(function (field) {
        props[field] = null;
      });
      this.setProperties(props);
    },
    _isLoggedIn: function _isLoggedIn() {
      var user = this.getProperties(signedInFields);

      return user && user._id && user.email && user.token;
    },


    isLoggedIn: Ember.computed.apply(undefined, signedInFields.concat([function () {
      return this._isLoggedIn();
    }])),

    // only used for comments
    shownName: Ember.computed.apply(undefined, nameFields.concat([function () {
      var userName = this.get('userName');
      if (userName) {
        return userName;
      } else {
        var user = this.getProperties(nameFields);
        var name = user.firstName + ' ' + user.lastName;

        return name.trim();
      }
    }])),

    _login: function _login(user) {
      this.__setUser(user);
      var isFastboot = this.get('fastboot.isFastBoot');
      if (user._id && user.token) {
        if (!isFastboot) {
          localStorage.setItem(LS_USER_KEY, JSON.stringify({ _id: user._id, token: user.token }));
        }
      }
    },
    _logout: function _logout() {
      var isFastboot = this.get('fastboot.isFastBoot');
      if (!isFastboot) {
        localStorage.removeItem(LS_USER_KEY);
      }
      this.__clearUser();
    },
    getUser: function getUser() {
      var user = this.getProperties(USER_FIELDS);
      user.additionalData = Ember.assign({}, user.additionalData || {});
      user.address = Ember.assign({}, user.address || {});
      user.options = Ember.assign({}, user.options || {});
      user.contact = Ember.assign({}, user.contact || {});
      return user;
    },
    signIn: function signIn() {
      var _this = this;

      var isFastboot = this.get('fastboot.isFastBoot');
      var rawUser = isFastboot ? '' : localStorage.getItem(LS_USER_KEY);
      if (rawUser) {
        var user = JSON.parse(rawUser);

        return this.get('myStorage.user').ajaxGet({ param: 'self', token: user.token }).then(function (retUser) {
          retUser.token = user.token;
          _this._login(retUser);
          return true;
        }).catch(function (err) {
          console.log('user not found');
          if (!isFastboot) {
            localStorage.removeItem(LS_USER_KEY);
          }
          return false;
        });
      } else {
        return Ember.RSVP.resolve(false);
      }
    },
    updateUser: function updateUser(data) {
      var _this2 = this;

      var myStorage = this.get('myStorage');
      var message = this.get('message');
      var i18n = this.get('i18n');

      return myStorage.user.ajaxPut({ param: 'self', data: data }).then(function () {
        message.info(i18n.t('user.message.after-save-success').toString());
        _this2.setProperties(data);
      }).catch(function (err) {
        console.error(err.stack);
        if ((0, _errors.isConflictError)(err)) {
          message.error(i18n.t('user.message.after-save-error').toString());
        } else {
          message.error(i18n.t('user.message.after-save-error').toString());
        }
      });
    },


    // pick the saveable fields from data and update user
    saveUser: function saveUser(data) {
      var updateData = Ember.getProperties(data, WRITEABLE_USER_FIELDS);
      return this.updateUser(updateData);
    },
    getWritableData: function getWritableData() {
      return Ember.getProperties(this, WRITEABLE_USER_FIELDS);
    },


    userGroupSlugs: Ember.computed('userGroups', function () {
      var userGroups = this.get('userGroups');
      return userGroups && userGroups.map(function (x) {
        return x.slug;
      });
    }),

    userGroupsString: Ember.computed('userGroups', function () {
      var userGroups = this.get('userGroups');
      if (!userGroups) {
        return '';
      }
      var userGroupsNames = userGroups.map(function (x) {
        return x.name;
      });
      if (userGroupsNames.length <= 1) {
        return userGroupsNames[0];
      } else {
        return userGroupsNames.slice(0, -1).join(', ') + ' und ' + userGroupsNames[userGroupsNames.length - 1];
      }
    }),

    // only biostadt
    relevantUserGroups: Ember.computed('userGroups', function () {
      var userGroups = this.get('userGroups');
      if (!userGroups) {
        return;
      }
      var slugs = ['aussteller', 'gastgeber', 'veranstalter'];

      return slugs.map(function (slug) {
        return userGroups.find(function (group) {
          return group.slug === slug;
        });
      });
    }),

    assignedEntryId: Ember.computed('accessPermissions', function () {
      var permissions = this.get('accessPermissions');
      if (permissions) {
        var entryPerm = permissions.find(function (perm) {
          return perm.targetModel === 'Entry' && perm.target;
        });
        return entryPerm && entryPerm.target;
      }
    }),

    assignedEntryIds: Ember.computed('accessPermissions', function () {
      var permissions = this.get('accessPermissions');
      if (permissions) {
        return permissions.filter(function (perm) {
          return perm.targetModel === 'Entry' && perm.target;
        }).map(function (perm) {
          return perm.target;
        });
      } else {
        return [];
      }
    }),

    fetchEntry: function fetchEntry() {
      var myStorage = this.get('myStorage');
      var entryId = this.get('assignedEntryId');

      if (entryId) {
        return myStorage.entryAdmin.ajaxGet({ param: entryId });
      } else {
        // message.error('Dem Benutzer ist kein Eintrag zugeordnet!');
        console.log('no entry assigned');
        return Ember.RSVP.reject(new Error('no entry assigned to user'));
      }
    },
    fetchOwnEntries: function fetchOwnEntries() {
      var myStorage = this.get('myStorage');
      var owner = this.get('_id');

      return myStorage.search.ajaxPost({
        data: {
          owner: owner,
          itemTypes: 'entry'
        }
      }).then(function (res) {
        return res.hits;
      });
    },
    fetchEvents: function fetchEvents(entry) {
      var _this3 = this;

      var myStorage = this.get('myStorage');

      var eventIds = entry.events.map(function (x) {
        return x.item;
      });
      return myStorage.eventAdmin.ajaxPost({
        param: 'search',
        data: { filter: { _id: { $in: eventIds } } }
      }).then(function (res) {
        var events = res.model.filter(function (x) {
          return x.geoPoint && x.geoPoint.lat;
        }).map(function (x) {
          return { title: x.title, geoPoint: x.geoPoint };
        });

        _this3.set('entryEvents', events);
      });
    },
    fetchUserGroups: function fetchUserGroups() {
      var _this4 = this;

      var myStorage = this.get('myStorage');

      return myStorage.userGroup.ajaxGet().then(function (groups) {
        _this4.set('availableUserGroups', groups);
      });
    },
    fetchData: function fetchData() {
      // TODO get location from user.location. if not available, get location from first assigned entry
      // return this.fetchEntry()
      //   .then(entry => {
      //     const location = entry && parseGeoPoint(entry.geoPoint);
      //     this.set('location', location);
      //
      //     return this.fetchEvents(entry);
      //   })
      //   .catch(err => {
      //     this.set('location', null);
      //   });
    },


    accountRoutes: Ember.computed('userGroups', function () {
      var userGroups = this.get('userGroups') || [];
      var accountRoutes = {};

      userGroups.forEach(function (group) {
        if (group.availableAccountRoutes) {
          group.availableAccountRoutes.forEach(function (route) {
            accountRoutes[route] = true;
          });
        }
      });

      return accountRoutes;
    })
  });
});