define('webapp/routes/account/favorites', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),

    model: function model() {
      var myStorage = this.get('myStorage');
      return myStorage.favorite.ajaxGet({ param: 'list' }).then(function (res) {
        return res;
      });
    },


    actions: {
      deleteFavorite: function deleteFavorite(item) {
        var _this = this;

        var myStorage = this.get('myStorage');

        myStorage.favorite.ajaxDelete({ param: item._id }).then(function (res) {
          var message = _this.get('message');

          if (res.message) {
            message.info(res.message);
          }

          _this.refresh();
        });
      }
    }
  });
});