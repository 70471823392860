define('webapp/routes/account/company', ['exports', 'webapp/utils/defaults-deep', 'ember-cp-validations', 'webapp/utils/clone-deep'], function (exports, _defaultsDeep, _emberCpValidations, _cloneDeep) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true, description: 'Titel' }),
    'address.street': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      description: 'Strasse'
    }),
    'address.houseNumber': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      description: 'Hausnummer'
    }),
    'address.zip': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      description: 'Postleitzahl'
    }),
    'address.city': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      description: 'Stadt'
    }),
    'contact.phone': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      description: 'Telefon'
    }),
    'contact.email': (0, _emberCpValidations.validator)('format', {
      presence: true,
      type: 'email',
      description: 'Email'
    })
  });

  function defaultCustomer(data, ownerInjection) {
    return Ember.Object.extend(Validations).create(ownerInjection, (0, _defaultsDeep.default)(Ember.assign({}, data), {
      title: '',
      address: {
        street: '',
        houseNumber: '',
        city: '',
        zip: '',
        country: ''
      },
      contact: {
        phone: '',
        email: ''
      }
    }));
  }

  exports.default = Ember.Route.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    viewService: Ember.inject.service(),

    model: function model() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var controller = this.controllerFor(this.routeName);

      return myStorage.customer.ajaxGet().then(function (customer) {
        // customer doesnt exist yet
        if (customer === null) {
          controller.set('isNew', true);
        } else {
          controller.set('isNew', false);
        }

        return defaultCustomer(customer || {}, Ember.getOwner(_this).ownerInjection());
      });
    },


    actions: {
      saveCustomer: function saveCustomer(data) {
        var _this2 = this;

        data = (0, _cloneDeep.default)(data);
        var myStorage = this.get('myStorage');
        var message = this.get('message');
        var controller = this.controllerFor(this.routeName);
        var isNew = controller.get('isNew');

        var promise = void 0;

        if (isNew) {
          promise = myStorage.customer.ajaxPost({ data: data });
        } else {
          promise = myStorage.customer.ajaxPut({ data: data });
        }

        promise.then(function () {
          message.info('Kunde gespeichert');
          _this2.set('viewService.skipScroll', true);
          _this2.refresh();
        }).catch(function (err) {
          message.info('Fehler beim speichern des Kunden </br> ' + (err.payload && err.payload.message));
        });
      }
    }
  });
});