define('webapp/routes/index', ['exports', 'webapp/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    cmsService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    searchService: Ember.inject.service(),
    viewService: Ember.inject.service(),
    languageService: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),

    firstVisit: true,

    setupLanguage: function setupLanguage() {
      var userProxy = this.get('userProxy');
      var languageService = this.get('languageService');
      var cachedData = this.get('cachedData');

      return cachedData.getSite().then(function (site) {
        languageService.setLanguageSettings(site);
        if (userProxy.language) {
          languageService.setLanguage(userProxy.language);
        } else {
          languageService.getNavigatorLanguage();
        }
      });
    },
    maybeSwitchToLanguageHomePage: function maybeSwitchToLanguageHomePage() {
      if (this.get('fastboot.isFastBoot')) {
        return;
      }
      var languageService = this.get('languageService');
      if (languageService.multiLanguage && !(languageService.defaultLanguageOnRoot && languageService.languageIsDefault(languageService.selectedLanguage))) {
        this.transitionTo('page', languageService.selectedLanguage);
      } else {
        return false;
      }
    },
    beforeModel: function beforeModel(transition) {
      var fingerprintService = this.get('fingerprintService');
      return Ember.RSVP.all([this.setupLanguage(), fingerprintService.handleBeforeTransition(transition)]);
    },
    model: function model() {
      var searchService = this.get('searchService');
      searchService.resetSearch();

      this.get('viewService').set('regionPage', null);

      if (this.get('firstVisit')) {
        this.set('firstVisit', false);
        if (!this.maybeSwitchToLanguageHomePage()) {
          return this.get('cmsService').getHomePage();
        }
      } else {
        return this.get('cmsService').getHomePage();
      }
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var headTags = this.getPageHeadTags(model);

      this.set('headTags', headTags);
    }
  });
});