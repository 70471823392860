define('webapp/routes/register', ['exports', 'webapp/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    i18n: Ember.inject.service(),
    myStorage: Ember.inject.service(),

    model: function model() {
      return this.get('cmsService').getPage('register');
    },
    getBreadcrumbs: function getBreadcrumbs() {
      return [{
        route: 'register',
        title: 'Registrierung'
      }];
    }
  });
});