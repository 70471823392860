define('webapp/services/cms-service', ['exports', 'webapp/utils/transform-widgets', 'webapp/utils/find-by'], function (exports, _transformWidgets, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function filterWidgetsByBlogPage(page, widgets) {
    if (page.type === 'blog') {
      return widgets.filter(function (w) {
        return !(w.widget.options && w.widget.options.noDisplayBlog);
      });
    } else if (page.type === 'blogPost') {
      return widgets.filter(function (w) {
        return !(w.widget.options && w.widget.options.noDisplayBlogPost);
      });
    } else if (page.type === 'blogCategory') {
      return widgets.filter(function (w) {
        return !(w.widget.options && w.widget.options.noDisplayBlogCategory);
      });
    } else {
      return widgets;
    }
  }

  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),
    widgets: Ember.computed.alias('activePage.widgets'),

    // cached versions
    menus: null,
    dynamicPageRoutesLanguage: {},
    itemTypeRoutes: {},

    getMenus: function getMenus() {
      var _this = this;

      var myStorage = this.get('myStorage');
      var cachedMenus = this.get('menus');
      if (cachedMenus) {
        return Ember.RSVP.resolve(cachedMenus);
      }
      return myStorage.menu.ajaxGet().then(function (menus) {
        _this.set('menus', menus);
        return menus;
      });
    },
    getHomePage: function getHomePage() {
      var _this2 = this;

      var cachedData = this.get('cachedData');
      var myStorage = this.get('myStorage');

      return myStorage.page.ajaxGet().then(function (page) {
        return cachedData.getSite().then(function (site) {
          page.widgets = _transformWidgets.default.createWidgetBuckets(_this2.filterTrackingWidgets(page.widgets), site.positions);
          _this2.set('activePage', page);
          return page;
        });
      });
    },
    filterTrackingWidgets: function filterTrackingWidgets(widgets) {
      var hasTracking = widgets.some(function (w) {
        return w.widget.options && w.widget.options.isTrackingActive;
      });
      if (hasTracking) {
        var fingerprintService = this.get('fingerprintService');
        var fingerprintData = fingerprintService.getProperties(['isKnown', 'isKnownTime', 'wasOnPage', 'wasOnPageTime']);
        return widgets.filter(function (w) {
          var options = w.widget.options || {};

          if (options.isTrackingActive) {
            var wasHere = fingerprintData.isKnown;
            var wasHereTime = fingerprintData.isKnownTime;
            if (options.pageRelevant) {
              wasHere = fingerprintData.wasOnPage;
              wasHereTime = fingerprintData.wasOnPageTime;
            }

            var trackingMatch = wasHere;
            var trackingInterval = parseFloat(options.trackingInterval);
            if (trackingMatch && trackingInterval) {
              trackingMatch = Date.now() - new Date(wasHereTime).getTime() < trackingInterval * 60 * 1000;
            }

            if (options.showForRecurringUsers) {
              return trackingMatch;
            } else {
              return !trackingMatch;
            }
          }

          return true;
        });
      } else {
        return widgets;
      }
    },
    getPage: function getPage(fullSlug) {
      var _this3 = this;

      var setActivePage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var cachedData = this.get('cachedData');
      var myStorage = this.get('myStorage');
      return myStorage.page.ajaxGet({ param: fullSlug }).then(function (page) {
        return cachedData.getSite().then(function (site) {
          page.widgets = _transformWidgets.default.createWidgetBuckets(_this3.filterTrackingWidgets(filterWidgetsByBlogPage(page, page.widgets)), site.positions);
          if (setActivePage) {
            _this3.set('activePage', page);
          }
          return page;
        });
      });
    },
    getFixedPage: function getFixedPage(type) {
      var cachedData = this.get('cachedData');
      return cachedData.getFlatPages().then(function (flatPages) {
        return (0, _findBy.default)(flatPages, 'type', type);
      });
    },


    // TODO merge to dynamicPageRoutes, own containerType for each entry type
    setupDetailPages: function setupDetailPages() {
      var _this4 = this;

      var cachedData = this.get('cachedData');
      var itemTypeRoutes = {};

      return cachedData.getFlatPages().then(function (flatPages) {
        flatPages.forEach(function (page) {
          if (page.type === 'entryContainer') {
            var itemType = page.options.itemType || 'entry';
            itemTypeRoutes[itemType] = { fullSlug: page.fullSlug, fullSlugs: page.fullSlugs };
          }
        });
        _this4.set('itemTypeRoutes', itemTypeRoutes);
      });
    },
    setupDynamicPages: function setupDynamicPages() {
      var _this5 = this;

      var dynamicPageTypes = ['search', 'entryContainer', 'categoryContainer', 'profileContainer'];
      return Ember.RSVP.map(dynamicPageTypes, function (type) {
        return _this5.getFixedPage(type).then(function (page) {
          if (page) {
            return { type: type, fullSlug: page ? page.fullSlug : '', fullSlugs: page.fullSlugs }; // TODO error handling if container page doesnt exist
          }
        });
      }).then(function (dynamicPages) {
        var dynamicPageRoutes = {};
        dynamicPages.filter(Boolean).forEach(function (_ref) {
          var type = _ref.type,
              fullSlug = _ref.fullSlug,
              fullSlugs = _ref.fullSlugs;

          // dynamicPageRoutes[type] = fullSlug;
          dynamicPageRoutes[type] = { fullSlug: fullSlug, fullSlugs: fullSlugs };
        });
        _this5.set('dynamicPageRoutesLanguage', dynamicPageRoutes);
      }).then(function () {
        _this5.setupDetailPages();
      });
    },


    dynamicPageRoutes: Ember.computed('languageService.selectedLanguage', 'dynamicPageRoutesLanguage', function () {
      var languageService = this.get('languageService');
      var dynamicPageRoutesLanguage = this.get('dynamicPageRoutesLanguage');
      var dynamicPageRoutes = {};

      Object.keys(dynamicPageRoutesLanguage).forEach(function (key) {
        dynamicPageRoutes[key] = languageService.translateSlug(dynamicPageRoutesLanguage[key]);
      });

      return dynamicPageRoutes;
    })
  });
});