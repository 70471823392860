define('webapp/router', ['exports', 'webapp/config/environment', 'webapp/mixins/transition-logger'], function (exports, _environment, _transitionLogger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_transitionLogger.default, {
    location: _environment.default.locationType,
    activeBodyClass: null,
    viewService: Ember.inject.service(),
    routeHistory: Ember.inject.service(),
    searchService: Ember.inject.service(),
    widgetInteractions: Ember.inject.service(),
    fingerprintService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    countService: Ember.inject.service(),

    _onDidTransition: Ember.on('didTransition', function () {
      this.get('routeHistory').pushState(this.currentRouteName, this._routerMicrolib.currentHandlerInfos);

      // this.get('inquestService').set('inquestActive', false);
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (!this.get('fastboot.isFastBoot')) {
        window.addEventListener('popstate', function () {
          _this.set('isBackButton', true);
        });
      }
    },


    _onWillTransition: Ember.on('willTransition', function () {
      var isFastBoot = this.get('fastboot.isFastBoot');
      var countService = this.get('countService');
      var searchService = this.get('searchService');
      searchService.onRouteChange();
      countService.refreshNotifications();
      if (!isFastBoot) {
        if (UIkit) {
          var offcanvasElements = ['#offcanvas', '#additional-offcanvas-right'];
          offcanvasElements.forEach(function (element) {
            var offC = window.UIkit.offcanvas(element);
            if (offC) {
              offC.hide();
            }
          });
          Array.prototype.forEach.call(document.querySelectorAll('[uk-dropdown].uk-open'), function (elem) {
            window.UIkit.dropdown(elem).hide();
          });
        }
      }
    }),

    _addBodyClass: Ember.on('didTransition', function () {
      var activeBodyClass = this.get('activeBodyClass');
      var bodyClass = this.get('viewService.currentModel.type');
      this.set('activeBodyClass', bodyClass);
      if (!this.get('fastboot.isFastBoot')) {
        var body = document.body;
        if (activeBodyClass) {
          body.classList.remove(activeBodyClass);
        }
        if (bodyClass === 'search' && this.get('viewService.showMap')) {
          body.classList.add('map');
        } else {
          body.classList.remove('map');
        }
        body.classList.add(bodyClass);
      }
    }),

    _afterTransition: Ember.on('didTransition', function () {
      var _this2 = this;

      this.get('widgetInteractions').resetVars();
      var viewService = this.get('viewService');
      Ember.run.schedule('afterRender', function () {
        viewService.incrementProperty('transitionCount');
        if (!viewService.get('skipScroll')) {
          if (!_this2.get('fastboot.isFastBoot')) {
            if (!_this2.get('isBackButton')) {
              window.scroll(0, 0);
            }
          }
        }
        viewService.set('skipScroll', false);
        viewService.set('hasScrolled', false);
        _this2.set('isBackButton', false);
      });
    })
  });

  Router.map(function () {
    this.route('index', { path: '/' });
    this.route('page', { path: '/*path' });
    this.route('register');
    this.route('request-password-reset');

    this.route('account', function () {
      this.route('profile', function () {
        this.route('activities');
        this.route('reviews');
      });
      this.route('component-press', function () {
        this.route('articles');
      });
      this.route('component-channels', function () {
        this.route('overview');
      });
      this.route('order', function () {
        this.route('new');
      });
      this.route('messages', function () {});
      this.route('events', function () {
        this.route('edit', { path: ':id' });
      });
      this.route('pois', function () {
        this.route('edit', { path: ':id' });
      });
      this.route('entries', function () {
        this.route('edit', { path: ':id' });
      });
      this.route('settings');
      this.route('subscriptions');
      this.route('favorites');
      this.route('joiner');
      this.route('shares');
      this.route('ratings');
      this.route('likes');
      this.route('media');
      this.route('company');
      this.route('newsletter', function () {
        this.route('list', { path: ':id' });
      });
      this.route('marketplace', function () {
        this.route('offer');
        this.route('request');
        this.route('search');
      });
      this.route('support');
    });
    this.route('login');
    this.route('logout');
    this.route('verify-email', { path: 'verify-email/:verificationToken' });
    this.route('password-reset', { path: 'password-reset/:verificationToken' });
  });

  exports.default = Router;
});