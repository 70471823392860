define('webapp/components/date-picker', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dateFormat: 'DD.MM.YYYY',
    internalDateFormat: null, // how to store the date, if null, store it as JS Date Object
    twoWay: true,

    internalDate: Ember.computed('date', function () {
      var date = this.get('date');
      if (!date) {
        return null;
      }

      var internalDateFormat = this.get('internalDateFormat');
      return internalDateFormat ? (0, _moment.default)(date, internalDateFormat) : date;
    }),

    setDate: function setDate(momentDate) {
      var internalDateFormat = this.get('internalDateFormat');

      var internalDate = internalDateFormat ? momentDate.format(internalDateFormat) : momentDate.toDate();

      this.sendAction('onDateChange', internalDate);
      if (this.get('twoWay')) {
        this.set('date', internalDate);
      }
    },


    actions: {
      manualDateChange: function manualDateChange(e) {
        var value = e.target.value;
        var dateFormat = this.get('dateFormat');
        var momentDate = (0, _moment.default)(value, dateFormat);
        this.setDate(momentDate);
      },
      dateChange: function dateChange(dateObj) {
        this.setDate((0, _moment.default)(dateObj));
      }
    }
  });
});