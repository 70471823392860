define('webapp/components/account/quick-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),

    selection: Ember.computed(function () {
      return [];
    }),
    prevInput: '',
    currentInput: '',
    suggestions: null,
    selectedIdx: -1,
    group: undefined,

    changeIndex: function changeIndex(no) {
      var selectedIdx = this.get('selectedIdx');
      var suggestions = this.get('suggestions');

      if (suggestions) {
        selectedIdx += no;
        if (selectedIdx < 0) {
          selectedIdx = 0;
        } else {
          if (selectedIdx >= suggestions.length) {
            selectedIdx = suggestions.length - 1;
          }
        }
      } else {
        selectedIdx = -1;
      }

      this.set('selectedIdx', selectedIdx);
    },
    keyDown: function keyDown(evt) {
      if (evt.keyCode === 38) {
        this.changeIndex(-1);
        evt.preventDefault();
      } else if (evt.keyCode === 40) {
        this.changeIndex(1);
        evt.preventDefault();
      } else if (evt.keyCode === 27) {
        // escape
        this.setProperties({
          suggestions: null,
          selectedIdx: -1,
          currentInput: '',
          prevInput: ''
        });
      }
    },


    inputLength: Ember.computed('currentInput.length', function () {
      return this.get('currentInput.length') + 1;
    }),

    actions: {
      inputChanged: function inputChanged() {
        var _this = this;

        var tagStorage = this.get('myStorage.tag');
        var currentInput = this.get('currentInput');
        var prevInput = this.get('prevInput');

        if (currentInput === prevInput) {
          return;
        }

        if (currentInput) {
          tagStorage.ajaxPost({
            param: 'searchregex',
            data: { name: currentInput, group: this.get('group') }
          }).then(function (suggestions) {
            suggestions = suggestions.map(function (x) {
              return x.name;
            });
            if (suggestions.length === 0) {
              suggestions = null;
            }

            _this.setProperties({
              suggestions: suggestions,
              selectedIdx: -1
            });
          });
        } else {
          this.setProperties({
            suggestions: null,
            selectedIdx: -1
          });
        }

        this.set('prevInput', currentInput);
      },
      inputEnter: function inputEnter() {
        var tagStorage = this.get('myStorage.tag');

        var _getProperties = this.getProperties(['currentInput', 'suggestions', 'selectedIdx', 'selection']),
            currentInput = _getProperties.currentInput,
            suggestions = _getProperties.suggestions,
            selectedIdx = _getProperties.selectedIdx,
            selection = _getProperties.selection;

        var newElem = void 0;
        if (selectedIdx >= 0) {
          newElem = suggestions[selectedIdx];
        } else if (currentInput && currentInput.length >= 3) {
          newElem = currentInput;
        }

        if (newElem && selection.indexOf(newElem) < 0) {
          newElem = newElem.toLowerCase();

          selection.pushObject(newElem);
          if (selectedIdx < 0) {
            tagStorage.ajaxPost({ data: { name: currentInput, group: this.get('group') } }).catch(function (err) {
              console.log('No rights to write to global Tags database');
            });
          }
        }

        this.setProperties({
          suggestions: null,
          currentInput: '',
          selectedIdx: -1
        });
      },
      removeItem: function removeItem(item) {
        var selection = this.get('selection');
        selection.removeObject(item);
      },
      selectSuggestion: function selectSuggestion(idx) {
        var suggestions = this.get('suggestions');
        var selection = this.get('selection');

        var suggestion = suggestions[idx];

        if (selection.indexOf(suggestion) < 0) {
          selection.pushObject(suggestion);
        }

        this.setProperties({
          selectedIdx: -1,
          currentInput: '',
          prevInput: '',
          suggestions: []
        });
      }
    }
  });
});