define('webapp/services/fingerprint-service', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    fingerprint: null,
    isKnown: false,
    wasOnPage: false,
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    router: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    _cachedFingerprint: '',

    postFingerprintAndLocation: function postFingerprintAndLocation(location /*, type*/) {
      var _this = this;

      if (_environment.default.fingerprint) {
        var myStorage = this.get('myStorage');
        var userProxy = this.get('userProxy');
        return this.getFingerprint().then(function (fingerprint) {
          console.log('fingerprint hash', fingerprint);
          _this.set('fingerprint', fingerprint);
          return myStorage.user.ajaxPost({
            param: 'fingerprint',
            data: {
              fingerprint: fingerprint,
              location: location,
              user: userProxy._id || null
              // type: type
            }
          }).then(function (res) {
            _this.setProperties({
              isKnown: res.isKnown,
              isKnownTime: res.isKnownTime,
              wasOnPage: res.wasOnPage,
              wasOnPageTime: res.wasOnPageTime
            });
            return res;
          });
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    getFingerprint: function getFingerprint() {
      if (this._cachedFingerprint) {
        return Ember.RSVP.resolve(this._cachedFingerprint);
      }
      return new Ember.RSVP.Promise(function (resolve) {
        Fingerprint2.get({
          userDefinedFonts: ['Nimbus Mono', 'Junicode', 'Presto']
        }, function (components) {
          var values = components.map(function (component) {
            return component.value;
          });
          var murmur = Fingerprint2.x64hash128(values.join(''), 31);
          resolve(murmur);
        });
      });
    },
    handleBeforeTransition: function handleBeforeTransition(transition) {
      if (this.get('fastboot.isFastBoot')) {
        return Ember.RSVP.resolve();
      } else {
        var targetURL = this._getTargetUrl(transition);
        return this.postFingerprintAndLocation(targetURL);
      }
    },


    // https://stackoverflow.com/questions/31140407/ember-get-target-url-of-transition
    _getTargetUrl: function _getTargetUrl(transition) {
      var router = this.get('router._router');
      var paramsCount = 0;
      var params = {};
      var args = [transition.targetName];
      // Iterate over route segments
      for (var key1 in transition.params) {
        if (transition.params.hasOwnProperty(key1)) {
          var segment = transition.params[key1];
          // Iterate over the params for the route segment.
          for (var key2 in segment) {
            if (segment.hasOwnProperty(key2)) {
              if (segment[key2] != null) {
                params[key2] = segment[key2];
                paramsCount++;
              }
            }
          }
        }
      }
      if (paramsCount > 0) {
        args.push(params);
      }
      var targetURL = router.generate.apply(router, args);
      return targetURL;
    }
  });
});