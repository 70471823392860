define('webapp/services/search-service', ['exports', 'webapp/utils/tree-utils', 'webapp/utils/filter-categories'], function (exports, _treeUtils, _filterCategories) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function parseWildcard(wildcard) {
    wildcard = wildcard || '';
    var segments = wildcard.split('/');
    var searchTerm = segments[segments.length - 1];
    var categorySlugs = segments.slice(0, -1);
    return { searchTerm: searchTerm, categorySlugs: categorySlugs };
  }

  exports.default = Ember.Service.extend(Ember.Evented, {
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    viewService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    router: Ember.inject.service(),

    filterExpanded: false,

    searchResult: null,
    searchSize: 50,
    currentSearchTerm: '', //updated only after search is triggered
    searchTerm: '',
    geoDistance: null,
    sort: null,
    itemType: null,
    enableMaxRange: false,
    selectedCategories: [],
    selectedTags: [],

    selectedEvent: null,
    aggs: {},
    globalCategoryCounts: {},
    globalTagCounts: {},

    exactSearch: false, //controlls fuzziness

    pagingIdx: 0,
    totalHits: 0,

    init: function init() {
      this._super.apply(this, arguments);
    },
    onRouteChange: function onRouteChange() {
      this.set('suggestions', null);
    },


    selectedCategorySlugs: Ember.computed('selectedCategories.@each.slug', function () {
      var selectedCategories = this.get('selectedCategories');
      return selectedCategories.map(function (cat) {
        return cat.slug;
      });
    }),

    isSearchRoute: Ember.computed('viewService.currentModel', function () {
      var currentModel = this.get('viewService.currentModel');
      return currentModel && currentModel.type === 'search';
    }),

    resetSearch: function resetSearch() {
      this.setProperties({
        searchTerm: '',
        selectedCategories: [],
        selectedTags: [],
        selectedEvent: null,
        itemType: null,
        sort: null,
        geoDistance: null,
        enableMaxRange: null
      });
    },
    setSearchTerm: function setSearchTerm(term) {
      this.set('searchTerm', term);
      this.trigger('searchTermChange', term);
    },
    newSearch: function newSearch() {
      this.setProperties({
        pagingIdx: 0
      });

      return this.updateSearch();
    },
    setPagingIdx: function setPagingIdx(pagingIdx) {
      var oldIdx = this.get('pagingIdx');
      if (oldIdx !== pagingIdx) {
        this.set('pagingIdx', pagingIdx);
      }
      this.updateSearch();
    },
    getLocation: function getLocation() {
      var location = this.get('userProxy.location');
      var selectedEvent = this.get('selectedEvent');
      if (selectedEvent) {
        location = selectedEvent.geoPoint;
      }
      return location;
    },
    fetchGlobalAggs: function fetchGlobalAggs() {
      var _this = this;

      if (!this.globalAggsPromise) {
        var myStorage = this.get('myStorage');
        this.globalAggsPromise = myStorage.search.ajaxPost({ data: { size: 0 } }).then(function (res) {
          _this.set('globalCategoryCounts', res.aggs && res.aggs.categoryIds);
          _this.set('globalTagCounts', res.aggs && res.aggs.tags);
        });
      }

      return this.globalAggsPromise;
    },
    getSelectedCategoryIds: function getSelectedCategoryIds() {
      var selectedCategories = this.get('selectedCategories');
      return selectedCategories.map(function (x) {
        return x._id;
      });
    },
    getCategoryFilter: function getCategoryFilter() {
      // TODO generalize this
      var rawCategories = this.get('rawCategories');
      var selectedCategories = this.get('selectedCategories').map(function (x) {
        return x._id;
      });

      var bioCats = (0, _treeUtils.flattenTree)((0, _filterCategories.default)(rawCategories, ['zertifikate'], true)).map(function (x) {
        return x._id;
      });
      var offerCats = (0, _treeUtils.flattenTree)((0, _filterCategories.default)(rawCategories, ['aussteller', 'veranstalter', 'gastgeber'], true)).map(function (x) {
        return x._id;
      });

      var andCats = [];
      var selectedBioCats = [];
      var selectedOfferCats = [];

      selectedCategories.forEach(function (cat) {
        if (bioCats.indexOf(cat) >= 0) {
          selectedBioCats.push(cat);
        } else if (offerCats.indexOf(cat) >= 0) {
          selectedOfferCats.push(cat);
        } else {
          andCats.push(cat);
        }
      });

      return [{
        type: 'and',
        items: andCats
      }, {
        type: 'or',
        items: selectedOfferCats
      }, {
        type: 'or',
        items: selectedBioCats
      }];
    },
    getTagFilter: function getTagFilter() {
      return this.get('selectedTags');
    },
    updateSearch: function updateSearch() {
      var _this2 = this;

      var searchTerm = this.get('searchTerm');
      this.set('currentSearchTerm', searchTerm);

      var from = this.get('searchSize') * this.get('pagingIdx');

      var searchRequest = {
        itemTypes: this.get('itemType'),
        searchTerm: searchTerm,
        size: this.get('searchSize'),
        from: from,
        exactSearch: this.get('exactSearch'),
        // categoryIds: this.getSelectedCategoryIds(),
        categoryFilter: this.getCategoryFilter(),
        tagFilter: this.getTagFilter(),
        location: this.getLocation(),
        sort: this.get('sort'),
        geoDistance: this.get('geoDistance'),
        enableMaxRange: this.get('enableMaxRange'),
        highlightFields: ['title']
      };

      if (searchRequest.sort === 'dreampoints') {
        searchRequest.sortOrder = 'desc';
      }

      return this.get('myStorage').search.ajaxPost({ data: searchRequest }).then(function (res) {
        if (res.hits) {
          res.hits.forEach(function (hit, idx) {
            hit.idx = idx + from + 1;
          });
        }

        _this2.setProperties({
          totalHits: res.totalHits,
          searchResult: res,
          aggs: res.aggs,
          suggestions: null
        });
        _this2.trigger('searchReceived');
        return res;
      });
    },
    searchAsYouType: function searchAsYouType(searchTerm) {
      var _this3 = this;

      if (searchTerm.length) {
        var highlightFields = ['title'];

        var searchRequest = {
          itemTypes: this.get('itemType'),
          searchTerm: searchTerm,
          size: 5,
          exactSearch: this.get('exactSearch'),
          highlightFields: highlightFields,
          aggregations: false,
          markers: false
        };

        var myStorage = this.get('myStorage');
        return myStorage.search.ajaxPost({ param: 'suggest', data: searchRequest }).then(function (suggestions) {
          _this3.set('suggestions', suggestions);
        });
      } else {
        this.set('suggestions', null);
      }
    },
    transitionSearch: function transitionSearch() {
      var routing = this.get('router');
      var searchTerm = this.get('searchTerm');
      var selectedCategories = this.get('selectedCategories');
      var wildcardParam = selectedCategories.map(function (x) {
        return x.slug;
      }).join('/');
      if (wildcardParam) {
        wildcardParam += '/';
      }
      if (searchTerm) {
        wildcardParam += searchTerm;
      }

      routing.transitionTo('page', this.getSearchPageRoute(wildcardParam));
    },
    transitionSearchToRegion: function transitionSearchToRegion() {
      var currentRegion = this.get('viewService.currentModel.region');
      var routing = this.get('router');
      var searchTerm = this.get('searchTerm');

      var regionSlug = void 0;

      if (currentRegion) {
        regionSlug = currentRegion.categories.map(function (x) {
          return x.slug;
        }).join('/');
      } else {
        // TODO legacy region pages
        regionSlug = this.get('regionSlug');
      }

      routing.transitionTo('page', this.getSearchPageRoute(regionSlug + '/' + searchTerm));
    },
    getSearchPageRoute: function getSearchPageRoute(addSeg) {
      var pageRoute = this.get('cmsService.dynamicPageRoutes.search');
      if (pageRoute && addSeg) {
        pageRoute += '/';
      }
      if (addSeg) {
        pageRoute += addSeg;
      }
      return pageRoute;
    },


    maximumReached: Ember.computed('totalHits', 'searchSize', 'pagingIdx', function () {
      var props = this.getProperties('totalHits', 'searchSize', 'pagingIdx');
      return props.totalHits / props.searchSize > 1000 && props.pagingIdx === 999;
    }),

    setupSearchCategories: function setupSearchCategories(categories, selectedCategorySlugs) {
      var catMap = this._catMap;
      if (!catMap) {
        catMap = {};
        (0, _treeUtils.walkTree)(categories, function (cat) {
          catMap[cat.slug] = cat;
        });
        this._catMap = catMap;
      }

      var fullSelectedCategories = selectedCategorySlugs.map(function (slug) {
        return catMap[slug];
      }).filter(function (x) {
        return !!x;
      });

      this.set('selectedCategories', fullSelectedCategories);
    },
    fetchRawCategories: function fetchRawCategories() {
      var _this4 = this;

      return this.get('cachedData').getCategories().then(function (res) {
        _this4.set('rawCategories', res.slice());
      });
    },
    setupPage: function setupPage(pageRoute, page, params) {
      var _this5 = this;

      var userProxy = this.get('userProxy');
      var searchSegment = page.restSlug;

      this.set('viewService.showSearchbar', true);

      var _parseWildcard = parseWildcard(searchSegment),
          searchTerm = _parseWildcard.searchTerm,
          categorySlugs = _parseWildcard.categorySlugs;

      this.setSearchTerm(searchTerm);
      var categories = void 0;
      var firstPromise = void 0;
      if (categorySlugs.length) {
        firstPromise = this.get('cachedData').getCategories().then(function (res) {
          categories = res.slice();
          _this5.setupSearchCategories(categories, categorySlugs);
        });
      } else {
        this.set('selectedCategories', []);
        firstPromise = Ember.RSVP.resolve();
      }

      return firstPromise.then(function () {
        return Ember.RSVP.all([userProxy.fetchData(), _this5.fetchGlobalAggs(), _this5.fetchRawCategories()]);
      }).then(function () {
        return _this5.newSearch().then(function () {
          _this5.set('isSubSearch', searchTerm || categorySlugs.length);
        });
      });
    }
  });
});