define('webapp/services/category-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getCatSlugs(flatCategories, targetCategory) {
    var cat = flatCategories.find(function (x) {
      return x._id === targetCategory._id;
    });
    var catHierarchy = [];

    while (cat.parent) {
      catHierarchy.push(cat);
      cat = flatCategories.find(function (x) {
        return x._id === cat.parent;
      });
    }

    return catHierarchy.reverse().slice(1).map(function (x) {
      return x.slug;
    });
  }

  exports.default = Ember.Service.extend({
    myStorage: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    cachedData: Ember.inject.service(),

    items: [],

    // TODO paging
    fetchItems: function fetchItems(activeCategory) {
      var _this = this;

      var myStorage = this.get('myStorage');

      return myStorage.search.ajaxPost({
        data: {
          itemTypes: ['entry'],
          categoryIds: [activeCategory._id],
          size: 2000
        }
      }).then(function (searchResult) {
        _this.set('items', searchResult.hits);
      });
    },
    setupPage: function setupPage(pageRoute, page, params) {
      var _this2 = this;

      var activeCategory = page.category;
      this.set('activeCategory', page.category);

      return this.get('cachedData').getFlatCategories().then(function (flatCategories) {
        _this2.set('flatCategories', flatCategories); // needed for breadcrumbs
        return _this2.fetchItems(activeCategory);
      });
    },
    getBreadcrumbs: function getBreadcrumbs(breadCrumbs) {
      var flatCategories = this.get('flatCategories');
      var activeCategory = this.get('activeCategory');
      var baseRoute = this.get('cmsService.dynamicPageRoutes.categoryContainer');

      var catSlugs = getCatSlugs(flatCategories, activeCategory);

      var addSluges = catSlugs.map(function (x, idx) {
        return {
          route: 'page',
          title: x,
          routeParams: baseRoute + '/' + catSlugs.slice(0, idx + 1).join('/')
        };
      });

      return breadCrumbs.concat(addSluges);
    }
  });
});