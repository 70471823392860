define('webapp/controllers/account/entries/edit', ['exports', 'webapp/utils/media'], function (exports, _media) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var progressConfig = [{ field: 'title', weight: 15 }, { field: 'metaDescription', weight: 10 }, { field: 'subtitle', weight: 20 }, { field: 'mainImage', weight: 10 }, { field: 'listImage', weight: 5 }, { field: 'address.city', weight: 10 }, { field: 'address.street', weight: 10 }, { field: 'address.zip', weight: 5 }, { field: 'address.houseNumber', weight: 5 }, { field: 'primaryCategory', weight: 10 }];

  exports.default = Ember.Controller.extend({
    userProxy: Ember.inject.service(),
    accountRoutes: Ember.computed.alias('userProxy.accountRoutes'),
    widgetInteractions: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    i18n: Ember.inject.service(),
    message: Ember.inject.service(),
    showProgressFields: false,

    highlight: [],
    progress: Ember.computed.apply(undefined, _toConsumableArray(progressConfig.map(function (obj) {
      return 'model.' + obj.field;
    })).concat([function () {
      var model = this.get('model');
      var value = 0;

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = progressConfig[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var elem = _step.value;

          var field = elem.field;
          var fieldValue = Ember.get(model, field);
          if (fieldValue) {
            value += elem.weight;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return value;
    }])),

    // called from account-banner via widget-interactions
    removeMainImage: function removeMainImage() {
      this.set('model.mainImage', null);
      this.set('model.listImage', null);
    },


    // called from account-banner via widget-interactions
    editMainImage: function editMainImage(image) {
      var _this = this;

      var myStorage = this.get('myStorage');
      myStorage.mediaAdmin.ajaxGet({ param: image.mediaId }).then(function (fullImage) {
        _this.set('showEditMainImageModal', true);
        _this.set('editedMainImage', fullImage);
      });
    },


    actions: {
      finishEditMainImage: function finishEditMainImage() {
        this.set('showEditMainImageModal', false);
        this.set('editedMainImage', null);
        this.get('widgetInteractions').incCounter();
      },
      changeMainProgress: function changeMainProgress(item) {
        var model = this.get('model');
        var mainProgress = parseInt(model.dreamProgress);
        if (mainProgress < item) {
          this.set('model.dreamProgress', item);
        }
      },
      confirmMainImage: function confirmMainImage(fullImage) {
        var image = (0, _media.mediumToNested)(fullImage);

        this.set('model.mainImage', image);
        this.set('model.listImage', image);
        this.set('widgetInteractions.changeCounter', this.get('widgetInteractions.changeCounter') + 1);

        var images = this.get('model.images');

        // add image to existing image array if not yet exists
        var imageIds = images.map(function (x) {
          return x.mediaId;
        });
        if (imageIds.indexOf(image.mediaId) < 0) {
          this.set('model.images', images.concat(image));
        }

        this.set('widgetInteractions.shownUserModal', null);
      },


      activateHighlight: function activateHighlight() {
        var model = this.get('model');
        var i18n = this.get('i18n');
        var message = this.get('message');
        var messageString = '';
        var highlightFields = [];
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = progressConfig[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var elem = _step2.value;

            var field = elem.field;
            var fieldValue = Ember.get(model, field);
            if (!fieldValue) {
              highlightFields.push(field);
              messageString = messageString + i18n.t('entries.entry.fields.' + field).toString() + '\n';
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        if (highlightFields.length) {
          message.info(i18n.t('entries.entry.fields.prefixMissing').toString() + messageString);
          if (highlightFields[0].indexOf('address.city') || highlightFields[0].indexOf('address.street') || highlightFields[0].indexOf('address.zip') || highlightFields[0].indexOf('address.houseNumber')) {
            if (!this.get('fastboot.isFastBoot')) {
              document.getElementById('address-block').scrollIntoView(false);
            }
          }
        }
        this.set('highlight', highlightFields);
      },

      transferCustomerContact: function transferCustomerContact() {
        var customerContact = this.get('customer.contact');
        if (customerContact) {
          var entryContact = this.get('model.contact');
          Object.keys(customerContact).forEach(function (key) {
            var value = customerContact[key];
            if (value) {
              Ember.set(entryContact, key, value);
            }
          });
        }
      }
    }
  });
});