define('webapp/components/map/base-map', ['exports', 'webapp/utils/inject-script', 'webapp/config/environment'], function (exports, _injectScript, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global mapboxgl */

  function easing(t) {
    return t;
  }

  exports.default = Ember.Component.extend(Ember.Evented, {
    showControls: true,
    defaultZoom: 0,
    fastboot: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _injectScript.default)('assets/mapbox-gl.js').then(function (res) {
        _this.setupMap();
      }).catch(function (err) {
        console.log(err);
      });
    },
    setupMap: function setupMap() {
      var _this2 = this;

      if (this.isDestroyed) {
        return;
      }
      var center = this.get('center') || [12, 49];
      var minZoom = this.get('minZoom') || 5;
      var maxZoom = this.get('maxZoom') || 20;

      var mapElement = this.element.getElementsByClassName('map-element');
      mapElement = mapElement.length > 0 ? mapElement[0] : this.element;

      // TODO move accessToken and styleUrl to config or admin-interface
      mapboxgl.accessToken = _environment.default.mapboxAccessToken;
      var map = new mapboxgl.Map({
        container: mapElement,
        center: center,
        // style: 'https://map.hallonachbarn.com/styles/nachbarn.json',
        style: _environment.default.mapboxStyle,
        minZoom: minZoom,
        maxZoom: maxZoom,
        zoom: this.get('defaultZoom'),
        attributionControl: false
      });

      if (this.get('showControls')) {
        var nav = new mapboxgl.NavigationControl();
        map.addControl(nav, 'top-left');
      }

      map.once('load', function () {
        Ember.run.schedule('afterRender', _this2, function () {
          _this2.map = map;
          if (_this2.isDestroyed) {
            _this2.destroyMap();
          } else {
            _this2.trigger('mapLoaded');
            // only for debugging
            map.on('click', function (evt) {
              return _this2.logGeoPoint(evt);
            });
          }
        });
      });
    },
    logGeoPoint: function logGeoPoint(evt) {
      console.log('mapClick');
      console.log({ lat: evt.lngLat.lat, lon: evt.lngLat.lng });
    },
    destroyMap: function destroyMap() {
      var map = this.map;
      if (!map) {
        return;
      }

      this.map = undefined;
      if (map.loaded()) {
        map.remove();
      } else {
        map.once('load', function () {
          map.remove();
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.map) {
        this.destroyMap();
      }
      this._super.apply(this, arguments);
    },
    getIcon: function getIcon() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var markerClass = options.class || 'my-marker';
      if (!this.get('fastboot.isfastBoot')) {
        var div = document.createElement('div');
        if (options && options.idx) {
          div.innerHTML = "<div class='marker-number'>" + options.idx + '</div>';
        }
        div.classList.add(markerClass);
        return div;
      }
    },
    clearPopups: function clearPopups() {
      if (this._popupLayer) {
        this._popupLayer.remove();
      }
    },
    createPopup: function createPopup(model, options) {
      var _this3 = this;

      options = options || {};
      var mapPopup = this.get('mapPopup');
      mapPopup.set('model', model);
      setTimeout(function () {
        _this3.clearPopups();
        var map = _this3.get('map');

        var lnglat = [model.geoPoint.lon, model.geoPoint.lat];
        var popupOptions = { anchor: options.anchor };
        var popup = new mapboxgl.Popup(popupOptions).setLngLat(lnglat).setDOMContent(mapPopup.element).addTo(map);

        if (options.transForm) {
          var tr = map.transform;
          var point = tr.project(new mapboxgl.LngLat(lnglat[0], lnglat[1]));
          point.y -= options.transForm[0];
          point.x -= options.transForm[1];
          lnglat = tr.unproject(point);
        }

        var panOptions = { easing: easing };

        if (options.duration === undefined) {
          panOptions.duration = 500;
        } else {
          panOptions.duration = options.duration;
        }

        map.panTo(lnglat, panOptions);
        _this3._popupLayer = popup;
        return popup;
      }, 1);
    }
  });
});