define('webapp/services/template-settings', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    cmsService: Ember.inject.service(),
    router: Ember.inject.service(),

    // TODO move to viewservice
    currentPageType: Ember.computed.alias('cmsService.activePage.type'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties(_environment.default.templateSettings);
    },


    rootRoute: Ember.computed('router.currentRouteName', function () {
      var currentRouteName = this.get('router.currentRouteName');
      return currentRouteName.split('.')[0];
    }),

    getClass: function getClass(classKey) {
      var rootRoute = this.get('rootRoute');
      var currentPageType = this.get('currentPageType');

      var containerClass = void 0;

      if (currentPageType === 'home') {
        containerClass = this.get('pageType.index.' + classKey);
      } else if (rootRoute === 'page') {
        containerClass = this.get('pageType.' + currentPageType + '.' + classKey);
      } else {
        containerClass = this.get('pageType.' + rootRoute + '.' + classKey);
      }

      return containerClass;
    },


    currentContainerClass: Ember.computed('rootRoute', 'currentPageType', function () {
      return this.getClass('mainContainerClass');
    }),

    currentBlockClass: Ember.computed('rootRoute', 'currentPageType', function () {
      return this.getClass('mainBlockClass');
    })
  });
});