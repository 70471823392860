define('webapp/components/menu-component', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    languageService: Ember.inject.service(),
    attributeBindings: ['role', 'aria-label'],

    translatedPages: Ember.computed('pages', 'languageService.selectedLanguage', function () {
      var languageService = this.get('languageService');
      var selectedLanguage = languageService.selectedLanguage;
      var pages = this.get('pages');

      return (0, _treeUtils.buildTree)(pages.map(function (page) {
        Ember.set(page.page, 'translatedMenuTitle', languageService.getTranslatedMenuTitle(page.page));
        return Ember.assign({}, page, { children: [] });
      }), function (x) {
        return Ember.get(x, 'page._id');
      }, function (x) {
        return Ember.get(x, 'parentPage');
      });
    })
  });
});