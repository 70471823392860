define('webapp/components/search/main-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: 'uk-grid',
    'uk-grid': true,
    classNames: ['uk-grid-small'],
    tagsOpen: false,
    userProxy: Ember.inject.service(),
    searchService: Ember.inject.service(),
    viewService: Ember.inject.service(),

    actions: {
      setSort: function setSort(value) {
        var searchService = this.get('searchService');
        searchService.set('sort', value);
        searchService.updateSearch();
      },
      setGeoDistance: function setGeoDistance(value) {
        var searchService = this.get('searchService');
        searchService.set('geoDistance', value);
        searchService.updateSearch();
      },
      toggleItemType: function toggleItemType(itemType) {
        var searchService = this.get('searchService');

        var oldType = searchService.get('itemType');

        if (oldType === itemType) {
          searchService.set('itemType', null);
        } else {
          searchService.set('itemType', itemType);
        }

        searchService.updateSearch();
      },
      setEvent: function setEvent(event) {
        var searchService = this.get('searchService');
        var oldEvenet = searchService.get('selectedEvent');

        if (event !== oldEvenet) {
          searchService.set('selectedEvent', event);
          searchService.updateSearch();
        }
      },
      toggleMaxRange: function toggleMaxRange() {
        var searchService = this.get('searchService');
        searchService.toggleProperty('enableMaxRange');
        searchService.updateSearch();
      },
      toogleTags: function toogleTags() {
        this.toggleProperty('tagsOpen');
        console.log('tagsOpen');
      },
      categoriesChanged: function categoriesChanged(cats, groupName) {
        var searchService = this.get('searchService');

        var hasAussteller = cats.some(function (x) {
          return x.slug === 'aussteller';
        });

        if (!hasAussteller) {
          searchService.set('enableMaxRange', false);
        }

        searchService.set('selectedCategories', cats);

        searchService.transitionSearch();
      },
      setSelectedTags: function setSelectedTags(tags) {
        var searchService = this.get('searchService');

        searchService.set('selectedTags', tags);
        searchService.updateSearch();
      },
      clearSearchTerm: function clearSearchTerm() {
        var searchService = this.get('searchService');
        searchService.set('searchTerm', '');
        searchService.transitionSearch();
      }
    }
  });
});