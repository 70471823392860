define("webapp/locales/en/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "application": {
      "searchAllRegions": "in all areas",
      "searchAllGroups": "in all ",
      "breadcrumbsHome": "Home",
      "noCosts": "free of charge",
      "month": "per month",
      "beta": {
        "infotext": "This function is not implemented in the Beta-Testingversion"
      },
      "ui": {
        "action": "action",
        "at": "at",
        "isActive": "active",
        "isNotActive": "disabled",
        "editDescription": "edit description",
        "dreampoints": "Entrypoints",
        "dreampointsAllDreams": "All your dream's dreampoints",
        "dreampointsDonate": "donate amount",
        "dreampointsTooltip": "You can collect dreampoints with your dreams",
        "totalDreampoints": "Entrypoints of all your dreams",
        "edit": "edit",
        "editProfile": "Edit profile",
        "editEvent": "Edit event",
        "editPoi": "Edit place",
        "editProduct": "edit product",
        "editEntry": "edit",
        "deleteEntry": "delete",
        "for": "for",
        "gavePoints": "gave you {{points}} points.",
        "goToEntry": "Go to this dream",
        "goToEvent": "Go to this event",
        "goToPoi": "Go to this place",
        "goToProduct": "Go to this product",
        "noEntry": "No dream created yet. Start your first dream now!",
        "noEntryProfile": "has no dream so far.",
        "noEvent": "No event created yet. Start your first event now!",
        "noPoi": "No place created yet. Create places to be better found by nearby friends.",
        "noProduct": "No product created yet. Start your first product now!",
        "nothingFound": "We could not find any results for this search.",
        "trySearch": "Would You try another search, like",
        "newEntry": "Create a new dream",
        "newEvent": "Create a new event",
        "newPoi": "Create a new place",
        "newProduct": "Create a new product",
        "addImage": "Add image",
        "addImages": "Add image",
        "editImage": "Edit image",
        "gallery": "Images",
        "memberSince": "Member since ",
        "notEditable": "cannot be edited",
        "routingDescription": "Directions",
        "editShownProfile": "Change profile",
        "advancedEdit": "Advanced",
        "saveShownProfile": "Save profile changes",
        "stockPhotoLinks": "Links to stock photo libraries",
        "total": "Total",
        "visibleForAll": "visible for all",
        "visibleForJoiner": "visible for joiner",
        "visibleForFollower": "visible for follower",
        "visibleForSupporter": "visible for supporter",
        "visibleForNone": "visible for nobody",
        "readMore": "read more",
        "userName": "Username ",
        "years": "years old",
        "yearsFew": "baby age",
        "fullView": "full view",
        "sort": "Sort by",
        "relevance": "Relevance",
        "distance": "Distance",
        "distant": "away",
        "here": "Nearby",
        "offer": "Skills",
        "region": "Region",
        "certificate": "Certificates",
        "type": "Type",
        "only": "Only",
        "all": "All",
        "hasBeen": "has been",
        "updated": "updated",
        "access": "",
        "alphabetical": "Alphabetical",
        "mobileRotate": "rotate for full screen image",
        "more": "more",
        "showAllEntries": "Show all entrys",
        "entriesFocus": "Selected entrys:",
        "entriesLatest": "Recent entrys:",
        "invalidEmbed": "Was not able to embed URL.",
        "unsavedChanges": "Unsaved changes",
        "discardUnsavedChanges": "Discard changes and continue",
        "discardUnsavedChangesMobile": "Discard",
        "saveUnsavedChanges": "Save"
      },
      "widgets": {
        "inFocus": "Spotlight",
        "newestEntries": "Newest dreams",
        "highestRatedEntries": "Most dreampoints"
      },
      "newsletter": {
        "alreadySubscribed": "You are already subscribed to {{list}}. You can access the newsletters in your account.",
        "alreadySubscribedNoList": "You already have an active subscription for the newsletter",
        "goToList": "access mailing list",
        "noAccess": "You have not the right permissions to subscribe to this newsletter.",
        "subscribe": "Subscribe",
        "subscribeWithAccount": "With your current signed in account.",
        "subscribeList": "You subscribe newsletters from: ",
        "subscribeThanks": "Thank you for your subscription. A validation e-mail has been sent for a second opt-in."
      }
    },
    "opt-in": {
      "success-member-verified": "{{email}} was added to {{listname}}.",
      "error-can-not-update-member": "Changes to the member data in {{email}} could not be processed.",
      "error-list-does-not-need-validation": "This list has no opt-in.",
      "error-list-does-not-exist": "No list found.",
      "error-member-not-in-list": "No member for this list found."
    },
    "opt-out": {
      "success-membership-terminated": "{{email}} was successfully removed from {{listname}}.",
      "error-can-not-update-member": "Changes to the member data in {{email}} could not be processed.",
      "error-list-does-not-have-opt-out": "This list has no opt-out.",
      "error-list-does-not-exist": "No list found.",
      "error-member-not-in-list": "No member for this list found."
    },
    "comments": {
      "reply": "RE: ",
      "heading": "Comments",
      "title": "Comments",
      "noComments": "This posts has no comments yet",
      "blockComments": "Commenting on this post has ended.",
      "message": {
        "afterSaveSuccess": "Your comment has been saved.",
        "afterSaveUnlock": "Your comment has been saved and is awaiting admin approval.",
        "afterSaveError": "Comment could not be saved!"
      },
      "comment": {
        "title": "Comment title",
        "userName": "Your username",
        "content": "Your comment",
        "cancel": "Cancel",
        "submit": "Submit",
        "button": "Add new comment",
        "buttonReply": "Reply",
        "login": "Login to submit comments",
        "past": "has commented",
        "replyFrom": "reply to",
        "hasCommented": "",
        "showReply": "show reply",
        "showReplies": "show replies",
        "anonymous": "Anonymous"
      },
      "hierarchy": {
        "title": "Comment view:",
        "flat": "flat",
        "nested": "nested"
      }
    },
    "blog": {
      "ui": {
        "category": "Category",
        "categoryHeading": "Posts from",
        "writtenBy": "written by ",
        "written": "written",
        "publishedIn": "published in",
        "lastUpdated": "Last updated ",
        "subCategories": "subcategories",
        "openSinglePost": "Go to article"
      }
    },
    "register": {
      "ui": {
        "companyData": "Company Information",
        "companyDataMandatory": "You must supply for company name",
        "typeMinimum": "You have to choose one type minimum",
        "personalData": "Personal information",
        "loginData": "Login information",
        "addressData": "Address",
        "addressInfo": "No need to supply your address, but you can be found more easily if so.",
        "usernameInfo": "Important: this is your login and profile name",
        "emptyFirstName": "Please supply your first name",
        "emptyLastName": "Please supply your last name",
        "emptyMail": "Please supply a valid e-mail address",
        "emptyUserName": "The username is mandatory",
        "takenUserName": "This username has already been taken",
        "takenEmail": "This e-mail is already registered. Please supply a different e-mail.",
        "password": "Password",
        "passwordRepeat": "Repeat password",
        "language": "Language",
        "timezone": "Timezone"
      }
    },
    "social": {
      "dreamline": {
        "anonymous": "Anonymous",
        "allActivities": "All activities",
        "locked": "This Content is only visible for Joiner",
        "sendComment": "send comment",
        "addMedia": "Add image/video",
        "nowJoiner": "is now supporter",
        "nowFollower": "follws this dream",
        "comments": "writes:",
        "writeComment": "Write comment...",
        "commentButton": "Comment",
        "nowLikes": "likes this dream.",
        "nowSharing": "has shared this dream.",
        "noMessages": "This dream has no activity yet. Be the first to comment this dream",
        "noMessagesFiltered": "No activities in the dreamline with this filter settings",
        "fewDreampoints": "has only few dreampoints. Comment, like or share this dream.",
        "dreampointsInfo": "This are the dream's dreampoints. More information about dreampoints in your profile.",
        "isJoiner": "",
        "isComment": " ago",
        "isLike": "",
        "isShare": " shared",
        "isFavorite": "",
        "isUpdate": " updated",
        "isStep": " has made a new step",
        "lastEntry": "Last dreamline entry",
        "addImageToDream": "Add this image to your dream",
        "onlyJoinerVisible": "Only visible for joiner or supporter",
        "prefixLike": "likes this dream since ",
        "prefixShare": "shared this dream at ",
        "prefixJoiner": "supports this dream since ",
        "prefixComment": "commented this dream  ",
        "prefixFavorite": "follows this dream since ",
        "prefixUpdate": "has updated this dream at ",
        "prefixStep": "has made a new step ",
        "provideImage": "the dream owner can use this image",
        "profile": "Profile",
        "depublished": "This comment has been removed.",
        "edited": {},
        "sharePart": "share",
        "toggleStream": "comments",
        "toggleStreamSingle": "comment",
        "resetFilter": "Clear filter",
        "filterBySteps": "Filter by steps",
        "filteredBySteps": "Filtered by steps",
        "filteredBy": "Activities filtered by",
        "filterComment": "comments",
        "filterShare": "shares",
        "filterFavorite": "follows",
        "filterLike": "likes",
        "filterStep": "steps",
        "flagged": "This reply has been reported",
        "isCopycatJoiner": "",
        "isFirstUpdate": " created",
        "prefixCopycatJoiner": "wants to entry this entry too since"
      },
      "report": {
        "title": "report",
        "send": "send report",
        "headlinePrefix": "You are about to report ",
        "headlineSuffix": "",
        "personSuffix": "s",
        "login": "To report an entry, you have to be logged in.",
        "reportSex": "Sexual content",
        "reportSpam": "Spam",
        "reportScam": "Scam",
        "reportViolence": "Voilence",
        "reportOther": "Other offensive content"
      },
      "dream": {
        "edit": "Edit dream",
        "settings": "Entry settings",
        "similar": "Similar dreams",
        "joinerApproved": "You participate in this dream",
        "joinerPending": "The dream owner must approve your join request",
        "joinerButton": "Join this dream",
        "joinerButtonMobile": "Join",
        "joinerSupport": "You support this dream",
        "joinerSupportPending": "The dream owner must approve your dream request",
        "joinerSupportButton": "Support this dream",
        "joinerSupportButtonMobile": "Support",
        "joinerDream": "Group dream",
        "joinerInfo": "This dream will turn into a group dream, when the dream owner approves your request.",
        "member": "Member",
        "members": "Members",
        "shareTitle": "Share the dream ",
        "noFollow": "You follow no dream",
        "noLike": "You haven't liked a dream yet!",
        "noSupport": "You don't support any dreams",
        "followedDreams": "Entries you follow",
        "noJoiner": "Your not a member of a Group-Entry",
        "meToo": "I want to dream this, too",
        "joinerSupportInfo": "We always implement new features like cash or entrypoints support. Stay tuned.",
        "joinerMessage": "Your message for the entry owner",
        "alreadyMeToo": "You are already entrying this entry",
        "done": "DONE",
        "registerFirstTitle": "Join or Support?",
        "registerFirstText": "In order to join or support a entry You have to be logged in. Register now. It is fast, easy and free. Just click the \"Sign-In\" Button."
      },
      "like": "Like",
      "share": "Share",
      "join": "Join",
      "comment": "Comment",
      "follow": "Follow",
      "Like": "Like",
      "Share": "Share",
      "Join": "Join",
      "Comment": "Comment",
      "Follow": "Follow",
      "Favorite": "Follow",
      "hasLiked": "You like this dream already."
    },
    "password": {
      "errors": {
        "password-and-conf-do-not-match": "Both passwords must match.",
        "not-secure-enough": "We recommend a password with a minimum length of 8–10 characters. Please supply additional characters like numbers or special characters."
      },
      "quality": {
        "0": "Very bad password quality, easy to hack",
        "1": "Bad password quality.",
        "2": "Not so good password, but OK.",
        "3": "Good password",
        "4": "Very well choosen password."
      }
    },
    "account": {
      "ui": {
        "userNameNotEditableTooltip": "Your username. Your username cannot be changed afterwards. Select carefully.",
        "registeredAs": "You are registered as",
        "register": "Sign up"
      }
    },
    "user": {
      "anonymous-user-name": "anonymous",
      "ui": {
        "accountToolbarLoginTooltip": "Sign up for free and create your dream",
        "accountToolbarLogin": "Sign in",
        "accountToolbarLogout": "Logout",
        "accountMenuProfile": "Profile",
        "accountProgressbar": "Your profile is {{value}}% complete.",
        "accountProgressbarEntry": "Your dream is {{value}}% complete.",
        "accountMenuSettings": "Settings",
        "accountMenuCompanySettings": "Company Info",
        "accountSettings": "Advanced Settings",
        "accountMenuSkills": "My Skills and Offers",
        "accountMenuSkillsOffer": "I need...",
        "accountMenuSkillsRequest": "I offer...",
        "accountMenuFavorites": "Favorites",
        "accountMenuSupports": "Entries I support",
        "accountMenuFollows": "Follows",
        "accountMenuImages": "My Images/Videos",
        "accountMenuSubscriptions": "My Subscriptions",
        "accountMenuReviews": "Reviews",
        "accountMenuActivities": "Entrypoints",
        "accountMenuSupport": "Supported Entries",
        "accountMenuJoin": "Joined Entries",
        "accountMenuLikes": "Entries I like",
        "accountMenuMessages": "Messages",
        "accountNoMessages": "You have received no messages yet.",
        "accountMenuEntries": "My Entries",
        "accountMenuEvents": "My Events",
        "accountMenuPoi": "My Places",
        "accountMenuProducts": "My Products",
        "accountMenuNewsletter": "My Newsletters",
        "accountMenuRegistrationData": "Registration information",
        "accountMenuPress": "My Articles",
        "accountGreeting": "Hello, ",
        "changeUserGroup": "Upgrade your user group",
        "backToLogin": "Back to login",
        "companyName": "Company name",
        "commercialTitle": "Company info for commercial dream",
        "companyNameTooltip": "Important: your dream is published under the company name.",
        "createNewAccount": "New account",
        "deleteImage": "Delete image",
        "fillOutForm": "All mandatory fields must be filled out to register",
        "firstName": "First name",
        "lastName": "Last name",
        "description": "Describe yourself",
        "email": "E-mail address",
        "userName": "Username",
        "zip": "ZIP",
        "city": "City",
        "street": "Street",
        "housenumber": "Number",
        "country": "Country",
        "birthday": "Birthday",
        "gender": "M/F",
        "genderMale": "male",
        "genderFemale": "female",
        "iAmCustomer": "I want to create a share your dream business account",
        "loginTitle": "Sign in",
        "username": "Username (or e-mail)",
        "password": "Password",
        "oldPassword": "Old password",
        "changePassword": "I want to change my password",
        "selectCustomerType": "You are ... please select",
        "stayLoggedin": "Stay logged in",
        "stayLoggedinInfo": "Deactivate this option if you sign in at a publicly available device.",
        "cannotLogin": "Login problems? Click here to reset Your password.",
        "loginButton": "Sign in",
        "registerTitle": "New here? Welcome!",
        "registerMessage": "Sign up now and you can start immediately.",
        "registerButton": "Set up your account now",
        "requestPasswordReset": "Request new password and delete the old one.",
        "uploadToMyStorage": "Upload new images to my image directory",
        "myStorage": "My directory",
        "uploadUserImage": "Choose user image",
        "uploadSelectFile": "select a file.",
        "uploadDrop": "Drag a file here to upload or ",
        "memberSince": "Member since ",
        "lastLogin": "Last login ",
        "myAddedStorage": "Usable images from other users",
        "dataUsage": "The file is used in ",
        "dataUsageNone": "This file is not in use, yet. Do you want to delete this file?",
        "dataDelete": " other entries. Do you want to delete the file permanently? All file occurencies were replaced by a placeholder.",
        "resetPasswordPage": "Here, you can reset your password",
        "resetPassword": "Reset password",
        "resetPasswordSuccess": "Your password was reset, you can now login"
      },
      "company": {
        "noCustomerYet": "Your registration has no business account yet",
        "noCustomerValidation": "Your business account is awaiting validation.",
        "newCustomerAccount": "Create a business-account",
        "email": "E-mail for customer contacts",
        "copyContact": "Use contact information from company profile",
        "companyName": "Company name",
        "phone": "Contact phone number",
        "fax": "Contact fax number",
        "website": "Company's website",
        "facebook": "Company's facebook account (account name only)",
        "twitter": "Company's twitter account (account name only)",
        "google": "Company's google+ account (account name only)",
        "instagram": "Company's instagram account (account name only)",
        "pinterest": "Company's pinterest account (account name only)",
        "remarks": "Remarks"
      },
      "message": {
        "after-save-success": "Changes have been saved.",
        "after-save-error": "Changes could not be saved.",
        "can-not-logout": "We could not sign you out!",
        "can-not-login": "We cannot sign you in!",
        "registration-success": "You should receive a confirmation mail.",
        "registration-error": "An error occured during registration. Please try again.",
        "conflict": "This username is already taken. Please try another one.",
        "verify-success": "Your email is now verified. You can now log in. ",
        "verify-error": "Your email could not be verified. Please contact the administrator.",
        "fileExistsError": "Upload failed. File already exists",
        "unknownError": "Unknown error while uploading the file",
        "fileSizeError": "Upload failed. File size limit reached",
        "loginFailureTop": "",
        "loginFailureBottom": ""
      },
      "user": "entryer",
      "users": "entryers"
    },
    "profile": {
      "press": {
        "filterAuthor": "Filter by author",
        "articleDetail": "Article detail",
        "show": "show",
        "hide": "hide",
        "downloadImage": "Download image",
        "downloadImages": "Download images",
        "downloadText": "Export text"
      },
      "progress": {
        "title": "Improve your profile"
      },
      "marketplace": {
        "new": "New Skill or Request",
        "offer": {
          "new": "New Skill",
          "delete": "Delete Skill",
          "tags": "Skills tags (helps to be better found)",
          "description": "Descripe your skill",
          "descriptionPlaceholder": "relevant description, to be better matched.",
          "relatedEntry": "related Entry"
        },
        "request": {
          "new": "New Request",
          "delete": "Delete Request"
        }
      },
      "messages": {
        "is": "is",
        "youAre": "You are",
        "want": "wants",
        "youWant": "You want to",
        "as": "as",
        "dreamer": "dreamer",
        "supporter": "supporter",
        "approve": "Approve",
        "enter": "entered",
        "join": "joined",
        "sendText": "Your message has been sent.",
        "shareDream": "You have shared this entry.",
        "likeDream": "You like this entry now.",
        "favoriteDream": "You follow this entry now.",
        "joinDream": "You want to join this entry.",
        "donateDream": "",
        "has": "has decided",
        "decided": "to entry",
        "dreamToo": "too",
        "commentPre": "commented",
        "comemntSuff": " ",
        "follow": "follows",
        "deletedUser": "Deleted user"
      },
      "newsletter": {
        "subscribed": "subscribed",
        "notSubscribed": "subscribe",
        "privacyInformation": "By clicking subscribe you opt-in to receive the future newsletters on this list. You can opt-out by clicking subscribe again."
      },
      "entries": {
        "entries": "{{userName}}s Entrys",
        "follows": "Entrys {{userName}} is following",
        "joins": "Entrys {{userName}} joined",
        "donates": "Entrys {{userName}} is supporting",
        "copycats": "Entrys {{userName}} also wants to entry"
      }
    },
    "entries": {
      "edit": "Edit dream <strong>{{title}}</strong>",
      "entry": {
        "tooltip": {
          "like": "click here to like this dream",
          "noLike": "you cannot like your own dream (although you do :-)",
          "follow": "click here to follow this dream",
          "noFollow": "you cannot follow your own dream",
          "alreadyLiked": "you like this dream already",
          "alreadyFollow": "you follow this dream already",
          "noFollowNotLoggedIn": "sign in to follow this dream",
          "copied": "you successfully copied this entry",
          "alreadyCopied": "you already copied this entry before"
        },
        "profile": {
          "edit": "Edit profile",
          "publicProfile": "Go to my public profile"
        },
        "ui": {
          "access": "This dream is visible for",
          "allAccess": "This dream is visible for all",
          "addEntry": "Add a dream",
          "addStep": "Add a step",
          "step": "Step",
          "editEntry": "Edit dream",
          "titlePlaceholder": "Enter dream title (max. 50 characters)",
          "subtitlePlaceholder": "Subtitle (max. 60 characters)",
          "metaDescriptionPlaceholder": "Short description with 200 characters maximum",
          "descriptionPlaceholder": "Describe the dream. You can use HTML. 600 characters max.",
          "companyTitle": "My Company information",
          "businessContact": "Company contact",
          "addOpening": "Add opening hours",
          "agreementHours": "open by appointment",
          "assignedCategories": "assigned categories",
          "assignedProducts": "assigned products",
          "assignedCertificates": "My certificates",
          "categoryFields": "Additional information",
          "cssClass": "Additional CSS class",
          "deliveryInfo": "Delivery information",
          "exclusionHours": "Opening hours exeptions",
          "galleryImages": "Image gallery",
          "globalTitle": "Name",
          "hasKiosk": "I have a kiosk",
          "rentKiosk": "I want to rent a kiosk",
          "imagesHorizontal": "horizontal",
          "imagesOrientation": "Image alignment",
          "imagesSquared": "squared",
          "imagesVertical": "vertical",
          "kioskDescription": "Kiosk description",
          "logo": "Logo",
          "longDescription": "Long description",
          "mainImage": "Main image",
          "isMainImage": "This image is the main image",
          "makeMainImage": "Assign this image as main image",
          "makeMainImageTooltip": "The main image is shown in the top banner. Images not assigned as main or list image are shown in the gallery.",
          "makeListImageTooltip": "Search results are shown as list with the list images. If no list image is assigned the main image is shown.",
          "listImage": "List image",
          "isListImage": "This image is the list image",
          "makeListImage": "Assign this image as list image",
          "metaDescription": "Short description",
          "noEntries": "No dreams found.",
          "noDescriptions": "Descriptions for this dream are missing.",
          "openAt": "open at",
          "openingHours": "Opening hours",
          "openingInfo": "Opening hours additional information",
          "openingReligious": "Address in mainly catholic region",
          "parking": "Parking",
          "parkingLots": "Lots",
          "parkingType": "Type",
          "personImage": "Contact image",
          "shortDescriptionInfo": "Is short description",
          "sortword": "Alternative title for sorting",
          "subtitle": "Descriptive subtitle",
          "skillsNeeded": "I need...",
          "tags": "Tags",
          "title": "The dream appears under the following name",
          "titleError": "To save this dream you must supply a title",
          "visible": "This dream is visible",
          "routingPlaceholder": "Your directions with HTML.",
          "categories": "Categories",
          "resolveAddress": "Address geolocation",
          "resolveAddressInfo": "Please geolocate your address to be better found.",
          "resolveGeolocation": "Search for address",
          "multiTitle": "Title for this step",
          "multiTitlePlaceholder": "Enter step title (max. 50 characters)",
          "multiDescription": "Description for this step",
          "multiProgress": "Progress of this step ",
          "multiImages": "Images for this step",
          "multiCode": "Additional Code for this step",
          "stepInDream": "Is this step",
          "stepInDreamBefore": "before the dream",
          "stepInDreamDuring": "during the dream",
          "stepInDreamAfter": "after the dream",
          "added": "Was added.",
          "tabDescription": "Description",
          "tabImages": "Images/Videos",
          "tabCompany": "Company Info",
          "tabCategories": "Categories",
          "imagesInfo": "Go to \"My Images/Videos\" to edit images."
        },
        "progress": {
          "title": "Improve your dream"
        },
        "fields": {
          "prefixMissing": "Folgende Felder sind nicht ausgefüllt: \n",
          "address": {
            "houseNumber": "housenumber \n",
            "zip": "zip \n",
            "city": "city \n",
            "street": "street \n",
            "country": "country \n"
          }
        }
      },
      "open": "Go to public dream page",
      "person": {
        "ui": {
          "company": "Company",
          "female": "Mrs.",
          "fullName": "First and last name",
          "fullSize": "Full size",
          "gender": "Gender",
          "greeting": "Greeting",
          "male": "Mr.",
          "open": "Go to this persons dreams",
          "personAddress": "Personal address",
          "personContact": "Personal contact information",
          "personImage": "Person image",
          "position": "Position/Type",
          "quote": "Citation, image description",
          "signature": "Signature image"
        }
      },
      "product": {
        "edit": "Edit product <strong>{{title}}</strong>",
        "ui": {
          "addProduct": "Add product",
          "noProducts": "No products found",
          "title": "Product"
        }
      },
      "poi": {
        "ui": {
          "title": "This place appears under"
        }
      },
      "event": {
        "edit": "Edit event <strong>{{title}}</strong>",
        "ui": {
          "addEvent": "Add event",
          "noEvents": "No events found",
          "title": "This event appears under",
          "startDate": "Start day of event",
          "endDate": "Last day of event",
          "opening": "Opening hours",
          "cooperation": "Cooperations",
          "directions": "Directions to event"
        }
      },
      "tabbar": {
        "basics": "Basics",
        "entry": "Entry",
        "gallery": "Images",
        "layout": "Layout",
        "openingHours": "Opening hours",
        "order": "Order information",
        "tooltip": {
          "entryNotSavedDetail": "Entry data can only modified, if the dream has been saved once with title.",
          "entryNotSavedLayout": "Layout data can only be modified, if the dream has been saved one with title."
        }
      },
      "types": {
        "poi": "Poi",
        "standard": "Standard"
      },
      "ui": {
        "groupDetailName": "Name for group entries",
        "groupName": "Name for groups (Region)"
      },
      "entries": "Entrys"
    },
    "search": {
      "searchBarPlaceholder": "Entriesearch",
      "removeDatefilter": "remove filter",
      "search": "search",
      "for": "for",
      "resulted": "results in",
      "hits": "hits",
      "andFilter": " and filter"
    },
    "buttons": {
      "cancel": "Cancel",
      "ok": "Ok",
      "save": "Save changes",
      "saveMobile": "Save",
      "newPassword": "Request new password",
      "delete": "Delete",
      "deleteFavorite": "Delete follow",
      "deleteLike": "Delete like",
      "selectThis": "Select this"
    },
    "accountTypes": {
      "aussteller": "Exhibitors",
      "veranstalter": "Organizers",
      "gastgeber": "Hosts"
    },
    "openingHours": {
      "addOpening": "Add opening hour",
      "delete": "Delete",
      "type": "Type of opening",
      "options": "Range",
      "additionalInfo": "Additional information",
      "date": {
        "day": {
          "di": "Tue",
          "do": "Thu",
          "fr": "Fri",
          "mi": "Wed",
          "mo": "Mon",
          "sa": "Sat",
          "so": "Sun"
        }
      },
      "dateS": "Date",
      "time": "Time",
      "from": "from",
      "to": "to"
    },
    "media": {
      "ui": {
        "title": "Title",
        "description": "Description",
        "copyright": "Copyright",
        "cropImage": "Crop image",
        "cropImageDuplicate": "Duplicate and Crop",
        "filename": "Filename",
        "rotateImage": "Rotate image 90° clockwise",
        "resetImage": "Reset and save",
        "cropFormat": "Formatvorgabe",
        "resetCrop": "Reset crop",
        "processingImage": "Image processing, please wait",
        "imageSaved": "Image saved"
      }
    },
    "entryTypes": {
      "entry": "Entry",
      "event": "Event",
      "poi": "Place"
    },
    "errors": {
      "description": "This field",
      "inclusion": "{{description}} is not included in the list",
      "exclusion": "{{description}} is reserved",
      "invalid": "{{description}} is invalid",
      "empty": "{{description}} can't be empty",
      "blank": "{{description}} needs to be filled",
      "email": "{{description}} must be a valid email",
      "slug": "Slug darf nur Kleinbuchstaben, Zahlen und \"-\" enthalten"
    }
  };
});