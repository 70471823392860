define('webapp/helpers/filter-images', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.filterImages = filterImages;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * return all images that are not main,list,logo image
   *
   * @param item e.g. full entry model
   * @return {*}
   */
  function filterImages(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 1),
        item = _ref2[0];

    var hiddenImages = [];
    if (item.logo && item.logo.path) {
      hiddenImages.push(item.logo.path);
    }
    if (item.mainImage && item.mainImage.path) {
      hiddenImages.push(item.mainImage.path);
    }
    if (item.listImage && item.listImage.path) {
      hiddenImages.push(item.listImage.path);
    }

    if (hiddenImages.length) {
      return item.images && item.images.filter(function (img) {
        return img && hiddenImages.indexOf(img.path) < 0;
      });
    } else {
      return item.images;
    }
  }

  exports.default = Ember.Helper.helper(filterImages);
});