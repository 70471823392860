define('webapp/components/widgets/widget-newsletter-subscribe', ['exports', 'webapp/utils/find-by', 'ember-cp-validations'], function (exports, _findBy, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    email: (0, _emberCpValidations.validator)('format', {
      presence: true,
      type: 'email',
      description: 'Email'
    })
  });

  exports.default = Ember.Component.extend(Validations, {
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),

    email: '',
    isSubscribed: false,

    initWidget: function initWidget() {
      var _this = this;

      var myStorage = this.get('myStorage');

      var isLoggedIn = this.get('userProxy.isLoggedIn');

      var dataPromises = {
        lists: myStorage.mailingList.ajaxGet()
      };

      if (isLoggedIn) {
        dataPromises.own = myStorage.mailingList.ajaxGet({ param: 'own' });
      }

      return Ember.RSVP.hash(dataPromises).then(function (_ref) {
        var lists = _ref.lists,
            own = _ref.own;

        var selectedMailingListId = _this.get('data.options.mailingList');

        var selectedMailingList = (0, _findBy.default)(lists, '_id', selectedMailingListId);
        _this.set('selectedMailingList', selectedMailingList);

        if (isLoggedIn) {
          var ownIds = own.map(function (x) {
            return x._id;
          });
          _this.set('isSubscribed', ownIds.indexOf(selectedMailingList._id) >= 0);
        } else {
          _this.set('isSubscribed', false);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this.initWidget();
    },


    actions: {
      subscribe: function subscribe(list) {
        var _this2 = this;

        var myStorage = this.get('myStorage');
        myStorage.mailingList.ajaxPost({
          param: 'subscribe_logged_in',
          data: {
            mailingList: list._id
          }
        }).then(function () {
          _this2.set('isSubscribed', true);
        });
      },
      subscribeByEmail: function subscribeByEmail(list, email) {
        var _this3 = this;

        var message = this.get('message');
        var myStorage = this.get('myStorage');
        myStorage.mailingList.ajaxPost({
          param: 'subscribe_email',
          data: {
            mailingList: list._id,
            email: email
          }
        }).then(function () {
          message.infoT('application.newsletter.subscribeThanks');
          _this3.set('isSubscribed', true);
        }).catch(function (err) {
          if (err.status === 409) {
            message.errorT('application.newsletter.alreadySubscribedNoList');
            _this3.set('alreadySubscribed', true);
          } else {
            message.error(err.payload && err.payload.message);
          }
        });
      }
    }
  });
});