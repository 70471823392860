define('webapp/components/page/blog-post-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    commentHierarchy: 'nested',
    highlightedComment: null,
    commentCompMap: Ember.computed(function () {
      return {};
    }),

    updateComments: function updateComments(value) {
      var _this = this;

      var id = this.get('post._id');
      var myStorage = this.get('myStorage');
      var params = {
        param: 'getByParentId/' + id,
        query: {
          nested: value
        }
      };

      myStorage.blog.ajaxGet(params).then(function (data) {
        _this.set('post.comments', data);
      });
    },
    registerComment: function registerComment(commentId, commentComp) {
      var commentCompMap = this.get('commentCompMap');
      commentCompMap[commentId] = commentComp;
    },
    unregisterComment: function unregisterComment(commentId) {
      var commentCompMap = this.get('commentCompMap');
      delete commentCompMap[commentId];
    },


    actions: {
      reloadComments: function reloadComments() {
        var commentHierarchy = this.get('commentHierarchy');

        this.updateComments(commentHierarchy);
      },
      setSort: function setSort(value) {
        var commentHierarchy = this.get('commentHierarchy');

        if (commentHierarchy !== value) {
          this.set('commentHierarchy', value);
          this.updateComments(value);
        }
      },
      highlightComment: function highlightComment(commentId) {
        this.set('highlightedComment', commentId);
        var commentCompMap = this.get('commentCompMap');
        var commentComp = commentCompMap[commentId];

        if (commentComp) {
          Ember.run.schedule('afterRender', function () {
            // UIkit.Utils.scrollToElement($(commentComp.element), {offset: 300, duration: 300});
          });
        }
      }
    }
  });
});