define('webapp/components/search/category-browser-flat', ['exports', 'webapp/utils/tree-utils', 'webapp/utils/filter-categories', 'webapp/utils/find-by'], function (exports, _treeUtils, _filterCategories, _findBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cachedData: Ember.inject.service(),
    categoryCounts: {},
    rawCategories: [],
    maxLevel: Infinity,
    multiSelect: true,
    onlyChildren: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('cachedData').getCategories().then(function (res) {
        _this.set('rawCategories', res.slice());
      });
    },


    availableCategories: Ember.computed('rawCategories', 'filter', function () {
      var onlyChildren = this.get('onlyChildren');
      var filter = this.get('filter').split(/,\s*/);
      var rawCategories = this.get('rawCategories');
      var filteredCategories = (0, _filterCategories.default)(rawCategories, filter);

      if (onlyChildren) {
        var childCats = [];
        for (var i = 0; i < filteredCategories.length; i++) {
          var cat = filteredCategories[i];
          if (cat.children && cat.children.length) {
            childCats = childCats.concat(cat.children);
          }
        }
        return childCats;
      } else {
        return filteredCategories;
      }
    }),

    flatCategories: Ember.computed('availableCategories', 'selectedCategories.length', 'maxLevel', 'categoryCounts', function () {
      var availableCategories = this.get('availableCategories');
      var selectedCategories = this.get('selectedCategories');
      var categoryCounts = this.get('categoryCounts') || {};
      var hideEmptyCats = this.get('hideEmptyCats');

      var flatCategories = (0, _treeUtils.flattenTree)(availableCategories, this.get('maxLevel'));

      var shownCategories = flatCategories.filter(function (cat) {
        return !cat.noDisplay;
      }).map(function (cat) {
        return Ember.assign({}, cat, {
          isSelected: selectedCategories.filter(function (x) {
            return x._id === cat._id;
          }).length > 0,
          count: categoryCounts[cat._id] || 0
        });
      });

      if (hideEmptyCats) {
        shownCategories = shownCategories.filter(function (cat) {
          return cat.count > 0;
        });
      }

      return shownCategories;
    }),

    selectCategory: function selectCategory(cat) {
      var selectedCategories = this.get('selectedCategories');
      var flatCategories = this.get('flatCategories').map(function (x) {
        return x._id;
      });

      var multiSelect = this.get('multiSelect');

      selectedCategories = selectedCategories.concat(cat);

      if (!multiSelect) {
        selectedCategories = selectedCategories.filter(function (x) {
          return x._id === cat._id || flatCategories.indexOf(x._id) < 0;
        });
      }

      this.sendAction('categoriesChanged', selectedCategories);
    },


    actions: {
      toggleCategory: function toggleCategory(cat) {
        var selectedCategories = this.get('selectedCategories');

        var foundCat = (0, _findBy.default)(selectedCategories, '_id', cat._id);
        if (foundCat) {
          this.sendAction('categoriesChanged', selectedCategories.filter(function (x) {
            return x !== foundCat;
          }));
        } else {
          this.selectCategory(cat);
        }
      }
    }
  });
});