define('webapp/components/widgets/widget-blog-newest-posts', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    languageService: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var blogId = this.get('data.options.blog');

      if (blogId) {
        this.fetchData(blogId);
      } else {
        console.info('no blog defined for widget: ' + this.get('data.title'));
      }
    },
    fetchData: function fetchData(blogId) {
      var _this = this;

      var myStorage = this.get('myStorage');

      // fetch first page where blog is mounted
      var cachedData = this.get('cachedData');
      var languageService = this.get('languageService');
      cachedData.cachedSitePromise.then(function (site) {
        var pages = site.pages;
        var blogPage = (0, _treeUtils.findInTree)(pages, function (page) {
          return Ember.get(page, 'options.blog') === blogId;
        });
        if (blogPage) {
          _this.set('blogPageSlug', languageService.translateSlug(blogPage));
        } else {
          _this.set('blogPageSlug', null);
        }
      });

      var _data$options = this.data.options,
          size = _data$options.size,
          sortOrder = _data$options.sortOrder,
          sortBy = _data$options.sortBy;


      myStorage.blog.ajaxGet({ param: blogId + '/newestposts', query: { size: size, sortOrder: sortOrder, sortBy: sortBy } }).then(function (res) {
        _this.setProperties({
          blog: res.blog,
          posts: res.posts,
          blogRoute: res.blogRoute
        });
      });
    }
  });
});