define('webapp/components/search/search-result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    searchService: Ember.inject.service(),

    searchResult: Ember.computed.alias('searchService.searchResult'),

    actions: {
      toggleFilter: function toggleFilter() {
        this.get('searchService').toggleProperty('filterExpanded');
      },
      setPage: function setPage(pagingIdx) {
        this.get('searchService').setPagingIdx(pagingIdx);
      }
    }
  });
});