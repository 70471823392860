define('webapp/components/account/catalogue-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    itemType: 'entry', // poi or event
    searchField: 'title',

    itemStorage: Ember.computed('itemType', function () {
      var itemType = this.get('itemType');
      var storages = {
        entry: 'myStorage.entryAdmin',
        event: 'myStorage.eventAdmin',
        poi: 'myStorage.poiAdmin'
      };

      var itemStorage = storages[itemType];

      if (!itemStorage) {
        throw new Error('unkown itemType: ' + itemType);
      }
      return this.get(itemStorage);
    }),

    items: [],
    searchTerm: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.search();
    },
    search: function search() {
      var _this = this;

      var searchField = this.get('searchField');
      var data = {
        search: {
          field: searchField,
          term: this.get('searchTerm')
        },
        perPage: 0
      };

      var itemsToSkip = this.get('itemsToSkip');
      if (itemsToSkip && itemsToSkip.length) {
        data.filter = { _id: { $nin: itemsToSkip.map(function (item) {
              return Ember.get(item, 'item._id');
            }) } };
      }

      return this.get('itemStorage').ajaxPost({ param: 'search', data: data }).then(function (items) {
        _this.set('items', items.model);
      });
    },


    actions: {
      search: function search() {
        this.search();
      },
      selectItem: function selectItem(item) {
        this.sendAction('selectItem', item);
      }
    }
  });
});