define('webapp/components/file-upload-base', ['exports', 'webapp/config/environment', 'webapp/settings/media-extensions'], function (exports, _environment, _mediaExtensions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProxy: Ember.inject.service(),
    message: Ember.inject.service(),
    fileQueue: Ember.inject.service(),
    viewService: Ember.inject.service(),
    i18n: Ember.inject.service(),
    uploadPath: 'upload',
    classNames: ['upload-drop'],
    path: '',
    qModal: false,
    fileMaxUpload: _environment.default.fileMaxUpload * 1024 * 1024,
    fileIds: [],

    queueName: 'main-upload',

    tasks: Ember.computed(function () {
      return {}; //wird benötigt um einzele upload-tasks abzubrechen
    }),

    allowedExtensions: [].concat(_mediaExtensions.default.IMAGE_EXTENSIONS, _mediaExtensions.default.VIDEO_EXTENSIONS, _mediaExtensions.default.DOCUMENT_EXTENSIONS, _mediaExtensions.default.AUDIO_EXTENSIONS),

    accept: Ember.computed('allowedExtensions', function () {
      return this.get('allowedExtensions').map(function (x) {
        return '.' + x;
      }).join(', ');
    }),

    didRender: function didRender() {
      var host = void 0;
      if (this.get('isUser')) {
        host = _environment.default.apiEndpoints.userImage;
      } else {
        if (this.get('uploadPath') === 'public_upload') {
          host = _environment.default.apiEndpoints.media;
        } else {
          host = _environment.default.apiEndpoints.mediaAdmin;
        }
      }
      var currentPath = this.get('currentPath');
      var uploadPath = this.get('uploadPath');

      var path = host + '/' + uploadPath;
      if (currentPath) {
        path += '?path=' + encodeURIComponent(currentPath);
      }
      this.set('path', path);
    },
    storeId: function storeId(fileID) {
      var fileIds = this.get('fileIds').slice();

      if (fileIds.indexOf(fileID) === -1) {
        fileIds.push(fileID);
        this.set('fileIds', fileIds);
      }
    },
    getMyFileQueue: function getMyFileQueue() {
      return this.get('fileQueue').find(this.get('queueName'));
    },
    closeStatusModal: function closeStatusModal() {
      this.set('qModal', false);
      this.set('viewService.uploadInProgress', false);
    },
    _uploadFile: function _uploadFile(file) {
      var _this = this;

      this.set('viewService.uploadInProgress', true);
      var message = this.get('message');
      var uploadpath = this.get('path');
      var i18n = this.get('i18n');
      var fileMaxUpload = this.get('fileMaxUpload');
      var fileQueue = this.getMyFileQueue();

      var mimeIndex = file.name.lastIndexOf('.');
      var mimeType = file.name.slice(mimeIndex + 1);
      if (mimeType === 'm4v' || mimeType === 'mov' || mimeType === 'M4V' || mimeType === 'MOV') {
        Ember.set(file, 'name', file.name.slice(0, mimeIndex).concat('.mp4'));
      }
      if (!this.isDestroyed) {
        this.set('qModal', true);
      }

      var settings = {
        headers: { Token: this.get('userProxy.token') },
        fileKey: 'files[]'
      };

      if (Ember.get(file, 'size') < fileMaxUpload) {
        var task = file.upload(uploadpath, settings).then(function (res) {
          _this.storeId(res.body.mediaIds[0]);
          if (_this.get('uploadPath') === 'upload') {
            _this.storeId(res.body.mediaIds[0]);
          }

          //überprüft ob sich noch weitere Dateien in der Warteschlange befinden.
          var stillUploading = false;
          fileQueue.files.forEach(function (file) {
            if (file.state === 'uploading') {
              stillUploading = true;
            }
          });

          if (!stillUploading && !_this.isDestroyed) {
            var fileIds = _this.get('fileIds').slice();
            _this.set('tasks', {});
            _this.closeStatusModal();
            message.info(i18n.t('user.message.upload-success'));
            _this.sendAction('openBatch', fileIds); //öffnet Editor nach Fileupload
            _this.set('fileIds', []);
            _this.sendAction('uploadCompleted', res.body && res.body.files && res.body.files[0]);
          }

          // ;
        }).catch(function (err) {
          console.error(err.stack);
          fileQueue.remove(file);

          var stillUploading = false;
          fileQueue.files.forEach(function (file) {
            if (file.state === 'uploading') {
              stillUploading = true;
            }
          });
          if (!stillUploading && !_this.isDestroyed) {
            _this.closeStatusModal();
          }
          if (err.status === 409) {
            message.error(i18n.t('user.message.fileExistsError'));
          } else {
            if (err.body.code === 'LIMIT_FILE_SIZE') {
              message.error(i18n.t('user.message.fileSizeError'));
            } else {
              message.error(i18n.t('user.message.unknownError'));
            }
          }
        });

        this.set('tasks.' + file.id, task);
        return task;
      } else {
        fileQueue.remove(file);
        this.closeStatusModal();
        message.error(i18n.t('user.message.fileSizeError'));
      }
    },


    actions: {
      cancel: function cancel(file) {
        var x = file.id;
        this.get('tasks')[x].cancel();
        var fileQueue = this.getMyFileQueue();
        if (fileQueue.files.length === 0) {
          this.closeStatusModal();
        }
      },
      uploadFile: function uploadFile(file) {
        this._uploadFile(file);
      }
    }
  });
});