define('webapp/components/comment-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    commentService: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    commentHierarchy: 'nested',
    highlightedComment: null,
    canReadComment: true,
    canWriteComment: true,
    nestlevel: 10,
    showMore: true,
    hasTitle: true,
    hasUser: true,

    commentCompMap: Ember.computed(function () {
      return {};
    }),

    // TODO handle comment options
    commentOptions: Ember.computed(function () {
      return { nested: true };
    }),

    updateComments: function updateComments() {
      var _this = this;

      var externalComments = this.get('externalComments');
      if (!externalComments) {
        var nested = this.get('commentOptions.nested');
        var parentType = this.get('parentType');
        var parentId = this.get('parentId');
        var myStorage = this.get('myStorage');

        var query = {};
        if (nested) {
          query.nested = true;
        }

        var params = {
          param: parentType + '/' + parentId,
          query: query
        };

        myStorage.comments.ajaxGet(params).then(function (data) {
          _this.set('comments', data);
        });
      }
    },
    registerComment: function registerComment(commentId, commentComp) {
      var commentCompMap = this.get('commentCompMap');
      commentCompMap[commentId] = commentComp;
    },
    unregisterComment: function unregisterComment(commentId) {
      var commentCompMap = this.get('commentCompMap');
      delete commentCompMap[commentId];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.throttle(this, this.updateComments, 100);
    },
    didInsertElement: function didInsertElement() {
      var commentService = this.get('commentService');
      commentService.registerCommentContainer(this.get('parentId'), this);
    },
    willDestroyElement: function willDestroyElement() {
      var commentService = this.get('commentService');
      commentService.unregisterCommentContainer(this.get('parentId'));
    },


    actions: {
      addComment: function addComment() {
        var commentForm = this.get('commentForm');
        if (commentForm) {
          commentForm.send('add');
        }
      },
      reloadComments: function reloadComments() {
        this.updateComments();
      },
      highlightComment: function highlightComment(commentId) {
        this.set('highlightedComment', commentId);
        var commentCompMap = this.get('commentCompMap');
        var commentComp = commentCompMap[commentId];

        if (commentComp) {
          Ember.run.schedule('afterRender', function () {
            // UIkit.Utils.scrollToElement($(commentComp.element), { offset: 300, duration: 300 });
          });
        }
      }
    }
  });
});