define('webapp/routes/login', ['exports', 'webapp/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    userProxy: Ember.inject.service(),
    languageService: Ember.inject.service(),
    i18n: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        page: this.get('cmsService').getPage('login')
      }).then(function (res) {
        return res.page;
      });
    },
    getBreadcrumbs: function getBreadcrumbs() {
      return [{
        route: 'login',
        title: 'Login'
      }];
    },


    actions: {
      afterLogin: function afterLogin(error) {
        if (error) {
          var i18n = this.get('i18n');
          var message = i18n.t('user.message.can-not-login').toString();
          UIkit.modal.alert(message);
        } else {
          var userLanguage = this.get('userProxy.language');
          var languageService = this.get('languageService');

          if (userLanguage) {
            languageService.setLanguage(userLanguage);
          }

          this.transitionTo('index');
        }
      }
    }
  });
});