define('webapp/components/search/selected-categories', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getChildIds(categories, categoryId) {
    var childsIds = [];
    var cat = (0, _treeUtils.findInTree)(categories, function (cat) {
      return cat._id === categoryId;
    });
    if (cat && cat.children) {
      (0, _treeUtils.walkTree)(cat.children, function (cat) {
        childsIds.push(cat._id);
      });
    }
    return childsIds;
  }

  exports.default = Ember.Component.extend({
    viewService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    selectedCategories: [],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('cachedData').getCategories().then(function (res) {
        _this.set('rawCategories', res.slice());
      });
    },


    actions: {
      removeCategory: function removeCategory(category) {
        var selectedCategories = this.get('selectedCategories');
        var childIds = getChildIds(this.get('rawCategories'), category._id);
        var idsToRemove = childIds.concat([category._id]);

        selectedCategories = selectedCategories.filter(function (cat) {
          return idsToRemove.indexOf(cat._id) < 0;
        });

        selectedCategories = selectedCategories.filter(function (cat) {
          return cat._id !== category._id;
        });

        //TODO need better solution
        if (category.type === 'region') {
          this.set('viewService.regionPage', null);
        }

        this.sendAction('categoriesChanged', selectedCategories);
      }
    }
  });
});