define('webapp/mixins/transition-logger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    trackPiwik: Ember.on('didTransition', function () {
      var url = this.get('url');

      if (window.location && window.location.href && window._paq) {
        _paq.push(['trackPageView', url]);
      }
    })
  });
});