define('webapp/components/widgets/widget-similar-entries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    detailService: Ember.inject.service(),
    allResults: Ember.computed(function () {
      return [];
    }),

    fetchEntriesByTags: function fetchEntriesByTags(tags) {
      var myStorage = this.get('myStorage');

      return myStorage.search.ajaxPost({
        data: {
          itemTypes: ['entry'],
          tagFilter: tags,
          size: 20
        }
      }).then(function (res) {
        return res.hits;
      });
    },
    fetchSimilarEntries: function fetchSimilarEntries() {
      var _this = this;

      var tags = this.get('detailService.detail.tags');
      var detailId = this.get('detailService.detail._id');

      if (tags && tags.length) {
        this.fetchEntriesByTags(tags).then(function (items) {
          if (!_this.isDestroyed) {
            _this.set('allResults', items.filter(function (item) {
              return item._id !== detailId;
            }));
          }
        });
      } else {
        if (!this.isDestroyed) {
          this.set('allResults', []);
        }
      }
    },


    _tagsChanges: Ember.observer('detailService.detail', function () {
      Ember.run.debounce(this, this.fetchSimilarEntries, 100);
    }),

    didInsertElement: function didInsertElement() {
      Ember.run.debounce(this, this.fetchSimilarEntries, 100);
    }
  });
});