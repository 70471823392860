define('webapp/controllers/account/profile/index', ['exports', 'webapp/utils/media'], function (exports, _media) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var progressConfig = [{ field: 'firstName', weight: 20 }, { field: 'lastName', weight: 20 }, { field: 'banner', weight: 10 }, { field: 'image', weight: 10 }, { field: 'additionalData.birthday', weight: 10 }, { field: 'address.street', weight: 5 }, { field: 'address.city', weight: 10 }, { field: 'address.zip', weight: 5 }, { field: 'address.houseNumber', weight: 5 }, { field: 'address.country', weight: 5 }];

  exports.default = Ember.Controller.extend({
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    widgetInteractions: Ember.inject.service(),

    emailValid: Ember.computed('model.email', function () {
      var email = this.get('model.email');
      return email && email.match(/^\S+@\S+$/);
    }),

    progress: Ember.computed.apply(undefined, _toConsumableArray(progressConfig.map(function (obj) {
      return 'userProxy.' + obj.field;
    })).concat([function () {
      var user = this.get('userProxy').getUser();
      var value = 0;

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = progressConfig[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var elem = _step.value;

          var field = elem.field;
          var fieldValue = Ember.get(user, field);
          if (fieldValue) {
            value += elem.weight;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return value;
    }])),

    removeBanner: function removeBanner() {
      this.set('userProxy.banner', null);
      this.set('model.banner', null);
    },
    removeAvatar: function removeAvatar() {
      this.set('userProxy.image', null);
      this.set('model.image', null);
    },
    editBanner: function editBanner(image) {
      var _this = this;

      var myStorage = this.get('myStorage');
      myStorage.mediaAdmin.ajaxGet({ param: image.mediaId }).then(function (fullImage) {
        _this.set('showEditBannerModal', true);
        _this.set('editedBanner', fullImage);
      });
    },
    editAvatar: function editAvatar() {
      var _this2 = this;

      var myStorage = this.get('myStorage');
      myStorage.mediaAdmin.ajaxGet({ param: this.get('userProxy.image.mediaId') }).then(function (fullImage) {
        _this2.set('showEditAvatarModal', true);
        _this2.set('editedAvatar', fullImage);
      });
    },


    actions: {
      finishEditBanner: function finishEditBanner() {
        this.set('showEditBannerModal', false);
        this.set('editedBanner', null);
        this.get('widgetInteractions').incCounter();
      },
      finishEditAvatar: function finishEditAvatar() {
        this.set('showEditAvatarModal', false);
        this.set('editedAvatar', null);
        this.get('widgetInteractions').incCounter();
      },
      saveUser: function saveUser() {
        var userProxy = this.get('userProxy');
        var model = this.get('model');
        return userProxy.saveUser(model);
      },
      cancelProgressFields: function cancelProgressFields() {
        this.set('showProgressFields', false);
      },
      toggleProgressModal: function toggleProgressModal() {
        this.toggleProperty('showProgressFields');
      },
      confirmImage: function confirmImage(modalType, image) {
        var model = this.get('model');
        var userProxy = this.get('userProxy');

        var nestedImage = (0, _media.mediumToNested)(image);

        // set property to userProxy, so new images are displayed immediately
        if (modalType === 'userImage') {
          Ember.set(model, 'image', nestedImage);
          Ember.set(userProxy, 'image', nestedImage);
        } else if (modalType === 'userBanner') {
          Ember.set(model, 'banner', nestedImage);
          Ember.set(userProxy, 'banner', nestedImage);
        }

        this.get('widgetInteractions').closeUserModal();
      },
      check: function check() {
        var _this3 = this;

        var myStorage = this.get('myStorage');
        var email = this.get('model.email');
        var proxyMail = this.get('userProxy.email');
        if (email !== proxyMail) {
          myStorage.user.ajaxPost({
            param: 'check',
            data: {
              email: email
            }
          }).then(function (res) {
            if (res.emailAvailable !== undefined) {
              _this3.set('emailCheck', !res.emailAvailable);
            }
          });
        }
      },
      activateHighlight: function activateHighlight() {
        var user = this.get('userProxy').getUser();
        var highlightFields = [];
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = progressConfig[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var elem = _step2.value;

            var field = elem.field;
            var fieldValue = Ember.get(user, field);
            if (!fieldValue) {
              highlightFields.push(field);
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        console.log(highlightFields);
        this.set('highlight', highlightFields);
      }
    }
  });
});