define('webapp/utils/image-path', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (path, size, timestamp) {
    if (!path) {
      return;
    }
    // if (path.indexOf('http') == 0) return path;

    size = size || 'small';
    var extIndex = path.lastIndexOf('.');
    var baseName = path.slice(0, extIndex);
    var ext = path.slice(extIndex);

    var targetPath = void 0;
    if (ext === '.svg' || ext === '.gif') {
      targetPath = path;
    } else if (ext === '.pdf' || ext === '.mp4') {
      targetPath = baseName + '_preview_' + size + '.jpg';
    } else {
      targetPath = baseName + '_' + size + ext;
    }

    if (timestamp) {
      targetPath += '?timestamp=' + timestamp;
    }

    return _environment.default.apiEndpoints.image + '/' + escapeImage(targetPath);
  };

  function escapeImage(img) {
    if (img) {
      return encodeURI(img).replace(/\(/g, '%28').replace(/\)/g, '%29');
    }
  }
});