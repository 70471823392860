define('webapp/components/search/search-result-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    searchService: Ember.inject.service(),

    mouseEnter: function mouseEnter() {
      var searchService = this.get('searchService');
      if (this.get('item.geoPoint')) {
        searchService.trigger('showPopup', this.get('item'));
      }
    }
  });
});